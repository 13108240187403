import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(rubro, rubroName, theme) {
  return {
    fontWeight: rubroName.includes(rubro)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const MultipleSelectChip = ({
  setChips,
  chips,
  setChipsSeleccionados,
  chipsSeleccionados,
}) => {
  const theme = useTheme();

  console.log("chips: ", chips);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setChipsSeleccionados(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    console.log("chipsSeleccionados: ", chipsSeleccionados);
  }, [chipsSeleccionados]);

  return (
    <div sx={{ align: "left" }}>
      <FormControl
        sx={{
          display: "flex",
          alignContent: "flex-start",

          width: "80%",
        }}
      >
        <InputLabel id="demo-multiple-chip-label">Rubros</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={chipsSeleccionados}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} color="primary" />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {chips.map((rubro) => (
            <MenuItem
              key={rubro.IDRubro}
              value={rubro.Rubro}
              // style={getStyles(rubro, rubroName, theme)}
            >
              {rubro.Rubro}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSelectChip;
