import React, { useEffect, useState } from "react";
import { traer_color } from "../../../colores/Colores";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
  useControlled,
} from "@mui/material";
import EstilosContext from "../../../contextos/EstilosContext";
import { useContext } from "react";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ServidorContext from "../../../contextos/ServidorContext";
import FiltroTareas from "./FiltroTareas";
import PopupAgregarTarea from "./PopupAgregarTarea";
import SelectComboCategoria from "./SelectComboCategoria";
import MensajesContext from "../../../contextos/MensajesContext";
import ObraContext from "../../../contextos/ObraContext";
import ControlDeAvanceContext from "../../../contextos/ControlDeAvanceContext";
import { desencriptar } from "../../../funciones/Funciones";
import UsuarioContext from "../../../contextos/UsuarioContext";

const filter = createFilterOptions();

const TituloGremios = ({ gremio }) => {
  const { screenWidth } = useContext(EstilosContext);
  const { servidor } = useContext(ServidorContext);
  const { obra } = useContext(ObraContext);
  const { usuario } = useContext(UsuarioContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const {
    controlDeAvance,
    setControlDeAvance,
    tareasAsignadas,
    setTareasAsignadas,
    tareasFiltradas,
    setTareasFiltradas,
  } = useContext(ControlDeAvanceContext);

  const color = traer_color("blue", 500);
  const [bgColor, setBgColor] = useState(color);

  const [tarea, setTarea] = useState("");

  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = useState(false);
  const [openTarea, setOpenTarea] = useState(false);
  const [openCategoria, setOpenCategoria] = useState(false);

  const initValid = { tareaValid: false };
  const [validForm, setValidForm] = useState(initValid);

  const [dialogValue, setDialogValue] = React.useState({
    IDTarea: "",
    CodTarea: "",
    Tarea: "",
    Unidad: "",
    CodCategoria: "",
    Cuantificacion: "",
  });

  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("");

  const [asignacionMultiple, setAsignacionMultiple] = useState(false);

  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [lista, setLista] = useState([]);
  const [listaPorCategoria, setListaPorCategoria] = useState([]);

  const [obrasSeleccionadas, setObrasSeleccionadas] = useState("");

  const [camposDisponibles, setCamposDisponibles] = useState({
    tarea: false,
    unidad: false,
    cuantificacion: false,
  });

  const [asignarTarea, setAsignarTarea] = useState(false);
  // const [asignacionMultiple, setAsignacionMultiple] = useState(false);

  const plano = desencriptar(localStorage.getItem("plano"));

  useEffect(() => {
    if (asignarTarea) {
      asignar_tarea();
      setAsignarTarea(false);
    }
  }, [tarea]);

  // useEffect(() => {
  //   console.log("tareasAsignadas: ", tareasAsignadas);
  // }, [tareasAsignadas]);

  useEffect(() => {
    setValue({ IDTarea: "", Tarea: "" }); // Esto debería resetear el valor del Autocompletado de tareas.
    // setDialogValue({ ...dialogValue, Unidad: "", Cuantificacion: "" });
    setDialogValue({
      IDTarea: "",
      CodTarea: "",
      Tarea: "",
      Unidad: "",
      CodCategoria: "",
      Cuantificacion: "",
    });

    if (categoriaSeleccionada) {
      let tasks = lista;

      let tareasFiltradasPorCategoria = tasks.filter(
        (tar) => tar.CodCategoria === categoriaSeleccionada.CodCategoria
      );

      // console.log("PASA");
      setListaPorCategoria(tareasFiltradasPorCategoria);
    } else {
      setListaPorCategoria([]);
    }

    if (
      categoriaSeleccionada !== "" &&
      categoriaSeleccionada !== undefined &&
      categoriaSeleccionada !== null
    ) {
      setCamposDisponibles({ ...camposDisponibles, tarea: true });
    } else {
      setCamposDisponibles({
        ...camposDisponibles,
        tarea: false,
        unidad: false,
        cuantificacion: false,
      });
    }

    // console.log("CAT: ", categoriaSeleccionada);
  }, [categoriaSeleccionada]);

  const handleAddTarea = async () => {
    toggleOpen(!open);
    setCategoriaSeleccionada("");
    setDialogValue({
      IDTarea: "",
      CodTarea: "",
      Tarea: "",
      Unidad: "",
      CodCategoria: "",
      Cuantificacion: "",
    });

    setValue(null);

    // const API_ENDPOINT = servidor + `api_tareas.php`;
    // // console.log("IDObra: ", obra.IDObra);
    // const datos = await fetch(API_ENDPOINT, {
    //   method: "POST",
    //   // headers: {
    //   //   Accept: "application/json",
    //   //   "Content-Type": "application/json",
    //   // },
    //   body: JSON.stringify({
    //     operacion: "AGREGAR_TAREA",
    //     idObra: grupo.IDObra,
    //     // productos: "VOLQUETES",
    //   }),
    // });
    // const respuesta = await datos.json();
    // console.log("grupos: ", respuesta.result.listaGrupos);
    // if (respuesta.result.listaGrupos) {
    //   setChipData(respuesta.result.listaGrupos);
    // } else {
    //   console.log("NO TRAE NADA EN traer_grupos()");
    //   // setState([]);
    // }
  };

  useEffect(() => {
    // console.log("plano: ", plano);
    // console.log("controlDeAvance: ", controlDeAvance);

    setCategoriaSeleccionada("");

    setControlDeAvance({
      ...controlDeAvance,
      IDGremio: gremio.IDGremio,
    });

    // console.log("IDGRUPO: ", grupo.IDGrupo);
    listar_tareas();
    // console.log("tareasAsignadas: ", tareasAsignadas);
  }, []);

  useEffect(() => {
    if (tarea && tarea.Tarea !== "" && tarea.Tarea !== undefined) {
      const nuevasTareasAsignadas = [...tareasAsignadas];

      let gre = nuevasTareasAsignadas.filter(
        (grem) => grem.IDGremio === gremio.IDGremio
      );

      // console.log("Grupo: ", grupo);
      if (gre.length > 0) {
        // console.log("gru: ", gru[0]);
        // console.log("IDSector: ", controlDeAvance.IDSector);
        // Verificar si la tarea ya existe en la lista
        const tareaExiste = gre[0].Tareas.some(
          (option) =>
            option.IDDepartamento === controlDeAvance.IDDepartamento &&
            option.IDGremio === controlDeAvance.IDGremio &&
            option.Tarea === dialogValue.Tarea
        );

        if (tareaExiste) {
          setMensaje({
            text:
              "La tarea ya está asignada en el gremio " + gremio.Gremio + ".",
            tipo: "error",
          });
          setMostrarMensaje(true);

          // console.log("dialog: ", dialogValue);
          setValidForm({ tareaValid: false });
        }
      }
    }
  }, [tarea]);

  const listar_tareas = async () => {
    // console.log(lista);

    setMostrarProgress(true);

    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_TAREAS",
        idObra: obra.IDObra,
        //   lista: texto,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.listaTareas) {
      // console.log("lista 2: ", respuesta.result.listaTareas);

      // console.log("PASA");
      setLista(respuesta.result.listaTareas);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: listar_tareas()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // console.log("objeto: ", {
    //   IDTarea: parseInt(dialogValue.IDTarea, 10),
    //   CodCategoria: categoriaSeleccionada.CodCategoria,
    //   CodTarea: dialogValue.CodTarea,
    //   Tarea: dialogValue.Tarea,
    //   Unidad: dialogValue.Unidad,
    //   Cuantificacion: dialogValue.Cuantificacion,
    // });

    if (
      categoriaSeleccionada.CodCategoria !== undefined &&
      categoriaSeleccionada !== ""
    ) {
      if (validForm.tareaValid) {
        if (dialogValue.Tarea !== "" && dialogValue.Tarea !== undefined) {
          if (dialogValue.Unidad !== "" && dialogValue.Unidad !== undefined) {
            // Verificar si la tarea ya existe en la lista
            const tareaExiste = lista.some(
              (option) =>
                option.CodCategoria === categoriaSeleccionada.CodCategoria &&
                option.Tarea === dialogValue.Tarea
            );

            // console.log("lista: ", lista);
            // console.log("categoriaSeleccionada: ", categoriaSeleccionada);
            // console.log("Tarea: ", dialogValue.Tarea);
            // console.log("Inputvalue: ", tarea.inputValue);

            setValue({
              IDTarea: parseInt(dialogValue.IDTarea, 10),
              CodCategoria: categoriaSeleccionada.CodCategoria,
              CodTarea: dialogValue.CodTarea,
              Tarea: dialogValue.Tarea,
              Unidad: dialogValue.Unidad,
              Cuantificacion: dialogValue.Cuantificacion,
            });

            // console.log("tareaExiste: ", tareaExiste);

            if (!tareaExiste) {
              // console.log("AGREGA TAREA");

              agregar_tarea_a_db();
            } else {
              // console.log("NO AGREGA TAREA");
              // no_agregar();
              asignar_tarea();
            }

            handleClose();
          } else {
            setMensaje({
              text: "Ingrese una unidad.",
              tipo: "error",
            });
            setMostrarMensaje(true);
          }
        } else {
          setMensaje({
            text: "Ingrese una tarea.",
            tipo: "error",
          });
          setMostrarMensaje(true);
        }
      } else {
        setMensaje({
          text:
            "La tarea ya está asignada en el gremio " +
            gremio.Gremio +
            ", por favor cámbiela.",
          tipo: "error",
        });
        setMostrarMensaje(true);
      }
    } else {
      setMensaje({
        text: "Seleccione la categoría.",
        tipo: "error",
      });
      setMostrarMensaje(true);
    }
  };

  const handleClose = () => {
    setDialogValue({
      IDTarea: "",
      CodTarea: "",
      Tarea: "",
      Unidad: "",
      CodCategoria: "",
      Cuantificacion: "",
    });
    toggleOpen(false);
  };

  const asignar_tarea = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    console.log("obj: ", {
      operacion: "ASIGNAR_TAREA_A_LOCAL_GREMIO",
      idObra: obra.IDObra,
      idPlano: plano.IDPlano,
      idDepartamento: controlDeAvance.IDDepartamento,
      idLocal: controlDeAvance.IDLocal,
      idGremio: gremio.IDGremio,
      idTarea: tarea.IDTarea,
      idUsuario: usuario.IDUsuario,
      cuantificacion: dialogValue.Cuantificacion,
      asignacionMultiple: asignacionMultiple,
    });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },

      body: JSON.stringify({
        operacion: "ASIGNAR_TAREA_A_LOCAL_GREMIO",
        idObra: obra.IDObra,
        idPlano: plano.IDPlano,
        idDepartamento: controlDeAvance.IDDepartamento,
        idLocal: controlDeAvance.IDLocal,
        idGremio: gremio.IDGremio,
        idTarea: tarea.IDTarea,
        idUsuario: usuario.IDUsuario,
        cuantificacion: dialogValue.Cuantificacion,
        asignacionMultiple: asignacionMultiple,
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.respuesta) {
      // console.log(respuesta.result.proveedores);
      // setProveedores(respuesta.result.proveedores);
      // setProveedorElegido(respuesta.result.proveedores[0]);
      // console.log(respuesta.result.proveedores);
      // alert("Lista de precios actualizada.");

      // Creamos una copia del estado actual
      const nuevasTareasAsignadas = [...tareasAsignadas];
      // console.log("nuevasTareasAsignadas: ", nuevasTareasAsignadas);
      let tareaNueva = respuesta.result.tareaNueva;
      // console.log("tareaNueva: ", tareaNueva);

      let gremioConocido = false;

      let i = 1;

      nuevasTareasAsignadas.forEach((gre) => {
        if (gre.IDGremio === gremio.IDGremio) {
          gre.Tareas.push(tareaNueva);
          gremioConocido = true;
          // console.log("PUSH " + i + ": ", tareaNueva);
          i++;
        }
      });

      // let nuevasTareasFiltradas = [...tareasFiltradas];

      // nuevasTareasFiltradas.forEach((gru) => {
      //   if (gru.IDGrupo === grupo.IDGrupo) {
      //     gru.Tareas.push(tareaNueva);
      //   }
      // });

      // console.log("gremioConocido: ", gremioConocido);

      if (gremioConocido) {
        // console.log("PASA POR GREMIO CONOCIDO");

        setTareasAsignadas(nuevasTareasAsignadas);
        // setTareasFiltradas(nuevasTareasFiltradas);
        // console.log("NUEVAS: ", nuevasTareasAsignadas);
      } else {
        // console.log("PASA POR GREMIO DESCONOCIDO");

        setTareasAsignadas([
          ...tareasAsignadas,
          {
            IDGremio: gremio.IDGremio,
            Gremio: gremio.Gremio,
            IDObra: obra.IDObra,
            IDPlano: plano.IDPlano,
            Tareas: [tareaNueva],
          },
        ]);

        // console.log()

        // setTareasFiltradas([
        //   ...tareasFiltradas,
        //   {
        //     IDGrupo: grupo.IDGrupo,
        //     Grupo: grupo.Grupo,
        //     IDObra: obra.IDObra,
        //     Tareas: [tareaNueva],
        //   },
        // ]);
      }

      // console.log("tareasAsignadas NUEVO: ", tareasAsignadas);
      // console.log("tareasFiltradas NUEVO: ", tareasFiltradas);

      setMensaje({
        text: "La tarea ha sido asignada con éxito.",
        tipo: "success",
      });
      setMostrarMensaje(true);
    } else {
      setMensaje({
        text: "Error al asignar la tarea.",
        tipo: "error",
      });
      setMostrarMensaje(true);

      console.log("NO TRAE NADA EN asignar_tarea()");
      // setState([]);
    }
  };

  const agregar_tarea_a_db = async () => {
    const API_ENDPOINT = servidor + `api_tareas.php`;

    // console.log("agregar_tarea_a_db(): ", {
    //   operacion: "AGREGAR_TAREA",
    //   idObra: obra.IDObra,
    //   tarea: dialogValue.Tarea,
    //   unidad: dialogValue.Unidad,
    //   codCategoria: categoriaSeleccionada.CodCategoria,
    //   arrObras: obrasSeleccionadas,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "AGREGAR_TAREA",
        idObra: obra.IDObra,
        tarea: dialogValue.Tarea,
        unidad: dialogValue.Unidad,
        codCategoria: categoriaSeleccionada.CodCategoria,
        arrObras: obrasSeleccionadas,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    // console.log("idTarea: ", respuesta.result.idTarea);
    if (respuesta.result.idTarea) {
      // console.log(respuesta.result.proveedores);
      // setProveedores(respuesta.result.proveedores);
      // setProveedorElegido(respuesta.result.proveedores[0]);
      // console.log(respuesta.result.proveedores);
      // alert("Lista de precios actualizada.");

      setMensaje({
        text: "La tarea ha sido agregada con éxito.",
        tipo: "success",
      });
      setMostrarMensaje(true);

      let sCodTarea = respuesta.result.codTarea;

      // console.log("tarea: ", {
      //   IDTarea: respuesta.result.idTarea,
      //   CodCategoria: categoriaSeleccionada.CodCategoria,
      //   CodTarea: sCodTarea,
      //   Tarea: dialogValue.Tarea,
      //   Unidad: dialogValue.Unidad,
      // });

      setTarea({
        IDTarea: respuesta.result.idTarea,
        CodCategoria: categoriaSeleccionada.CodCategoria,
        CodTarea: sCodTarea,
        Tarea: dialogValue.Tarea,
        Unidad: dialogValue.Unidad,
      });

      setAsignarTarea(true);

      // props.setActualizarTarea(true);
    } else {
      setMensaje({
        text: "Error al agregar la tarea.",
        tipo: "error",
      });
      setMostrarMensaje(true);

      console.log("NO TRAE NADA EN agregar_tarea_a_db()");
      // setState([]);
    }
  };

  //   const handleCheck = (event) => {
  //     // console.log("event: ", event.target.checked);

  //     // setAsignacionMultiple(event.target.checked);

  //     if (event.target.checked) {
  //       setAsignacionMultiple(true);
  //     } else {
  //       setAsignacionMultiple(false);
  //     }
  //   };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column", // Ajusta la dirección a columna
        justifyContent: "center",
        width: "100%",
        backgroundColor: bgColor,
        fontSize:
          screenWidth === "xs" || screenWidth === "sm" ? "26px" : "60px",
      }}
    >
      <div
        style={{
          fontSize: "25px",
          color: "#FFFFFF",
          paddingLeft: "10px",
          // padding: "15px",
          //   alignItems: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // border: "1px solid #FF0000",
        }}
      >
        {gremio.Gremio}

        <IconButton
          aria-label="add"
          sx={{ fontSize: 40, color: "#FFFFFF", cursor: "pointer" }}
        >
          <AddCircleOutlineOutlinedIcon
            fontSize="inherit"
            onClick={() => handleAddTarea(gremio.IDGremio)}
          />
        </IconButton>
        {/* 
        <Typography
          style={{  marginLeft: "5px" }}
        >
          AGREGAR TAREA
        </Typography> */}

        {/* <AddCircleOutlineOutlinedIcon
          sx={{ fontSize: 40, cursor: "pointer" }}
        /> */}
      </div>

      <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>Agregar tarea</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Agregá una tarea al gremio {gremio.Gremio}!
            </DialogContentText>
            <br />
            <SelectComboCategoria
              screen={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              setCategoriaSeleccionada={setCategoriaSeleccionada}
            />
            <br />
            {/* {console.log("VAL: ", value)} */}
            <Autocomplete
              id="free-solo-dialog-demo"
              options={listaPorCategoria}
              value={value}
              // inputValue={value === null ? "" : value.Tarea}
              clearOnBlur={false}
              selectOnFocus
              handleHomeEndKeys
              freeSolo
              disabled={!camposDisponibles.tarea}
              onChange={(event, newValue) => {
                // console.log("newValue: ", newValue);

                if (typeof newValue === "string") {
                  // console.log("PASA POR 1");

                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    toggleOpen(true);

                    setDialogValue({
                      IDTarea: "",
                      Tarea: newValue,
                    });
                  });
                } else if (newValue && newValue.inputValue) {
                  // console.log("PASA POR 2: Cuando agrega una tarea.");

                  toggleOpen(true);
                  setDialogValue({
                    IDTarea: "",
                    Tarea: newValue.inputValue,
                    Unidad: "",
                  });

                  setCamposDisponibles({
                    ...camposDisponibles,
                    unidad: true,
                    cuantificacion: false,
                  });
                } else {
                  // console.log(
                  //   "PASA POR 3: Cuando selecciona una tarea existente."
                  // );
                  setValue(newValue);

                  if (newValue !== null) {
                    setDialogValue({
                      IDTarea: newValue.IDTarea,
                      Tarea: newValue.Tarea,
                      Unidad: newValue.Unidad,
                    });
                    setCamposDisponibles({
                      ...camposDisponibles,
                      cuantificacion: true,
                    });
                  } else {
                    setDialogValue({
                      IDTarea: "",
                      Tarea: "",
                      Unidad: "",
                      Cuantificacion: "",
                    });
                    setCamposDisponibles({
                      ...camposDisponibles,
                      unidad: false,
                      cuantificacion: false,
                    });
                  }
                }

                // setLista([...lista, newValue]);

                setTarea(newValue);
                // alert(JSON.stringify(newValue));

                setValidForm({ ...validForm, tareaValid: true });
                // setCamposDisponibles({ ...camposDisponibles, unidad: true });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // console.log("options: ", options);
                // console.log("params: ", params);

                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    Tarea: `Agregar "${params.inputValue}"`,
                  });
                }
                // console.log("filtered: ", filtered);
                return filtered;
              }}
              getOptionLabel={(option) => {
                // console.log("option: ", option);
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  // console.log("PASA POR A");
                  return option;
                }
                if (option && option.inputValue) {
                  // console.log("PASA POR B");
                  return option.inputValue;
                }
                if (option && option.Tarea) {
                  // console.log("PASA POR C");

                  return option.Tarea;
                }
                return "";
                // if (typeof option === "string") {
                //   return option;
                // }
                // if (option.inputValue) {
                //   return option.inputValue;
                // }
                // console.log("option.inputValue: ", option.inputValue);
                // return option.Tarea;
              }}
              // clearOnBlur

              renderOption={(props, option) => (
                <li {...props} key={option.IDTarea}>
                  {option.Tarea}
                </li>
              )}
              // sx={{ width: 300 }}

              renderInput={(params) => (
                <TextField {...params} label="Tarea" variant="standard" />
              )}
            />
            {/* <TextField
              autoFocus
              margin="dense"
              id="tarea"
              value={dialogValue.Tarea}
              onChange={(event) => {
                setDialogValue({
                  ...dialogValue,
                  Tarea: event.target.value,
                  // Tarea: dialogValue.Tarea,
                });

                setTarea({
                  ...tarea,
                  IDTarea: "",
                  Tarea: event.target.value,
                  // Tarea: dialogValue.Tarea,
                });
              }}
              label="Tarea"
              type="text"
              variant="standard"
              style={{ display: "none" }}
            /> */}
            &nbsp;
            <TextField
              autoFocus
              margin="dense"
              id="unit"
              disabled={!camposDisponibles.unidad}
              value={dialogValue.Unidad}
              onChange={(event) => {
                setDialogValue({
                  ...dialogValue,
                  Unidad: event.target.value,
                });

                setTarea({
                  ...tarea,
                  Unidad: event.target.value,
                });

                setCamposDisponibles({
                  ...camposDisponibles,
                  cuantificacion: true,
                });
              }}
              label="Unidad"
              type="text"
              variant="standard"
            />
            <br />
            <TextField
              autoFocus
              margin="dense"
              id="cuantificacion"
              disabled={!camposDisponibles.cuantificacion}
              value={dialogValue.Cuantificacion}
              onChange={(event) => {
                setDialogValue({
                  ...dialogValue,
                  Cuantificacion: event.target.value,
                });

                setTarea({
                  ...tarea,
                  Cuantificacion: event.target.value,
                });
              }}
              label="Cuantificación"
              type="text"
              variant="standard"
            />
            <br />
            <br />
            <br />
            {/* <Grid container>
              <Grid item>
                <Checkbox
                  id="check"
                  key="check"
                  icon={<RadioButtonUncheckedIcon sx={{ fontSize: 40 }} />}
                  checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                  style={{
                    paddingLeft: "0px",
                    paddingTop: "0px",
                    // border: "1px solid #FF0000",
                  }}
                  // checked={seleccionados.some(
                  //   (item) => item.IDDocumentacion == docs.IDDocumentacion
                  // )}
                  onChange={(event) => handleCheck(event)}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  // style={{ border: "1px solid #FF0000" }}
                >
                  Asignar a todas las combinaciones {controlDeAvance.Sector}
                  {" - "}
                  {grupo.Grupo} <br /> (No se asignará la cuantificación)
                </Typography>
              </Grid>
            </Grid> */}
            {/* <br />
                  <br />
                  <br />
                  {listadoObras.map((obra) => (
                    <div key={obra.IDObra}>
                      <Checkbox
                        id={obra.IDObra}
                        key={obra.IDObra}
                        icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                        checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                        // checked={seleccionados.some(
                        //   (item) => item.IDDocumentacion == docs.IDDocumentacion
                        // )}
                        onChange={(event) => handleChange(event, obra)}
                      />
                      {obra.Obra.toUpperCase()}
                    </div>
                  ))} */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleSubmit}>Agregar</Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* <Dialog
        open={open}
        onClose={handleClose}
        style={{
          width: "100%",
          // border: "1px solid #FF0000",
        }}
      >
     
      <form>
          <DialogTitle>Agregar Grupo</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ejemplos: "Muro", "Piso", "Puertas", etc.
            </DialogContentText>

            <br />
            <TextField
              autoFocus
              margin="dense"
              id="grupo"
              value={dialogValue.Grupo}
              onChange={(event) => {
                setDialogValue({
                  ...dialogValue,
                  Grupo: event.target.value,
                });

                setGrupos({
                  ...grupos,
                  IDGrupo: "",
                  Grupo: event.target.value,
                });
              }}
              label="Grupo"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleSubmit}>Agregar</Button>
          </DialogActions>
        </form>
      </Dialog> */}
    </div>
  );
};

export default TituloGremios;
