import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import UsuarioContext from "../../contextos/UsuarioContext";

import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";

import { desencriptar, encriptar } from "../../funciones/Funciones";
import { registerLocale } from "react-datepicker";
import MensajesContext from "../../contextos/MensajesContext";
import AlertValidConfig from "../../components/AlertValidConfig";
import ServidorContext from "../../contextos/ServidorContext";

const steps = ["Paso 1", "Paso 2"];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const styles = {
  container: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  error: {
    color: "#FF0000",
    marginBottom: 10,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    // borderWidth: 1,
    // borderColor: "#FF0000",
  },
};

const PageAltaObra = (props) => {
  var objusu = desencriptar(localStorage.getItem("objusu"));

  useEffect(() => {
    props.setTitulo("Alta de obra");
    props.setPagina("");

    // console.log("A");
  }, [props.setTitulo]);

  useEffect(() => {
    setUsuario(objusu);
  }, []);

  const { servidor } = useContext(ServidorContext);
  const { usuario, setUsuario } = useContext(UsuarioContext);
  const [titulo, setTitulo] = useState({ text: "", valid: false, error: "" });
  const [direccion, setDireccion] = useState({
    text: "",
    valid: false,
    error: "",
  });
  const [razonSocial, setRazonSocial] = useState({
    text: "",
    valid: false,
    error: "",
  });
  const [cuit, setCuit] = useState({ text: "", valid: false, error: "" });
  const [email, setEmail] = useState({ text: "", valid: false, error: "" });
  const [telefono, setTelefono] = useState({
    text: "",
    valid: false,
    error: "",
  });

  const [whatsappAdminObra, setWhatsappAdminObra] = useState({
    text: "",
    valid: false,
    error: "",
  });

  // useEffect(() => {
  //   console.log(user.valid);
  //   console.log(email.valid);
  // }, [user.valid, email.valid]);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  let navigate = useNavigate();

  const validarFormulario = () => {
    // chequearSiUsuarioExiste();

    // chequearSiEmailExiste();

    if (
      titulo.valid &&
      direccion.valid &&
      razonSocial.valid &&
      cuit.valid &&
      email.valid &&
      telefono.valid &&
      whatsappAdminObra.valid
    ) {
      //   let objregusu = desencriptar(localStorage.getItem("objregusu"));

      alta_obra();
    } else {
      if (!titulo.valid) {
        setMensaje({ text: "Título de la obra incorrecto", tipo: "error" });
      } else if (!direccion.valid) {
        setMensaje({ text: "Dirección incorrecta", tipo: "error" });
      } else if (!razonSocial.valid) {
        setMensaje({ text: "Razón Social incorrecta", tipo: "error" });
      } else if (!cuit.valid) {
        setMensaje({ text: "Cuit incorrecto", tipo: "error" });
      } else if (!email.valid) {
        setMensaje({ text: "E-mail incorrecto", tipo: "error" });
      } else if (!telefono.valid) {
        setMensaje({
          text: "Teléfono incorrecto",
          tipo: "error",
        });
      } else if (!whatsappAdminObra.valid) {
        setMensaje({
          text: "Whatsapp del administrador de la obra incorrecto",
          tipo: "error",
        });
      } else {
        setMensaje({ text: "Datos incorrectos", tipo: "error" });
      }

      setMostrarMensaje(true);
    }
  };

  const validarTitulo = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    if (text.length == 0) {
      // Título vacío.
      setTitulo({
        text: text,
        valid: false,
        error: "",
      });
    } else if (text.length < 3) {
      // console.log("Nombre muy corto.");
      setTitulo({
        text: text,
        valid: false,
        error: "Título de obra muy corto",
      });
    } else {
      // console.log("Nombre OK.");
      setTitulo({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const validarDireccion = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    if (text.length == 0) {
      // Dirección vacío.
      setDireccion({
        text: text,
        valid: false,
        error: "",
      });
    } else if (text.length < 3) {
      // console.log("Apellido muy corto.");
      setDireccion({
        text: text,
        valid: false,
        error: "Dirección de obra muy corta",
      });
    } else {
      // console.log("Dirección OK.");
      setDireccion({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const validarRazonSocial = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    if (text.length == 0) {
      // CUIT vacío.
      setRazonSocial({
        text: text,
        valid: false,
        error: "",
      });
    } else if (text.length < 3) {
      // console.log("Cuit muy corto.");
      setRazonSocial({
        text: text,
        valid: false,
        error: "Razón Social de la obra muy corta",
      });
    } else {
      // console.log("Cuit OK.");
      setRazonSocial({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const validarCuit = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    if (text.length == 0) {
      // CUIT vacío.
      setCuit({
        text: text,
        valid: false,
        error: "",
      });
    } else if (text.length < 3) {
      // console.log("Cuit muy corto.");
      setCuit({
        text: text,
        valid: false,
        error: "Cuit de obra muy corto",
      });
    } else {
      // console.log("Cuit OK.");
      setCuit({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const validarTelefono = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    if (text.length == 0) {
      // Teléfono vacío.
      setTelefono({
        text: text,
        valid: false,
        error: "",
      });
    } else if (text.length < 3) {
      // console.log("Teléfono muy corto.");
      setTelefono({
        text: text,
        valid: false,
        error: "Teléfono muy corto",
      });
    } else {
      // console.log("Teléfono OK.");
      setTelefono({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const validarWhatsappAdminObra = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;

    if (text.length == 0) {
      // Teléfono vacío.
      setWhatsappAdminObra({
        text: text,
        valid: false,
        error: "",
      });
    } else if (text.length < 10) {
      // console.log("Teléfono muy corto.");
      setWhatsappAdminObra({
        text: text,
        valid: false,
        error: "Whatsapp de administrador de la obra muy corto",
      });
    } else {
      // console.log("Teléfono OK.");
      setWhatsappAdminObra({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const validarEmail = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;
    // console.log(text);

    // VALIDACIÓN DE USUARIO
    // if (text.length > 2) {
    //   setEmail({ text: text, valid: true, error: "" });
    // } else {
    //   setEmail({
    //     text: text,
    //     valid: false,
    //     error: "Email o usuario incorrecto",
    //   });
    // }

    // VALIDACIÓN DE MAIL
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      if (text.length == 0) {
        // Email vacío
        setEmail({ text: text, valid: false, error: "" });
      } else {
        setEmail({ text: text, valid: false, error: "Email incorrecto" });
      }

      return false;
    } else {
      // console.log(text.length);

      // console.log("Email is Correct");
      setEmail({ text: text, valid: true, error: "" });
    }
  };

  const alta_obra = async () => {
    try {
      const API_ENDPOINT = servidor + `api_obras.php`;

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "ALTA_OBRA",
          idUsuarioCreador: usuario.IDUsuario,
          titulo: titulo.text,
          direccion: direccion.text,
          razonSocial: razonSocial.text,
          cuit: cuit.text,
          email: email.text,
          telefono: telefono.text,
          whatsappAdminObra: whatsappAdminObra.text,
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const obras = await datos.json();

      // console.log(usuarios.result.usuario);

      if (obras.result.alta === "ALTA_OK") {
        // console.log(usuarios.result.usuario);

        // localStorage.setItem("objregusu", encriptar(usuarios.result.usuario));

        copiar_categorias_default(obras.result.idObra);

        setMensaje({
          text: "La obra ha sido agregada con éxito.",
          tipo: "success",
        });
        setMostrarMensaje(true);

        setTimeout(() => {
          // props.setMostrar("FORMULARIO_SELECCIONAR_MODULOS");
          navigate("/selectObra");
        }, 4000);
      } else {
        setMensaje({ text: "Error en el alta de obra", tipo: "error" });

        setMostrarMensaje(true);
      }

      return obras.result;
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  const copiar_categorias_default = async (idObra) => {
    try {
      const API_ENDPOINT = servidor + `api_tareas.php`;

      // var base64 = require("base-64");

      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "COPIAR_CATEGORIAS_DEFAULT",
          idObra: idObra,
        }),

        // headers: headers,
        //credentials: 'user:passwd'
      });

      const obras = await datos.json();

      // console.log(usuarios.result.usuario);

      if (obras.result.respuesta === "OK") {
        // console.log(usuarios.result.usuario);
        // localStorage.setItem("objregusu", encriptar(usuarios.result.usuario));
        // setMensaje({
        //   text: "La obra ha sido agregada con éxito.",
        //   tipo: "success",
        // });
        // setMostrarMensaje(true);
        // setTimeout(() => {
        //   // props.setMostrar("FORMULARIO_SELECCIONAR_MODULOS");
        //   navigate("/selectObra");
        // }, 4000);
      } else {
        setMensaje({ text: "Error al copiar las categorías", tipo: "error" });

        setMostrarMensaje(true);
      }

      return obras.result;
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  // const handleBack = () => {
  //   props.setMostrar("FORMULARIO_LOGIN");
  //   navigate("/");
  // };

  return (
    <>
      <div>
        <AlertValidConfig
          mostrarMensaje={mostrarMensaje}
          mensaje={mensaje.text}
          tipo={mensaje.tipo}
        />
        <Box
          sx={{
            display: "flex",
            // flexWrap: "wrap",
            alignContent: "center",
            justifyContent: "center",
            p: 1,
            m: 1,
            mt: 2,
            // border: 1,
            bgcolor: "background.paper",
            // maxWidth: 380,
            // width: { xs: 300, md: 400 },
            height: "auto",
            borderRadius: 1,
          }}
        >
          <Item sx={{ width: { xs: 350, md: 350, height: "550px" } }}>
            <Grid container xs={12}>
              <Grid item xs={1}>
                {/* <IconButton
                  color="inherit"
                  // onClick={handleAltaPedido}
                  aria-label="volver"
                  size="large"
                >
                  <ArrowBackIcon
                    // fontSize="large"
                    fontSize="inherit"
                    // sx={{ color: "#FFFFFF" }}
                    onClick={() => handleBack()}
                  />
                </IconButton> */}
              </Grid>
              <Grid item xs={10}>
                <Box
                  component="img"
                  sx={{
                    // border: "1px solid #000000",
                    height: 48,
                    width: 226,
                    maxHeight: { xs: 48, md: 72 },
                    maxWidth: { xs: 226, md: 339 },
                    mt: 1,
                  }}
                  alt="Bimtracker"
                  src="bimtracker.png"
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            <div style={{ fontWeight: "bold", fontSize: "18px" }}>
              AGREGAR OBRA
            </div>
            <Box
              component="form"
              sx={{
                height: 40,
                width: "100%",
                // border: 1,
                marginTop: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                required
                id="standard-required"
                label="Título de la obra"
                defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validarTitulo}
                // value={obra.text}
              />
              <div style={styles.error}>{titulo.error}</div>
              <TextField
                required
                id="standard-required"
                label="Dirección"
                defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validarDireccion}
                // value={direccion.text}
              />
              <div style={styles.error}>{direccion.error}</div>

              <TextField
                required
                id="standard-required"
                label="Razón Social"
                defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validarRazonSocial}
                // onBlur={chequearSiUsuarioExiste}
                // value={cuit.text}
                // disabled={disable.usuario ? "disabled" : ""}
              />
              <div style={styles.error}>{cuit.error}</div>

              <TextField
                required
                id="standard-required"
                label="CUIT"
                defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validarCuit}
                // onBlur={chequearSiUsuarioExiste}
                // value={cuit.text}
                // disabled={disable.usuario ? "disabled" : ""}
              />
              <div style={styles.error}>{cuit.error}</div>

              <TextField
                required
                id="standard-required"
                label="E-mail"
                defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validarEmail}
                // onBlur={chequearSiEmailExiste}
                value={email.text}
                // disabled={disable.email ? "disabled" : ""}
              />
              <div style={styles.error}>{email.error}</div>

              <TextField
                required
                id="standard-required"
                label="Teléfono"
                defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validarTelefono}
                // onBlur={chequearSiUsuarioExiste}
                // value={cuit.text}
                // disabled={disable.usuario ? "disabled" : ""}
              />
              <div style={styles.error}>{telefono.error}</div>

              <TextField
                required
                id="standard-required"
                label="Whatsapp del administrador"
                defaultValue=""
                variant="standard"
                style={{ width: "80%" }}
                onChange={validarWhatsappAdminObra}
                // onBlur={chequearSiUsuarioExiste}
                // value={cuit.text}
                // disabled={disable.usuario ? "disabled" : ""}
              />
              <div style={styles.error}>{whatsappAdminObra.error}</div>

              <br />
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() => validarFormulario()}
              >
                Confirmar
              </Button>
            </Box>
          </Item>
        </Box>
      </div>
    </>
  );
};

export default PageAltaObra;
