import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ServidorContext from "../../contextos/ServidorContext";
import ObraContext from "../../contextos/ObraContext";

import MensajesContext from "../../contextos/MensajesContext";
import EstilosContext from "../../contextos/EstilosContext";
import { traer_color } from "../../colores/Colores";

const ListaTareasConCheck = ({
  tareas,
  setTareasSeleccionadas,
  tareasSeleccionadas,
}) => {
  const { servidor } = useContext(ServidorContext);
  const { obra } = useContext(ObraContext);

  const { isMobile, screenWidth } = useContext(EstilosContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const colorTitulo = traer_color("blue", 600);
  const [bgColorTitulo, setBgColorTitulo] = useState(colorTitulo);

  //   useEffect(() => {
  //     const actualizar_tareas = async () => {
  //       await actualizar_seleccion_tarea_en_gantt();
  //     };

  //     actualizar_tareas();

  //     console.log("tareasSeleccionadas: ", tareasSeleccionadas);
  //   }, [tareasSeleccionadas]);

  const handleTarea = (event, tarea) => {
    console.log("check: ", event.target.checked);

    if (event.target.checked) {
      // Agregar la tarea seleccionada al estado
      setTareasSeleccionadas([...tareasSeleccionadas, tarea]);
    } else {
      // Remover la tarea deseleccionada del estado
      let deselec = tareasSeleccionadas.filter(
        (item) => item.IDTarea !== tarea.IDTarea
      );
      setTareasSeleccionadas(deselec);
    }
  };

  const tareasPorGremio = tareas.reduce((grupos, tarea) => {
    const { Gremio } = tarea;
    if (!grupos[Gremio]) {
      grupos[Gremio] = [];
    }
    grupos[Gremio].push(tarea);
    return grupos;
  }, {});

  return (
    <div>
      {Object.entries(tareasPorGremio || {}).map(([gremio, tareas]) => (
        <div key={gremio} style={{ marginBottom: "20px" }}>
          {/* Título del gremio */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              backgroundColor: bgColorTitulo,
              fontSize:
                screenWidth === "xs" || screenWidth === "sm" ? "26px" : "60px",
            }}
          >
            <div
              style={{
                fontSize: "25px",
                color: "#FFFFFF",
                paddingLeft: "10px",
              }}
            >
              {gremio.toUpperCase()}
            </div>
          </div>

          {/* Validación adicional */}
          {(tareas || []).map((tarea) => (
            <div key={tarea.IDTarea} style={{ padding: "10px" }}>
              <Checkbox
                id={tarea.IDTarea}
                icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                checked={tareasSeleccionadas.some(
                  (seleccionada) => seleccionada.IDTarea === tarea.IDTarea
                )}
                onChange={(event) => handleTarea(event, tarea)}
              />
              {`${tarea.Tarea} (${tarea.Unidad})`}
            </div>
          ))}
        </div>
      ))}

      {/* <List>
        {tareas.map((tarea) => (
          <ListItem
            key={tarea.IDTarea}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={tarea.Seleccionada == "SI" ? true : false}
              onChange={(event) => handleTarea(event, tarea)}
              icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
              checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
            />
            <ListItemText primary={tarea.Tarea} sx={{ fontSize: "20px" }} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );
};

export default ListaTareasConCheck;
