import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";

import {
  chequearPermisos,
  desencriptar,
  encriptar,
} from "../../../funciones/Funciones";
import ServidorContext from "../../../contextos/ServidorContext";
import EstilosContext from "../../../contextos/EstilosContext";
import ObraContext from "../../../contextos/ObraContext";
import MensajesContext from "../../../contextos/MensajesContext";
import AlertValidConfig from "../../../components/AlertValidConfig";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ControlDeAvanceContext from "../../../contextos/ControlDeAvanceContext";

const filter = createFilterOptions();

const BoxGremios = ({
  setGremioSelected,
  gremioSelected,
  ufSelected,
  localSelected,
  // idPlano,
}) => {
  const { servidor } = useContext(ServidorContext);
  const { isMobile, screenWidth } = useContext(EstilosContext);
  const { obra, setObra } = useContext(ObraContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  //   const [gremioSelected, setGremioSelected] = useState("");

  const [chipData, setChipData] = useState([]);
  const [chipSelected, setChipSelected] = useState([]);

  //   useEffect(() => {
  // setPaso1(desencriptar(localStorage.getItem("objordserv")));
  //   }, []);

  // console.log("SECTOR: ", sector);

  useEffect(() => {
    // console.log("ufSelected.IDDepartamento: ", ufSelected.IDDepartamento);
    // console.log("localSelected.IDLocal: ", localSelected);

    traer_gremios();
  }, [ufSelected, localSelected]);

  //   useEffect(() => {
  //     // console.log("PASA POR sector.IDSector: ", sector.IDSector);
  //     traer_gremios();
  //   }, [sector.IDSector]);

  //   useEffect(() => {
  //     traer_gremios();

  //     // setChipSelected(gremioSelected);
  //   }, []);

  useEffect(() => {
    // console.log("chipData: ", chipData);
  }, [chipData]);

  //   useEffect(() => {
  //     // console.log("idGrupo: ", dialogValue.IDGrupo);

  //     if (asignar) {
  //       asignar_grupo();

  //       setValue({
  //         IDGrupo: parseInt(dialogValue.IDGrupo, 10),
  //         Grupo: dialogValue.Grupo,
  //       });

  //       setAsignar(false);
  //     }
  //   }, [asignar]);

  useEffect(() => {
    // return () => {
    // let paso1 = desencriptar(localStorage.getItem("objordserv"));
    // let paso2 = { ...paso1, grupoSelected: chipSelected };

    // localStorage.setItem("objordserv", encriptar(paso2));

    setGremioSelected(chipSelected);

    // console.log("chipSelected: ", chipSelected);

    // console.log("paso2: ", paso2);
    // };
  }, [chipSelected]);

  //   const guardar_grupo = async () => {
  //     const API_ENDPOINT = servidor + `api_grupos.php`;

  //     // console.log("idPlano: ", plano.IDPlano);
  //     // console.log("dialogValue.Grupo: ", dialogValue.Grupo);

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       // headers: {
  //       //   Accept: "application/json",
  //       //   "Content-Type": "application/json",
  //       // },
  //       body: JSON.stringify({
  //         operacion: "GUARDAR_GRUPO",
  //         idObra: obra.IDObra,
  //         idDepartamento: idDepartamento,
  //         idSector: sector.IDSector,
  //         grupo: dialogValue.Grupo,

  //         // productos: "VOLQUETES",
  //       }),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log("respuesta 1: ", respuesta);

  //     if (respuesta.result.resultado === "OK") {
  //       // console.log("result: ", respuesta.result.palabrasClaves[0]);
  //       // setChipData([...chipData, respuesta.result.grupos[0]]);

  //       // setGroupSelected("");
  //       // setGrupoSelected("");

  //       setDialogValue({
  //         IDGrupo: respuesta.result.grupos[0]["IDGrupo"],
  //         Grupo: respuesta.result.grupos[0]["Grupo"],
  //       });

  //       // setGrupoSelected({
  //       //   ...grupoSelected,
  //       //   IDGrupo: respuesta.result.grupos[0]["IDGrupo"],
  //       //   Grupo: respuesta.result.grupos[0]["Grupo"],
  //       // });

  //       setAsignar(true);
  //       // setMensaje({ text: "Grupo agregado con éxito!", tipo: "success" });

  //       // setMostrarMensaje(true);
  //     } else {
  //       console.log("NO TRAE NADA EN guardar_grupo()");
  //       // setState([]);
  //     }
  //   };

  const traer_gremios = async () => {
    const API_ENDPOINT = servidor + `api_gremios.php`;

    // console.log("IDObra: ", obra.IDObra);
    // console.log("idDepartamento: ", idDepartamento);
    // console.log("sector.IDSector: ", sector.IDSector);

    // console.log({
    //   operacion: "TRAER_GRUPOS",
    //   idObra: obra.IDObra,
    //   idDepartamento: idDepartamento,
    //   idSector: sector.IDSector,
    //   idPlano: plano.IDPlano,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_GREMIOS_DE_LOCAL",
        idObra: obra.IDObra,
        idDepartamento: ufSelected.IDDepartamento,
        idLocal: localSelected.IDLocal,
      }),
    });
    const respuesta = await datos.json();

    // console.log("listaGruposEnCombo 2: ", respuesta.result.listaGruposEnCombo);

    if (respuesta.result.resultado === "OK") {
      // console.log("listaGremios: ", respuesta.result.listaGremios);
      setChipData(respuesta.result.listaGremios);
    } else {
      setChipData([]);

      console.log("NO TRAE NADA EN traer_gremios()");
      // setState([]);
    }
  };

  const handleClick = (chip) => {
    // let found = chipSelected.filter(
    //   (chip) => chip.IDPalabraClave === idPalabraClave
    // );
    // console.log("found: ", found);

    // let newChip = "";
    // if (found.length > 0) {
    //   newChip = chipSelected.filter(
    //     (chip) => chip.IDPalabraClave !== idPalabraClave
    //   );
    //   setChipSelected(newChip);
    // } else {
    //   newChip = { IDPalabraClave: idPalabraClave };
    //   setChipSelected([...chipSelected, newChip]);
    // }

    // console.log("chip: ", chip);
    // console.log("chipSelected: ", chipSelected);
    // console.log(chip.IDGrupo + " === " + chipSelected.IDGrupo);

    if (chip.IDGremio === chipSelected.IDGremio) {
      setGremioSelected("");
      setChipSelected("");
    } else {
      setGremioSelected(chip);
      setChipSelected(chip);
    }
  };

  //   const handleSubmit = (event) => {
  //     event.preventDefault();

  //     if (dialogValue.Grupo !== "") {
  //       if (
  //         chipData.filter((chip) => chip.Grupo === dialogValue.Grupo).length > 0
  //       ) {
  //         setMensaje({ text: "El grupo ya existe.", tipo: "error" });

  //         setMostrarMensaje(true);
  //       } else {
  //         if (
  //           comboGrupos.filter((item) => item.IDGrupo === dialogValue.IDGrupo)
  //             .length === 0
  //         ) {
  //           guardar_grupo();
  //         } else {
  //           asignar_grupo();
  //         }

  //         // asignar_grupo();

  //         // setValue({
  //         //   IDGrupo: parseInt(dialogValue.IDGrupo, 10),
  //         //   Grupo: dialogValue.Grupo,
  //         // });
  //         handleClose();
  //       }
  //     } else {
  //       setMensaje({ text: "El grupo no puede ser vacío.", tipo: "error" });

  //       setMostrarMensaje(true);
  //     }
  //   };

  //   const handleClose = () => {
  //     setDialogValue({
  //       IDGrupo: "",
  //       Grupo: "",
  //     });
  //     toggleOpen(false);
  //   };

  //   const handleAddGrupo = () => {
  //     // console.log("idDepartamento: ", idDepartamento);
  //     // console.log("sector.IDSector: ", sector.IDSector);

  //     if (
  //       chequearPermisos(
  //         obra.Privilegios,
  //         "CONTROL_DE_AVANCE_ROL_ADMINISTRADOR"
  //       ) === true
  //     ) {
  //       if (
  //         idDepartamento !== "" &&
  //         idDepartamento !== 0 &&
  //         idDepartamento !== undefined
  //       ) {
  //         if (
  //           sector.IDSector !== "" &&
  //           sector.IDSector !== 0 &&
  //           sector.IDSector !== undefined
  //         ) {
  //           toggleOpen(true);
  //         } else {
  //           setMensaje({
  //             text: "Seleccione el sector!",
  //             tipo: "error",
  //           });

  //           setMostrarMensaje(true);
  //         }
  //       } else {
  //         setMensaje({
  //           text: "Seleccione el espacio!",
  //           tipo: "error",
  //         });

  //         setMostrarMensaje(true);
  //       }
  //     } else {
  //       setMensaje({ text: "No tiene permisos!", tipo: "error" });
  //       setMostrarMensaje(true);
  //     }
  //   };

  //   const asignar_grupo = async () => {
  //     const API_ENDPOINT = servidor + `api_grupos.php`;

  //     // console.log("idPlano: ", plano.IDPlano);
  //     // console.log("dialogValue.Grupo: ", dialogValue.Grupo);
  //     // console.log("dialogValue.IDGrupo: ", dialogValue.IDGrupo);

  //     // console.log(API_ENDPOINT);
  //     // console.log({
  //     //   operacion: "ASIGNAR_GRUPO",
  //     //   idObra: obra.IDObra,
  //     //   idDepartamento: idDepartamento,
  //     //   idSector: sector.IDSector,
  //     //   idGrupo: dialogValue.IDGrupo,
  //     //   grupo: dialogValue.Grupo,
  //     //   asignacionMultiple: asignacionMultiple,
  //     //   idPlano: plano.IDPlano,
  //     // });

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       // headers: {
  //       //   Accept: "application/json",
  //       //   "Content-Type": "application/json",
  //       // },
  //       body: JSON.stringify({
  //         operacion: "ASIGNAR_GRUPO",
  //         idObra: obra.IDObra,
  //         idDepartamento: idDepartamento,
  //         idSector: sector.IDSector,
  //         idGrupo: dialogValue.IDGrupo,
  //         grupo: dialogValue.Grupo,
  //         asignacionMultiple: asignacionMultiple,
  //         idPlano: plano.IDPlano,
  //       }),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log("respuesta: ", respuesta);

  //     if (respuesta.result.resultado === "OK") {
  //       // console.log("result: ", respuesta.result.palabrasClaves[0]);
  //       setChipData([...chipData, respuesta.result.grupos[0]]);

  //       setGrupoSelected({
  //         ...grupoSelected,
  //         IDGrupo: respuesta.result.grupos[0]["IDGrupo"],
  //         Grupo: respuesta.result.grupos[0]["Grupo"],
  //       });

  //       // setGroupSelected("");
  //       // setGrupoSelected("");
  //       setMensaje({ text: "Grupo asignado con éxito!", tipo: "success" });

  //       setMostrarMensaje(true);
  //       // setAsignar(false);
  //     } else {
  //       console.log("NO TRAE NADA EN asignar_grupo()");
  //       // setState([]);
  //     }
  //   };

  //   const handleCheck = (event) => {
  //     // console.log("Check: ", event.target.checked);

  //     if (event.target.checked) {
  //       setAsignacionMultiple(true);
  //     } else {
  //       setAsignacionMultiple(false);
  //     }
  //   };

  return (
    <>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      {/* <br /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {chipData.map((data, index) => (
          // <div
          //   style={
          //     {
          //       /*padding: "20px"*/
          //     }
          //   }
          // >
          <Chip
            key={data.IDGremio}
            label={data.Gremio.toUpperCase()}
            color="primary"
            // variant=""
            variant={
              // chipSelected.length > 0 &&
              // chipSelected.filter((chip) => chip.IDGrupo === data.IDGrupo)
              //   .length > 0
              chipSelected !== "" && chipSelected.IDGremio === data.IDGremio
                ? ""
                : "outlined"
            }
            onClick={() => handleClick(data)}
            style={{ fontSize: "25px", padding: "0px" }} // Ajusta estos valores a tu gusto
          />
          // </div>
        ))}

        {/* <Chip
          key="agregar"
          label="+"
          color="primary"
          // variant={
          //   chipSelected.length > 0 &&
          //   chipSelected.filter(
          //     (chip) => chip.IDGrupo === data.IDGrupo
          //   ).length > 0
          //     ? ""
          //     : "outlined"
          // }
          variant="outlined"
          onClick={() => handleAddGremio()}
          style={{ fontSize: "25px", padding: "10px" }} // Ajusta estos valores a tu gusto
        /> */}
      </div>
      {/* <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>Agregar Grupo</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ejemplos: "Muro", "Piso", "Puertas", etc.
            </DialogContentText>

            <br />

            <Autocomplete
              id="free-solo-dialog-demo"
              options={comboGrupos}
              value={value}
              // inputValue={value === null ? "" : value.Tarea}
              clearOnBlur={false}
              selectOnFocus
              handleHomeEndKeys
              freeSolo
              // disabled={!camposDisponibles.tarea}
              onChange={(event, newValue) => {
                // console.log("newValue: ", newValue);

                if (typeof newValue === "string") {
                  // console.log("PASA POR 1");

                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    toggleOpen(true);

                    setDialogValue({
                      IDSector: "",
                      Sector: newValue,
                    });

                    setGrupos({
                      ...grupos,
                      IDGrupo: "",
                      Grupo: newValue,
                    });
                  });
                } else if (newValue && newValue.inputValue) {
                  // console.log("PASA POR 2: Cuando agrega un grupo.");

                  toggleOpen(true);
                  setDialogValue({
                    IDGrupo: "",
                    Grupo: newValue.inputValue,
                  });

                  setGrupos({
                    ...grupos,
                    IDGrupo: "",
                    Grupo: newValue.inputValue,
                  });
                } else {
                  // console.log(
                  //   "PASA POR 3: Cuando selecciona un grupo existente."
                  // );
                  setValue(newValue);

                  if (newValue !== null) {
                    setDialogValue({
                      IDGrupo: newValue.IDGrupo,
                      Grupo: newValue.Grupo,
                    });

                    setGrupos({
                      ...grupos,
                      IDGrupo: newValue.IDGrupo,
                      Grupo: newValue.Grupo,
                    });
                  } else {
                    setDialogValue({
                      IDGrupo: "",
                      Grupo: "",
                    });

                    setGrupos({
                      ...grupos,
                      IDGrupo: "",
                      Grupo: "",
                    });
                  }
                }

                // setLista([...lista, newValue]);

                if (newValue === null) {
                  setGrupoSelected({ IDGrupo: "", Grupo: "" });
                } else {
                  setGrupoSelected(newValue);
                }
                // alert(JSON.stringify(newValue));

                setValidForm({ ...validForm, grupoValid: true });
                // setCamposDisponibles({ ...camposDisponibles, unidad: true });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // console.log("options: ", options);
                // console.log("params: ", params);

                if (
                  params.inputValue !== "" &&
                  !options.some(
                    (item) =>
                      item.Grupo.toLowerCase() ===
                      params.inputValue.toLowerCase()
                  )
                ) {
                  filtered.push({
                    inputValue: params.inputValue,
                    Grupo: `Agregar "${params.inputValue}"`,
                  });
                }
                // console.log("filtered: ", filtered);
                return filtered;
              }}
              getOptionLabel={(option) => {
                // console.log("option: ", option);
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  // console.log("PASA POR A");
                  return option;
                }
                if (option && option.inputValue) {
                  // console.log("PASA POR B");
                  return option.inputValue;
                }
                if (option && option.Grupo) {
                  // console.log("PASA POR C");

                  return option.Grupo;
                }
                return "";
                // if (typeof option === "string") {
                //   return option;
                // }
                // if (option.inputValue) {
                //   return option.inputValue;
                // }
                // console.log("option.inputValue: ", option.inputValue);
                // return option.Tarea;
              }}
              // clearOnBlur

              renderOption={(props, option) => (
                <li {...props} key={option.IDGrupo}>
                  {option.Grupo}
                </li>
              )}
              // sx={{ width: 300 }}

              renderInput={(params) => (
                <TextField {...params} label="Grupo" variant="standard" />
              )}
            />

         
          </DialogContent>
          <DialogTitle>
            
          </DialogTitle>
          <DialogContent>
            <Checkbox
              id="idCheck"
              key="idCheck"
              icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
              checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
              // checked={seleccionados.some(
              //   (item) => item.IDDocumentacion == docs.IDDocumentacion
              // )}
              onChange={(event) => handleCheck(event)}
            />
            AGREGAR ESTE GRUPO A TODOS LOS SECTORES "{sector.Sector}"
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleSubmit}>Agregar</Button>
          </DialogActions>
        </form>
      </Dialog> */}
    </>
  );
};

export default BoxGremios;
