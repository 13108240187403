import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import AlertValidConfig from "../../components/AlertValidConfig";
import EstilosContext from "../../contextos/EstilosContext";
import MensajesContext from "../../contextos/MensajesContext";
import ObraContext from "../../contextos/ObraContext";
import {
  contieneCodigoLaEntrada,
  desencriptar,
  encriptar,
} from "../../funciones/Funciones";

import Rubros from "../pedidos/Rubros";
import SeleccionDeColor from "./SeleccionDeColor";
import ServidorContext from "../../contextos/ServidorContext";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";

const AsignarProveedor = ({
  setProveedores,
  proveedores,
  setMostrarFormAsignarProveedor,
}) => {
  const { servidor } = useContext(ServidorContext);
  const { isMobile, screenWidth } = useContext(EstilosContext);
  const { obra, setObra } = useContext(ObraContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [busqueda, setBusqueda] = useState("");
  const [todosLosProveedores, setTodosLosProveedores] = useState([]);

  useEffect(() => {
    // console.log("proveedores: ", proveedores);

    traer_proveedores();
  }, []);

  const volver = () => {
    setMostrarFormAsignarProveedor(false);
  };

  const seleccionarProveedor = (prov, event) => {
    //   setProveedorElegido(prov);

    // console.log("proveedorElegido: ", prov);

    let check = event.target.checked;

    asignar_proveedor(check, prov);

    setProveedores((prevProveedores) => {
      const existe = prevProveedores.some(
        (item) => item.IDProveedor === prov.IDProveedor
      );

      if (existe) {
        // Si ya existe, se elimina de la lista
        return prevProveedores.filter(
          (item) => item.IDProveedor !== prov.IDProveedor
        );
      } else {
        // Si no existe, se agrega a la lista
        return [...prevProveedores, prov];
      }
    });

    //   setPedido({
    //     ...pedido,
    //     idProveedor: prov.IDProveedor,
    //     proveedor: prov.Proveedor,
    //     // detalle: [],
    //   });
  };

  const traer_proveedores = async () => {
    const API_ENDPOINT = servidor + `api_proveedores.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_PROVEEDORES",
        // idObra: obra.IDObra,
        rubros: busqueda,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.proveedores) {
      // console.log(respuesta.result.proveedores);
      setTodosLosProveedores(respuesta.result.proveedores);
      // setProveedorElegido(respuesta.result.proveedores[0]);
      // console.log(respuesta.result.proveedores);
      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN traer_proveedores()");
      // setState([]);
    }
  };

  const asignar_proveedor = async (check, prov) => {
    console.log("Obra: ", obra);
    console.log("proveedor: ", prov);
    // console.log("usuario: ", "");
    // console.log("email: ", email.text);
    // console.log("clave: ", "");
    // console.log("direccion: ", direccion.text);
    // console.log("telefono: ", whatsapp.text);
    // console.log("whatsapp: ", whatsapp.text);
    // console.log("contacto: ", contacto.text);
    // console.log("rubros: ", rubros);
    // console.log("proveedorColor: ", proveedorColor);
    // console.log("proveedorColorNumero: ", proveedorColorNumero);

    const API_ENDPOINT = servidor + `api_proveedores.php`;

    // console.log(codigoPedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ASIGNAR_PROVEEDOR",
        idObra: obra.IDObra,
        idProveedor: prov.IDProveedor,
        // whatsappAdminObra: obra.WhatsappAdminObra,
        check: check ? 1 : 0,
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado == "OK") {
      if (check) {
        setMensaje({ text: "Proveedor asignado con éxito!", tipo: "success" });
      } else {
        setMensaje({
          text: "Proveedor desasignado con éxito!",
          tipo: "success",
        });
      }

      setMostrarMensaje(true);
      //   setMostrarFormAsignarProveedor(false);
    } else {
      console.log("NO TRAE NADA EN asignar_proveedor()");
      // setState([]);
    }
  };

  return (
    <>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Grid
        container
        style={{
          display: "flex",
          justifyContent:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "left"
              : "center",
          // border: "1px solid #FF0000",
          width: "100%",
          paddingLeft:
            screenWidth === "md" || screenWidth === "lg" || screenWidth === "xl"
              ? "20px"
              : "",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            // border: "1px solid #FF0000",
            display: "flex",
            justifyContent:
              screenWidth === "md" ||
              screenWidth === "lg" ||
              screenWidth === "xl"
                ? "left"
                : "center",
          }}
          paddingTop={2}
        >
          <FormControl sx={{ pl: 1 }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              // defaultValue="female"
              name="radio-buttons-group"
            >
              {todosLosProveedores.map((item, index) => (
                // <div key={item.IDProveedor}>
                <FormControlLabel
                  value={item.IDProveedor}
                  key={item.IDProveedor}
                  control={
                    <Checkbox
                      // checked={index === 0}
                      checked={proveedores.some(
                        (prov) => prov.IDProveedor === item.IDProveedor
                      )}
                      icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                      checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                      onChange={(event) => seleccionarProveedor(item, event)}
                    />
                  }
                  label={item.Proveedor.toUpperCase()}
                />
                // </div>
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          &nbsp;<Button onClick={() => volver()}>Volver</Button>
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
      </Grid>
    </>
  );
};

export default AsignarProveedor;
