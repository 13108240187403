import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import Select from "react-select";

import { Navigate, useNavigate } from "react-router-dom";

import { chequearPermisos } from "../../../funciones/Funciones";
import ObraContext from "../../../contextos/ObraContext";
import EstilosContext from "../../../contextos/EstilosContext";
import PrivilegioContext from "../../../contextos/PrivilegiosContext";
import MensajesContext from "../../../contextos/MensajesContext";
import Titulo from "../../../components/Titulo";
import SelectComboLocales from "./SelectComboLocales";
import ServidorContext from "../../../contextos/ServidorContext";
import AlertValidForms from "../../../components/AlertValidForms";

const PageAltaLocal = () => {
  const { servidor } = useContext(ServidorContext);
  const { obra, setObra } = useContext(ObraContext);
  const { estilos, screenWidth } = useContext(EstilosContext);
  const { privilegios, setPrivilegios, mostrarAlert, setMostrarAlert } =
    useContext(PrivilegioContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  let navigate = useNavigate();

  const initValidLocal = {
    text: "",
    valid: true,
    error: "",
  };
  const [validLocal, setValidLocal] = useState(initValidLocal);

  //   const [listaFiltrada, setListaFiltrada] = useState([]);
  //   const [mensaje, setMensaje] = useState({ text: "", error: false });

  const [listaLocales, setListaLocales] = useState([]);
  const [localSeleccionado, setLocalSeleccionado] = useState([]);
  const [mostrarProgress, setMostrarProgress] = useState(false);

  useEffect(() => {
    listar_locales();
  }, []);

  const listar_locales = async () => {
    // console.log(lista);

    setMostrarProgress(true);

    const API_ENDPOINT = servidor + `api_locales.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_LOCALES",
        idObra: obra.IDObra,
        //   lista: texto,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.listaLocales) {
      // console.log("lista: ", respuesta.result.listaGremios);
      // console.log("PASA");
      setListaLocales(respuesta.result.listaLocales);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: listar_locales()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const handleSubmit = () => {
    console.log("validLocal: ", validLocal);
    if (validLocal.valid) {
      console.log("localSeleccionado: ", localSeleccionado);

      agregar_local_a_db(localSeleccionado);
    } else {
      setMensaje({
        text: "Ya existe un local con ese nombre.",
        tipo: "error",
      });
      setMostrarMensaje(true);
    }
  };

  const handleClose = () => {
    navigate("/locales");
  };

  const agregar_local_a_db = async (loc) => {
    const API_ENDPOINT = servidor + `api_locales.php`;

    // console.log("agregar_categoria_a_db(): ", {
    //   operacion: "AGREGAR_CATEGORIA",
    //   idObra: obra.IDObra,
    //   categoria: cat.inputValue,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "AGREGAR_LOCAL",
        idObra: obra.IDObra,
        local: loc.inputValue,
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    // console.log("IDLocal: ", respuesta.result.IDLocal);

    if (respuesta.result.IDLocal) {
      // console.log(respuesta.result.proveedores);
      // setProveedores(respuesta.result.proveedores);
      // setProveedorElegido(respuesta.result.proveedores[0]);
      // console.log(respuesta.result.proveedores);
      // alert("Lista de precios actualizada.");

      let newLocal = respuesta.result.locales[0];

      setListaLocales([...listaLocales, newLocal]);

      setMensaje({
        text: "El local ha sido agregado con éxito.",
        tipo: "success",
      });
      setMostrarMensaje(true);
    } else {
      setMensaje({
        text: "Error al agregar el local.",
        tipo: "error",
      });
      setMostrarMensaje(true);

      console.log("NO TRAE NADA EN agregar_local_a_db()");
      // setState([]);
    }
  };
  return (
    <>
      <AlertValidForms
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <div>
        {/* {console.log("privilegios", privilegios)} */}
        {chequearPermisos(privilegios, "CONTROL_DE_AVANCE_ALTA_LOCAL") ===
        true ? (
          // <BoxContad
          //   imagen="planificacion.png"
          //   cantidad={0}
          //   titulo="Planificacion"
          //   link="planificacion"
          // />
          <>
            <Titulo
              texto="AGREGAR LOCAL"
              botones={{
                seccion: "",
                alta: false,
                baja: false,
                modif: false,
              }}
              estilos={estilos}
            />
            <Typography variant="h6" style={{ fontSize: "16px" }}>
              Complete el formulario.
            </Typography>
            <br /> <br />
            {/* <TablaProject data={ejemplo} /> */}
          </>
        ) : (
          ""
        )}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectComboLocales
            screen={{ xs: 12, sm: 12, md: 10, lg: 8, xl: 4 }}
            setListaLocales={setListaLocales}
            listaLocales={listaLocales}
            setLocalSeleccionado={setLocalSeleccionado}
            setValidLocal={setValidLocal}
            //   setCamposDisponibles={setCamposDisponibles}
            //   camposDisponibles={camposDisponibles}
            // setValueLocal={setValueLocal}
            // valueLocal={valueLocal}
          />
        </Grid>
      </Grid>

      <br />
      <br />
      <br />
      <br />
      <Button onClick={handleClose}>Cancelar</Button>
      <Button onClick={handleSubmit}>Confirmar</Button>
    </>
  );
};

export default PageAltaLocal;
