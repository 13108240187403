import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slider,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { traer_color } from "../../../colores/Colores";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import ServidorContext from "../../../contextos/ServidorContext";
import ObraContext from "../../../contextos/ObraContext";
import MensajesContext from "../../../contextos/MensajesContext";
import UsuarioContext from "../../../contextos/UsuarioContext";
import TomarFoto from "./TomarFoto";
import { useRef } from "react";
import PageDetalleTarea from "./PageDetalleTarea";
import {
  chequearPermisos,
  desencriptar,
  enviarPosicionHaciaReactNative,
} from "../../../funciones/Funciones";
import EstilosContext from "../../../contextos/EstilosContext";
import Webcam from "react-webcam";

import styled from "@mui/system/styled";
import ControlDeAvanceContext from "../../../contextos/ControlDeAvanceContext";
import ModalDetalleTarea from "./ModalDetalleTarea";
import ModalDetalleTareaDeGremio from "./ModalDetalleDeGremio";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

// const WebcamComponent = () => <Webcam />;
// const videoConstraints = {
//   width: 400,
//   height: 400,
//   //   facingMode: "user",

//   //   width: { min: 480 },
//   //   height: { min: 720 },
//   aspectRatio: 0.6666666667,
//   facingMode: "user", // CAMARA FRONTAL
//   //   facingMode: { exact: "environment" },  // CAMARA TRASERA
// };

const Item = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#444d58" : "#ced7e0",
  padding: theme.spacing(1),
  borderRadius: "4px",
  textAlign: "center",
}));

const TareaDeGremio = ({ gremio, tarea }) => {
  const webcamRef = useRef(null);

  const { screenWidth, isMobile } = useContext(EstilosContext);
  const { servidor } = useContext(ServidorContext);
  const { usuario } = useContext(UsuarioContext);
  const { obra } = useContext(ObraContext);
  const { tareasAsignadas, setTareasAsignadas } = useContext(
    ControlDeAvanceContext
  );

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const colorPorcentaje = traer_color("blueGrey", 200);
  const [bgColorPorcentaje, setBgColorPorcentaje] = useState(colorPorcentaje);

  const colorTarea = traer_color("grey", 50);
  const [bgColorTarea, setBgColorTarea] = useState(colorTarea);

  const colorTareaChecked = traer_color("blue", 500);
  const [bgColorTareaChecked, setBgColorTareaChecked] =
    useState(colorTareaChecked);

  const [mostrarCamara, setMostrarCamara] = useState(false);
  const [mirrored, setMirrored] = useState(false);

  const [avanceDO, setAvanceDO] = useState("");
  const [avanceGremio, setAvanceGremio] = useState("");

  const [listaDeFotos, setListaDeFotos] = useState([]);

  const [isChecked, setIsChecked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const [contadorIntersticial, setContadorIntersticial] = useState(0);
  const [picture, setPicture] = useState("");
  const [mostrarImagen, setMostrarImagen] = useState("");
  const [fotoTomada, setFotoTomada] = useState("");

  const [tareaSeleccionada, setTareaSeleccionada] = useState("");

  const [guardarFoto, setGuardarFoto] = useState(false);

  // const marks = [
  //   {
  //     value: 0,
  //     label: "0%",
  //   },
  //   {
  //     value: 50,
  //     label: "50%",
  //   },
  //   {
  //     value: 100,
  //     label: "100%",
  //   },
  // ];

  // useEffect(() => {
  //   if (fotoTomada !== "" && fotoTomada !== null) {
  //     alert("|" + tarea.IDAsignacion + "|");

  //     guardar_foto();
  //   }
  // }, [fotoTomada]);

  // useEffect(() => {
  //   // console.log("mostrar: ", mostrarCamara);

  //   // if ((!isMobile && webcamRef.current) || isMobile) {

  //   if (mostrarCamara) {
  //     capture();
  //   }

  //   // }
  // }, [mostrarCamara]);

  // useEffect(() => {
  //   // console.log("usuario: ", usuario);
  //   // console.log("obra: ", obra);

  //   // console.log("tarea: ", tarea);

  //   traer_lista_de_fotos();
  // }, []);

  // const traer_lista_de_fotos = async () => {
  //   const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

  //   console.log({
  //     operacion: "TRAER_FOTOS_DE_TAREA",
  //     idAsignacion: tarea.IDAsignacion,
  //   });

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       operacion: "TRAER_FOTOS_DE_TAREA",
  //       idAsignacion: tarea.IDAsignacion,
  //       // idObra: obra.IDObra,

  //       // productos: "VOLQUETES",
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log("fotos: ", respuesta.result.listaFotos);

  //   if (respuesta.result.listaFotos) {
  //     setListaDeFotos(respuesta.result.listaFotos);
  //     // console.log("listaDeFotos: ", respuesta.result.listaDeFotos);
  //   } else {
  //     console.log("NO TRAE NADA EN traer_lista_de_fotos()");
  //     // setState([]);
  //   }
  // };

  // function valuetext(value) {
  //   let obr = localStorage.getItem("objobra");
  //   // console.log("obra context: ", obra);
  //   // console.log("obra localstorage: ", desencriptar(obr));

  //   if (obra.EsAdmin === "1") {
  //     // console.log("DO");
  //     setAvanceDO(value);
  //   } else {
  //     // console.log("GREMIO");
  //     setAvanceGremio(value);
  //   }

  //   // console.log("value: ", value);

  //   return `${value} ${tarea.Unidad}`;
  // }

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
    // console.log("touchStart: ", e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
    // console.log("touchEnd: ", e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchEnd - touchStart > 100) {
      // Aquí se detectó un swipe right
      // Puedes abrir el popup
      alert("PASA");
    }
  };

  const handleClick = () => {
    // alert("PASA");

    // console.log("OBRA: ", obra.Privilegios);

    setTareaSeleccionada(tarea);

    setIsClicked(true);

    setTimeout(() => {
      setIsClicked(false);
    }, 200);

    setOpenDialog(true);
  };

  // const handleSubmitDialog = (event) => {
  //   event.preventDefault();

  //   if (
  //     chequearPermisos(
  //       obra.Privilegios,
  //       "CONTROL_DE_AVANCE_ROL_ADMINISTRADOR"
  //     ) === true
  //   ) {
  //     guardar_avance();
  //   } else {
  //     setMensaje({ text: "No tiene permisos!", tipo: "error" });
  //     setMostrarMensaje(true);
  //   }

  //   // if (dialogValue.Grupo !== "") {
  //   //   if (
  //   //     chipData.filter((chip) => chip.Grupo === dialogValue.Grupo).length > 0
  //   //   ) {
  //   //     setMensaje({ text: "El grupo ya existe.", tipo: "error" });

  //   //     setMostrarMensaje(true);
  //   //   } else {
  //   //     guardar_grupo();

  //   //     setValue({
  //   //       IDGrupo: parseInt(dialogValue.IDGrupo, 10),
  //   //       Grupo: dialogValue.Grupo,
  //   //     });
  //   //     handleClose();
  //   //   }
  //   // } else {
  //   //   setMensaje({ text: "El grupo no puede ser vacío.", tipo: "error" });

  //   //   setMostrarMensaje(true);
  //   // }
  // };

  // const handleCloseDialog = () => {
  //   // setDialogValue({
  //   //   IDGrupo: "",
  //   //   Grupo: "",
  //   // })0;
  //   setOpenDialog(false);
  // };

  // const guardar_avance = async () => {
  //   const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

  //   // console.log("idAsignacion: ", tarea.IDAsignacion);
  //   // console.log("AvanceDO: ", avanceDO);
  //   // console.log("usuario: ", usuario);

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       operacion: "GUARDAR_AVANCE_DE_TAREA",
  //       idAsignacion: tarea.IDAsignacion,
  //       avanceDO: avanceDO,
  //       avanceGremio: avanceGremio,
  //       idUsuario: usuario.IDUsuario,
  //       esAdmin: obra.EsAdmin,

  //       // productos: "VOLQUETES",
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log("respuesta: ", respuesta);

  //   if (respuesta.result.resultado === "OK") {
  //     // console.log("result: ", respuesta.result.palabrasClaves[0]);

  //     // setTareasAsignadas
  //     // console.log("tarea: ", tarea);

  //     // Creamos una copia del estado actual
  //     const nuevasTareasAsignadas = [...tareasAsignadas];

  //     // Encuentra el grupo con IDGrupo = 6
  //     let gru = nuevasTareasAsignadas.find(
  //       (gru) => gru.IDGrupo === grupo.IDGrupo
  //     );

  //     if (gru) {
  //       // Encuentra la tarea con IDTarea = 7229
  //       let task = gru.Tareas.find((tar) => tar.IDTarea === tarea.IDTarea);

  //       if (task) {
  //         // Actualiza AvanceDO
  //         if (obra.EsAdmin === "1") {
  //           task.AvanceDO = avanceDO;
  //           task.AvanceGremio = avanceDO;
  //         } else {
  //           task.AvanceGremio = avanceGremio;
  //         }
  //       }
  //     }

  //     // console.log("nuevas: ", nuevasTareasAsignadas);
  //     setTareasAsignadas(nuevasTareasAsignadas);

  //     setMensaje({ text: "Avance guardado con éxito!", tipo: "success" });

  //     setMostrarMensaje(true);
  //     setOpenDialog(false);
  //   } else {
  //     console.log("NO TRAE NADA EN guardar_avance()");
  //     // setState([]);
  //   }
  // };

  const handleTomarFoto = (imagen) => {
    // console.log(imagen);
    // Aquí puedes manejar la imagen tomada
  };

  const capture = React.useCallback(async () => {
    // let sum = localStorage.getItem("contIntersticial");

    // if (sum % 10 === 0) {
    //   // Muestra el banner bonificado cada 10 fotos.
    //   sum = 1;

    //   enviarPosicionHaciaReactNative(isMobile, "banner", {
    //     usuario: usuario,
    //     obra: obra,
    //     tipoDeBanner: "BONIFICADO",
    //     idPublicidad: "ca-app-pub-9747826938175612/9299862830",
    //     idBanner: "ca-app-pub-9747826938175612/8150779805",
    //     sacarFoto: true,
    //   });
    // } else {
    //   sum++;

    //   if (sum % 5 === 0) {
    //     // Muestra el banner intersticial cada 5 fotos.
    //     enviarPosicionHaciaReactNative(isMobile, "banner", {
    //       usuario: usuario,
    //       obra: obra,
    //       tipoDeBanner: "INTERSTICIAL",
    //       idPublicidad: "ca-app-pub-9747826938175612/9108291141",
    //       idBanner: "ca-app-pub-9747826938175612/8150779805",
    //       sacarFoto: true,
    //     });
    //   }
    // }

    // localStorage.setItem("contIntersticial", sum);

    // setContadorIntersticial(sum);

    if (isMobile && window.ReactNativeWebView) {
      // window.ReactNativeWebView.postMessage(
      //   JSON.stringify({
      //     accion: "takePhoto",
      //     contenido: {},
      //   })
      // );
      enviarPosicionHaciaReactNative(isMobile, "takePhoto", {});
    } else {
      // console.log("webcamRef: ", webcamRef);

      // if (webcamRef.current) {
      const pictureSrc = await webcamRef.current.getScreenshot();
      setPicture(pictureSrc);
      setMostrarImagen(pictureSrc);

      setFotoTomada(pictureSrc);
      // console.log("picture: ", pictureSrc);
      // setMostrarCamara(false);

      // } else {
      //   setPicture("");
      //   setMostrarImagen("");

      //   setFotoTomada("");
      // }

      // console.log("pictureSrc: ", pictureSrc);
    }

    // }

    // console.log("pictureSrc", pictureSrc);
  }, [webcamRef]);

  // window.addEventListener("message", function (event) {
  //   // if (processed) {
  //   //   return;
  //   // }
  //   // processed = true;

  //   // window.ReactNativeWebView.postMessage("FUERA: " + event);

  //   // if (event.data.type === "myEventType") {
  //   //   window.ReactNativeWebView.postMessage("myEventType: " + event.data.data);
  //   //   alert("MSG: " + event.data.data);
  //   // }

  //   // alert("mensaje: ", event.data);

  //   if (event.data.type === "photo") {
  //     // window.ReactNativeWebView.postMessage("photo: " + event.data.data);

  //     // const img = document.createElement("img");
  //     // img.src = `data:image/jpeg;base64,${event.data.data}`;

  //     // document.body.appendChild(img);

  //     const pictureSrc = `data:image/jpeg;base64,${event.data.data}`;
  //     // alert("pictureSrc: ", pictureSrc);
  //     setPicture(pictureSrc);
  //     setMostrarImagen(pictureSrc);

  //     // console.log("pictureSrc: ", pictureSrc);

  //     setFotoTomada(pictureSrc);

  //     enviarPosicionHaciaReactNative(isMobile, "banner", {
  //       usuario: usuario,
  //       obra: obra,
  //       tipoDeBanner: "BANNER",
  //       idPublicidad: "ca-app-pub-9747826938175612/8150779805",
  //       idBanner: "ca-app-pub-9747826938175612/8150779805",
  //       sacarFoto: false,
  //     });

  //     setMostrarCamara(false);
  //   }
  // });

  // const handleEliminarImagen = async (idFotoDeTarea) => {
  //   const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

  //   // console.log("fotoTomada: ", fotoTomada);
  //   // console.log("idAsignacion: ", tarea.IDAsignacion);
  //   // // console.log("idUsuario: ", usuario.IDUsuario);
  //   // console.log("idFotoDeTarea: ", idFotoDeTarea);

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       operacion: "ELIMINAR_FOTO_DE_TAREA",
  //       idFotoDeTarea: idFotoDeTarea,
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log("respuesta: ", respuesta);

  //   if (respuesta.result.resultado === "OK") {
  //     // console.log("result: ", respuesta.result.palabrasClaves[0]);

  //     // setListaDeFotos([
  //     //   listaDeFotos.filter((imagen) => imagen.IDFotoDeTarea !== idFotoDeTarea),
  //     // ]);

  //     let filtro = listaDeFotos.filter(
  //       (imagen) => imagen.IDFotoDeTarea !== idFotoDeTarea
  //     );

  //     setListaDeFotos(filtro);
  //     // console.log("filtro: ", filtro);

  //     setMensaje({
  //       text: "La foto ha sido eliminada con éxito!",
  //       tipo: "error",
  //     });

  //     setMostrarMensaje(true);
  //     // setOpenDialog(false);
  //     setFotoTomada("");
  //     setMostrarCamara(false);
  //   } else {
  //     console.log("NO TRAE NADA EN handleEliminarImagen()");
  //     // setState([]);
  //   }
  // };

  // useEffect(() => {
  //   if (fotoTomada !== "" && fotoTomada !== null) {
  //     alert("|" + tarea.IDAsignacion + "|");

  //     guardar_foto();
  //   }
  // }, [fotoTomada]);

  // const guardar_foto = async () => {
  //   const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

  //   // console.log("fotoTomada: ", fotoTomada);
  //   // console.log("idAsignacion: ", tarea.IDAsignacion);
  //   // console.log("idUsuario: ", usuario.IDUsuario);

  //   console.log({
  //     operacion: "GUARDAR_FOTO_DE_AVANCE",
  //     foto: fotoTomada,
  //     anotaciones: "",
  //     idAsignacion: tarea.IDAsignacion,
  //     idUsuario: usuario.IDUsuario,
  //   });

  //   const datos = await fetch(API_ENDPOINT, {
  //     method: "POST",
  //     // headers: {
  //     //   Accept: "application/json",
  //     //   "Content-Type": "application/json",
  //     // },
  //     body: JSON.stringify({
  //       operacion: "GUARDAR_FOTO_DE_AVANCE",
  //       foto: fotoTomada,
  //       anotaciones: "",
  //       idAsignacion: tarea.IDAsignacion,
  //       idUsuario: usuario.IDUsuario,
  //     }),
  //   });
  //   const respuesta = await datos.json();

  //   // console.log("respuesta: ", respuesta);

  //   if (respuesta.result.resultado === "OK") {
  //     // console.log("result: ", respuesta.result.palabrasClaves[0]);

  //     setListaDeFotos([
  //       {
  //         IDFotoDeTarea: respuesta.result.idFoto,
  //         Foto: fotoTomada,
  //         Anotaciones: "",
  //         IDAsignacion: tarea.IDAsignacion,
  //         IDUsuario: usuario.IDUsuario,
  //         Fecha: respuesta.result.fecha,
  //         Publicar: 1,
  //       },
  //       ...listaDeFotos,
  //     ]);

  //     // console.log("listaDeFotos 2: ", listaDeFotos);

  //     setMensaje({ text: "Foto guardada con éxito!", tipo: "success" });

  //     setMostrarMensaje(true);
  //     // setOpenDialog(false);
  //     setFotoTomada("");
  //     setMostrarCamara(false);
  //   } else {
  //     console.log("NO TRAE NADA EN guardar_foto()");
  //     // setState([]);
  //   }
  // };

  return (
    // <div style={{ border: "1px solid #CCCCCC" }}>{tarea.Tarea} </div>
    <>
      <Typography style={{ flexGrow: 1 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexGrow: 1,
            //   border: "1px solid #FF0000",
          }}
        >
          <div
            style={{
              backgroundColor: bgColorPorcentaje,
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "5px",
              paddingRight: "5px",
              width: "60px",
              height: "80px",
              fontSize: "20px",
              // color: "#FFFFFF",
              color: "#000000",
              borderBottom: "1px solid #000000",
              // left: touchEnd,
            }}
          >
            {Math.round(tarea.AvanceDO)}%
          </div>

          <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onClick={handleClick}
            style={{
              backgroundColor:
                isChecked || isClicked ? bgColorTareaChecked : bgColorTarea,
              color: isChecked ? "#FFFFFF" : "#000000",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "5px",
              width: "100%",
              height: "80px",
              fontSize: "20px",
              flexGrow: 1,
              borderBottom: "1px solid #000000",
              alignItems: "center",
              // color: "#FFFFFF",
            }}
          >
            {tarea.Tarea}
            <div style={{ float: "right" }}>
              <Checkbox
                {...label}
                icon={<RadioButtonUncheckedIcon sx={{ fontSize: 40 }} />}
                checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                checked={isChecked}
                onChange={handleOnChange}
              />
            </div>
          </div>
        </div>
      </Typography>

      {/* {openDialog ? (
        <PageDetalleTarea
          setTareasAsignadas={setTareasAsignadas}
          tarea={tarea}
          setOpenDialog={setOpenDialog}
          openDialog={openDialog}
        />
      ) : (
        ""
      )} */}

      <div
        style={{
          width: "100%",
        }}
      >
        <ModalDetalleTareaDeGremio
          gremio={gremio}
          tarea={tarea}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
      </div>
    </>
  );
};

export default TareaDeGremio;
