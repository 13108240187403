import React, { useContext } from "react";
import TituloGrupos from "./TituloGrupos";
import Tarea from "./Tarea";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import ControlDeAvanceContext from "../../../contextos/ControlDeAvanceContext";
import TituloGremios from "./TituloGremios";
import TareaDeGremio from "./TareaDeGremio";

const styles = {
  slide: {
    padding: 15,
    minHeight: 100,
    color: "#fff",
  },
  slide1: {
    backgroundColor: "#FEA900",
  },
  slide2: {
    backgroundColor: "#B3DC4A",
  },
  slide3: {
    backgroundColor: "#6AC0FF",
  },
};

const ListadoDeTareasPorGremio = ({ setGremios, gremios }) => {
  const {
    tareasFiltradas,
    tareasAsignadas,
    setTareasAsignadas,
    tareasFiltradasCoincidentes,
  } = useContext(ControlDeAvanceContext);

  // console.log("tareasFiltradassss: ", tareasFiltradas);

  // console.log("tareasFiltradasCoincidentes: ", tareasFiltradasCoincidentes);

  // console.log("tareasFiltradasCoincidentes: ", tareasFiltradasCoincidentes);
  return (
    // Luego, usamos este nuevo array en nuestro renderizado
    // tareasFiltradasCoincidentes &&
    // Array.isArray(tareasFiltradasCoincidentes) &&
    tareasFiltradasCoincidentes.length > 0 &&
    tareasFiltradasCoincidentes.map((gremio, index) => (
      <>
        <TituloGremios
          gremio={{ IDGremio: gremio.IDGremio, Gremio: gremio.Gremio }}
        />
        {Array.isArray(gremio.Tareas) &&
          gremio.Tareas.map((tarea) => (
            <TareaDeGremio
              gremio={gremio}
              tarea={tarea}
              style={Object.assign({})}
            />
          ))}
        <br />
      </>
    ))
  );
};

export default ListadoDeTareasPorGremio;
