import React, { useContext, useState } from "react";
import { enviarPosicionHaciaReactNative } from "../../../funciones/Funciones";
import EstilosContext from "../../../contextos/EstilosContext";
import PrivilegioContext from "../../../contextos/PrivilegiosContext";
import UsuarioContext from "../../../contextos/UsuarioContext";
import ObraContext from "../../../contextos/ObraContext";
import { useRef } from "react";
import Webcam from "react-webcam";

const TomarFoto = ({}) => {
  const { screenWidth, isMobile } = useContext(EstilosContext);
  const { privilegios, setPrivilegios } = useContext(PrivilegioContext);
  const { usuario } = useContext(UsuarioContext);
  const { obra } = useContext(ObraContext);

  const [contadorIntersticial, setContadorIntersticial] = useState(0);
  const [picture, setPicture] = useState("");
  const [mostrarImagen, setMostrarImagen] = useState("");
  const [mirrored, setMirrored] = useState(false);

  const webcamRef = useRef(null);

  const videoConstraints = {
    width: 400,
    height: 400,
    //   facingMode: "user",

    //   width: { min: 480 },
    //   height: { min: 720 },
    aspectRatio: 0.6666666667,
    facingMode: "user", // CAMARA FRONTAL
    //   facingMode: { exact: "environment" },  // CAMARA TRASERA
  };

  const capture = React.useCallback(async () => {
    // alert(screenWidth);

    // let sum = localStorage.getItem("contIntersticial");
    // // alert("sum: " + sum + "\nCondicion: " + (sum % 3));

    // if (sum % 3 === 0) {
    //   sum = 1;

    //   enviarPosicionHaciaReactNative(isMobile, "banner", {
    //     usuario: usuario,
    //     obra: obra,
    //     tipoDeBanner: "BONIFICADO",
    //     idPublicidad: "ca-app-pub-9747826938175612/9380320165",
    //     idBanner: "ca-app-pub-9747826938175612/8150779805",
    //     sacarFoto: true,
    //   });
    // } else {
    //   sum++;

    //   enviarPosicionHaciaReactNative(isMobile, "banner", {
    //     usuario: usuario,
    //     obra: obra,
    //     tipoDeBanner: "INTERSTICIAL",
    //     idPublicidad: "ca-app-pub-9747826938175612/5432206994",
    //     idBanner: "ca-app-pub-9747826938175612/8150779805",
    //     sacarFoto: true,
    //   });
    // }

    // localStorage.setItem("contIntersticial", sum);

    // setContadorIntersticial(sum);

    if (isMobile && window.ReactNativeWebView) {
      // window.ReactNativeWebView.postMessage(
      //   JSON.stringify({
      //     accion: "takePhoto",
      //     contenido: {},
      //   })
      // );
      // enviarPosicionHaciaReactNative(isMobile, "takePhoto", {});
    } else {
      const pictureSrc = await webcamRef.current.getScreenshot();
      setPicture(pictureSrc);
      setMostrarImagen(pictureSrc);
    }

    // console.log("pictureSrc", pictureSrc);
  }, [webcamRef]);

  window.addEventListener("message", function (event) {
    // if (processed) {
    //   return;
    // }
    // processed = true;

    // window.ReactNativeWebView.postMessage("FUERA: " + event);

    // if (event.data.type === "myEventType") {
    //   window.ReactNativeWebView.postMessage("myEventType: " + event.data.data);
    //   alert("MSG: " + event.data.data);
    // }

    // alert("mensaje: ", event.data);

    if (event.data.type === "photo") {
      // window.ReactNativeWebView.postMessage("photo: " + event.data.data);

      // const img = document.createElement("img");
      // img.src = `data:image/jpeg;base64,${event.data.data}`;

      // document.body.appendChild(img);

      const pictureSrc = `data:image/jpeg;base64,${event.data.data}`;
      // alert("pictureSrc: ", pictureSrc);
      setPicture(pictureSrc);
      setMostrarImagen(pictureSrc);

      enviarPosicionHaciaReactNative(isMobile, "banner", {
        usuario: usuario,
        obra: obra,
        tipoDeBanner: "BANNER",
        idPublicidad: "ca-app-pub-9747826938175612/8150779805",
        idBanner: "ca-app-pub-9747826938175612/8150779805",
        sacarFoto: false,
      });
    }
  });

  return (
    <div>
      <Webcam
        //   width="99%"
        height={400}
        ref={webcamRef}
        videoConstraints={videoConstraints}
        mirrored={mirrored}
        screenshotFormat="image/jpeg"
        screenshotQuality={0.95}
        imageSmoothing={true}
        // audio={true}
        video="true"
        //   sx={{
        //     alignSelf: "flex-start",
        //     justifyContent: "flex-start",
        //     display: "flex",
        //     alignItems: "flex-start",
        //     float: "left",
        //     marginLeft: "0px",
        //   }}
      />
    </div>
  );
};

export default TomarFoto;
