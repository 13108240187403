import { Grid, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import ServidorContext from "../../../contextos/ServidorContext";
import EstilosContext from "../../../contextos/EstilosContext";
import ObraContext from "../../../contextos/ObraContext";
import MensajesContext from "../../../contextos/MensajesContext";

const filter = createFilterOptions();

const SelectComboLocales = ({
  screen,
  setListaLocales,
  listaLocales,
  setLocalSeleccionado,
  setValidLocal,
}) => {
  const { servidor } = useContext(ServidorContext);
  const { screenWidth } = useContext(EstilosContext);
  const { obra } = useContext(ObraContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [locales, setLocales] = useState([]);

  // *********** AUTOCOMPLETE ******************

  const [dialogValue, setDialogValue] = React.useState({
    IDLocal: "",
    Local: "",
  });

  const [valueLocal, setValueLocal] = useState("");
  // ********** FIN AUTOCOMPLETE ***************

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  //   const traer_obras_del_usuario = async () => {
  //     // console.log(texto);
  //     // console.log("PASA POR listar_obras()");

  //     let objusu = desencriptar(localStorage.getItem("objusu"));

  //     const API_ENDPOINT = servidor + `api_obras.php`;

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       // mode: "no-cors",
  //       // headers: {
  //       //   Accept: "application/json",
  //       //   "Content-Type": "application/json",
  //       // },
  //       body: JSON.stringify({
  //         operacion: "LISTAR_OBRAS",
  //         idUsuario: objusu.IDUsuario,
  //         // operacion: "LISTAR_PRESUPUESTO",
  //         //  idObra: obra.IDObra,
  //         // lista:
  //         //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
  //       }),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log(respuesta);

  //     if (respuesta.result.obras) {
  //       // console.log(respuesta.result.obras);

  //       setListadoObras(respuesta.result.obras);
  //     } else {
  //       setListadoObras([]);
  //       console.log("NO TRAE NADA EN listar_obras()");
  //       // setState([]);
  //     }
  //   };

  //   const handleChange = (_, obr) => {
  //     // console.log(obr);

  //     setObrasSeleccionadas([...obrasSeleccionadas, obr.IDObra]);
  //   };

  //   const handleSubmit = (event) => {
  //     event.preventDefault();

  //     // agregar_categoria_a_db();

  //     setValueGremios({
  //       IDCategoria: parseInt(dialogValue.IDCategoria, 10),
  //       CodCategoria: dialogValue.CodCategoria,
  //       Categoria: dialogValue.Categoria,
  //     });
  //     handleClose();
  //   };

  //   const handleClose = () => {
  //     setDialogValue({
  //       IDCategoria: "",
  //       CodCategoria: "",
  //       Categoria: "",
  //     });
  //     toggleOpen(false);
  //   };

  //   const agregar_categoria_a_db = async () => {
  //     const API_ENDPOINT = servidor + `api_tareas.php`;

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       // headers: {
  //       //   Accept: "application/json",
  //       //   "Content-Type": "application/json",
  //       // },
  //       body: JSON.stringify({
  //         operacion: "AGREGAR_CATEGORIA",
  //         idObraActual: obra.IDObra,
  //         codCategoria: dialogValue.CodCategoria,
  //         categoria: dialogValue.Categoria,
  //         arrObras: obrasSeleccionadas,
  //         // productos: "VOLQUETES",
  //       }),
  //     });
  //     const respuesta = await datos.json();

  //     // console.log(respuesta);

  //     if (respuesta.result.idCategoria) {
  //       // console.log(respuesta.result.proveedores);
  //       // setProveedores(respuesta.result.proveedores);
  //       // setProveedorElegido(respuesta.result.proveedores[0]);
  //       // console.log(respuesta.result.proveedores);
  //       // alert("Lista de precios actualizada.");
  //       setCategoria({
  //         IDCategoria: respuesta.result.idCategoria,
  //         CodCategoria: dialogValue.CodCategoria,
  //         Categoria: dialogValue.Categoria,
  //       });
  //       // props.setActualizarTarea(true);
  //     } else {
  //       console.log("NO TRAE NADA EN agregar_categoria_a_db()");
  //       // setState([]);
  //     }
  //   };

  //   const options = lista.map((option) => {
  //     const categoria = option.Categoria.toUpperCase();
  //     return {
  //       categoria: /[0-9]/.test(categoria) ? "0-9" : categoria,
  //       ...option,
  //     };
  //   });

  //   const GroupHeader = styled("div")(({ theme }) => ({
  //     position: "sticky",
  //     top: "-8px",
  //     padding: "4px 10px",
  //     color: theme.palette.primary.main,
  //     backgroundColor:
  //       theme.palette.mode === "light"
  //         ? lighten(theme.palette.primary.light, 0.85)
  //         : darken(theme.palette.primary.main, 0.8),
  //   }));

  //   const GroupItems = styled("ul")({
  //     padding: 0,
  //   });

  const seleccionarLocal = (loc) => {
    console.log("loc: ", loc);

    // console.log("gremioSelec: ", gre);
    setLocalSeleccionado(loc);

    if (loc !== null) {
      if (loc.IDLocal === undefined) {
        // Agrego la categoría
        // console.log("AGREGO");
        setValidLocal({ text: loc.inputValue, valid: true, error: "" });
      } else {
        // console.log("SELECCIONO");

        setValidLocal({
          text: "",
          valid: false,
          error: "Ya existe un local con ese nombre.",
        });

        // setMensaje({
        //   text: "Ya existe un local con ese nombre.",
        //   error: true,
        // });

        // setMostrarMensaje(true);
      }
    }
  };

  // console.log("screen: ", screen);
  return (
    <div Style="width: 100%;">
      <Grid container sx={{ display: "flex", justifyContent: "left" }}>
        <Grid
          item
          xs={screen.xs}
          sm={screen.sm}
          md={screen.md}
          lg={screen.lg}
          xl={screen.xl}
          sx={{ width: "100%" }}
        >
          <Stack
            spacing={1}

            // xs={9}
            // sm={9}
            // md={9}
            // lg={5}
          >
            {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={categorias}
              getOptionLabel={(option) => option.Categoria}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Categorías" />
              )}
              onChange={(event, valueGremios) => seleccionarCategoria(valueGremios)}
            /> */}

            <Autocomplete
              id="free-solo-dialog-demo"
              options={listaLocales}
              value={valueLocal}
              // inputValue={valueGremios === null ? "" : valueGremios.Tarea}
              clearOnBlur={false}
              selectOnFocus
              handleHomeEndKeys
              freeSolo
              //   disabled={!camposDisponibles.gremio}
              onChange={(event, newValue) => {
                // console.log("newValue: ", newValue);

                if (typeof newValue === "string") {
                  // console.log("PASA POR 1");

                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    // toggleOpen(true);
                    setDialogValue({
                      IDLocal: "",
                      Local: newValue,
                    });
                  });
                } else if (newValue && newValue.inputValue) {
                  // console.log("PASA POR 2: Cuando agrega un gremio.");
                  // toggleOpen(true);
                  setDialogValue({
                    IDLocal: "",
                    Local: newValue.inputValue,
                  });

                  // setCamposDisponibles({
                  //   ...camposDisponibles,
                  //   tarea: true,
                  //   unidad: false,
                  //   cuantificacion: false,
                  // });
                } else {
                  // console.log(
                  //   "PASA POR 3: Cuando selecciona un gremio existente."
                  // );
                  setValueLocal(newValue);

                  if (newValue !== null) {
                    setDialogValue({
                      IDLocal: newValue.IDLocal,
                      Local: newValue.Local,
                    });
                    // setCamposDisponibles({
                    //   ...camposDisponibles,
                    //   tarea: true,
                    //   unidad: false,
                    //   cuantificacion: false,
                    // });
                  } else {
                    setDialogValue({
                      IDLocal: "",
                      Local: "",
                    });
                    // setCamposDisponibles({
                    //   ...camposDisponibles,
                    //   unidad: false,
                    //   cuantificacion: false,
                    // });
                  }
                }

                // setLocalSeleccionado(newValue);

                seleccionarLocal(newValue);

                // alert(JSON.stringify(newValue));

                // setValidForm({ ...validForm, tareaValid: true });
                // setCamposDisponibles({ ...camposDisponibles, unidad: true });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // console.log("options: ", options);
                // console.log("params: ", params);

                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    Local: `Agregar "${params.inputValue}"`,
                  });
                }
                // console.log("filtered: ", filtered);
                return filtered;
              }}
              getOptionLabel={(option) => {
                // console.log("option: ", option);
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  // console.log("PASA POR A");
                  return option;
                }
                if (option && option.inputValue) {
                  // console.log("PASA POR B");
                  return option.inputValue;
                }
                if (option && option.Gremio) {
                  // console.log("PASA POR C");

                  return option.Gremio;
                }
                return "";
                // if (typeof option === "string") {
                //   return option;
                // }
                // if (option.inputValue) {
                //   return option.inputValue;
                // }
                // console.log("option.inputValue: ", option.inputValue);
                // return option.Tarea;
              }}
              // clearOnBlur

              renderOption={(props, option) => (
                <li {...props} key={option.IDLocal}>
                  {option.Local}
                </li>
              )}
              // sx={{ width: 300 }}

              renderInput={(params) => (
                <TextField {...params} label="Local" variant="standard" />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectComboLocales;
