import React, { useContext, useEffect, useState } from "react";
import { Box, Button, TextField, Paper, Grid, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SendIcon from "@mui/icons-material/Send";

import MensajesContext from "../../contextos/MensajesContext";
import AlertValidConfig from "../../components/AlertValidConfig";
import EncabezadoItems from "../../components/EncabezadoItems";
import ObraContext from "../../contextos/ObraContext";
import ServidorContext from "../../contextos/ServidorContext";
import SeleccionDeColor from "./SeleccionDeColor";

import MultipleSelectChip from "../pedidos/MultipleSelectChip";
import Rubros from "../pedidos/Rubros";
import { BorderColor } from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const PageQuieroSerProveedor = (props) => {
  const [proveedor, setProveedor] = useState({
    text: "",
    valid: false,
    error: "",
  });
  const [razonSocial, setRazonSocial] = useState({
    text: "",
    valid: false,
    error: "",
  });
  const [cuit, setCuit] = useState({
    text: "",
    valid: false,
    error: "",
  });
  const [contacto, setContacto] = useState({
    text: "",
    valid: false,
    error: "",
  });
  const [whatsapp, setWhatsapp] = useState({
    text: "",
    valid: false,
    error: "",
  });
  const [email, setEmail] = useState({ text: "", valid: false, error: "" });
  const [clave, setClave] = useState({ text: "", valid: false, error: "" });
  const [direccion, setDireccion] = useState({
    text: "",
    valid: false,
    error: "",
  });

  const [chipsSeleccionados, setChipsSeleccionados] = useState([]);

  const [rubros, setRubros] = useState([]);

  // const [rubros, setRubros] = useState([]);
  const [rubrosSeleccionados, setRubrosSeleccionados] = useState({
    text: "",
    valid: false,
    error: "",
  });
  const [proveedorColor, setProveedorColor] = useState({
    text: "",
    valid: false,
    error: "",
  });
  const [proveedorColorNumero, setProveedorColorNumero] = useState({
    text: "",
    valid: false,
    error: "",
  });

  const { servidor } = useContext(ServidorContext);
  const { obra, setObra } = useContext(ObraContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  let navigate = useNavigate();

  const styles = {
    container: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    error: {
      color: "#FF0000",
      marginBottom: 10,
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      // borderWidth: 1,
      // borderColor: '#FFFFFF',
    },
  };

  useEffect(() => {
    props.setTitulo("Recuperar contraseña");
    props.setPagina("");

    // console.log("A");
  }, [props.setTitulo]);

  useEffect(() => {
    traer_rubros();
  }, []);

  useEffect(() => {
    // console.log("rub: ", rubros);

    // console.log("chipsSeleccionados: ", chipsSeleccionados);

    if (chipsSeleccionados.length > 0) {
      let textChipsSeleccionados = chipsSeleccionados;
      let cantChipsSeleccionados = chipsSeleccionados.length;

      if (cantChipsSeleccionados > 5) {
        // console.log("Proveedor muy corto.");
        setRubrosSeleccionados({
          text: textChipsSeleccionados,
          valid: false,
          error: "Seleccione hasta un máximo de 5 rubros.",
        });
      } else {
        setRubrosSeleccionados({
          text: textChipsSeleccionados,
          valid: true,
          error: "",
        });
      }
    } else {
      // console.log("chipsSeleccionados.len: ", chipsSeleccionados.length);
      setRubrosSeleccionados({
        text: "",
        valid: false,
        error: "Seleccione al menos un rubro",
      });
    }

    // if (text.length == 0) {
    //   // Proveedor vacío.
    //   setRubrosSeleccionados({
    //     text: text,
    //     valid: false,
    //     error: "Seleccione al menos un rubro",
    //   });
    // } else if (text.length < 3) {
    //   // console.log("Proveedor muy corto.");
    //   setProveedor({
    //     text: text,
    //     valid: false,
    //     error: "Nombre del proveedor muy corto",
    //   });
    // } else {
    //   // console.log("Proveedor OK.");
    //   setRubrosSeleccionados({
    //     text: text,
    //     valid: true,
    //     error: "",
    //   });
    // }
  }, [chipsSeleccionados]);

  const traer_rubros = async () => {
    const API_ENDPOINT = servidor + `api_proveedores.php`;

    // console.log({
    //   operacion: "LISTAR_RUBROS",
    //   idObra: 0, // obra.IDObra,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_RUBROS",
        idObra: 0, // obra.IDObra,
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.rubros) {
      setRubros(respuesta.result.rubros);
    } else {
      console.log("NO TRAE NADA EN traer_rubros()");
      // setState([]);
    }
  };

  const validarFormulario = () => {
    if (
      proveedor.valid &&
      razonSocial.valid &&
      cuit.valid &&
      email.valid &&
      clave.valid &&
      contacto.valid &&
      whatsapp.valid &&
      rubrosSeleccionados.valid
    ) {
      //   let objregusu = desencriptar(localStorage.getItem("objregusu"));
      guardar_proveedor();
    } else {
      if (!proveedor.valid) {
        setMensaje({
          text: "Ingrese un nombre de proveedor correcto",
          tipo: "error",
        });
      } else if (!razonSocial.valid) {
        setMensaje({
          text: "Ingrese una razón social correcta",
          tipo: "error",
        });
      } else if (!cuit.valid) {
        setMensaje({ text: "Ingrese un CUIT correcto", tipo: "error" });
      } else if (!email.valid) {
        setMensaje({ text: "Ingrese un e-mail correcto", tipo: "error" });
      } else if (!clave.valid) {
        setMensaje({ text: "Ingrese una contraseña correcta", tipo: "error" });
      } else if (!contacto.valid) {
        setMensaje({
          text: "Ingrese un nombre de contacto correcto",
          tipo: "error",
        });
      } else if (!whatsapp.valid) {
        setMensaje({
          text: "Ingrese un número de Whatsapp de contacto correcto",
          tipo: "error",
        });
      } else if (!rubrosSeleccionados.valid) {
        setMensaje({
          text: "Seleccione por lo menos un rubro",
          tipo: "error",
        });

        //   } else if (!proveedorColor.valid) {
        //     setMensaje({ text: proveedorColor.error, tipo: "error" });
      } else {
        setMensaje({ text: "Datos incorrectos", tipo: "error" });
      }

      setMostrarMensaje(true);
    }

    // if (email.valid) {
    //   recuperar_clave();
    // } else {
    //   setMensaje({ text: "Datos incorrectos", tipo: "error" });
    //   setMostrarMensaje(true);
    // }
  };

  const guardar_proveedor = async () => {
    // console.log("idObra: ", obra.IDObra);
    // console.log("proveedor: ", proveedor.text);
    // console.log("razonSocial: ", razonSocial.text);
    // console.log("cuit: ", cuit.text);
    // console.log("usuario: ", "");
    // console.log("email: ", email.text);
    // console.log("clave: ", clave.text);
    // console.log("direccion: ", direccion.text);
    // console.log("telefono: ", whatsapp.text);
    // console.log("whatsapp: ", whatsapp.text);
    // console.log("contacto: ", contacto.text);
    // console.log("rubros: ", rubros);
    // console.log("proveedorColor: ", proveedorColor);
    // console.log("proveedorColorNumero: ", proveedorColorNumero);

    if (proveedor.valid && whatsapp.valid) {
      const API_ENDPOINT = servidor + `api_proveedores.php`;

      // console.log(codigoPedido);

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "GUARDAR_PROVEEDOR_EN_QUIERO_SER_PROVEEDOR",
          idObra: obra.IDObra,
          proveedor: proveedor.text,
          razonSocial: razonSocial.text,
          cuit: cuit.text,
          usuario: "",
          email: email.text,
          clave: clave.text,
          direccion: direccion.text,
          telefono: whatsapp.text,
          whatsapp: whatsapp.text,
          contacto: contacto.text,
          rubrosSeleccionados: rubrosSeleccionados.text,
          proveedorColor: proveedorColor,
          proveedorColorNumero: proveedorColorNumero,

          // productos: "VOLQUETES",
        }),
      });
      const respuesta = await datos.json();

      // console.log("respuesta: ", respuesta);

      if (respuesta.result.resultado == "OK") {
        setMensaje({ text: "Proveedor agregado con éxito!", tipo: "success" });

        setMostrarMensaje(true);

        setTimeout(() => {
          props.setMostrar("FORMULARIO_LOGIN");

          navigate("/login");
        }, 3000);
      } else {
        console.log("NO TRAE NADA EN guardar_proveedor()");
        // setState([]);
      }
    }
  };

  const handleProveedor = (e) => {
    let text = e.target.value;

    if (text.length == 0) {
      // Proveedor vacío.
      setProveedor({
        text: text,
        valid: false,
        error: "Complete el nombre del proveedor",
      });
    } else if (text.length < 3) {
      // console.log("Proveedor muy corto.");
      setProveedor({
        text: text,
        valid: false,
        error: "Nombre del proveedor muy corto",
      });
    } else {
      // console.log("Proveedor OK.");
      setProveedor({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const handleRazonSocial = (e) => {
    let text = e.target.value;

    if (text.length == 0) {
      // Razón Social vacío.
      setRazonSocial({
        text: text,
        valid: false,
        error: "Complete la Razón Social",
      });
    } else if (text.length < 2) {
      // console.log("Razon Social muy corto.");
      setRazonSocial({
        text: text,
        valid: false,
        error: "Razón Social muy corta",
      });
    } else {
      // console.log("Razón Social OK.");
      setRazonSocial({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const handleCuit = (e) => {
    let text = e.target.value;

    if (text.length == 0) {
      // Cuit vacío.
      setCuit({
        text: text,
        valid: false,
        error: "Complete el CUIT del proveedor",
      });
    } else if (text.length < 11) {
      // console.log("Cuit muy corto.");
      setCuit({
        text: text,
        valid: false,
        error: "CUIT del proveedor muy corto",
      });
    } else {
      // console.log("Cuit OK.");
      setCuit({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const handleContacto = (e) => {
    let text = e.target.value;

    if (text.length == 0) {
      // Contacto vacío.
      setContacto({
        text: text,
        valid: false,
        error: "Complete el nombre de la persona de contacto",
      });
    } else if (text.length < 3) {
      // console.log("Contacto muy corto.");
      setContacto({
        text: text,
        valid: false,
        error: "Nombre de la persona de contacto muy corto",
      });
    } else {
      // console.log("Contacto OK.");
      setContacto({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const handleEmail = (e) => {
    let text = e.target.value;

    // VALIDACIÓN DE MAIL
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      if (text.length == 0) {
        // Email vacío
        setEmail({
          text: text,
          valid: false,
          error: "Complete el Email.",
        });
      } else {
        setEmail({
          text: text,
          valid: false,
          error: "Email incorrecto",
        });
      }

      return false;
    } else {
      // console.log(text.length);

      // console.log("Email is Correct");
      setEmail({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const handleClave = (e) => {
    let text = e.target.value;

    if (text.length == 0) {
      // Clave vacío.
      setClave({
        text: text,
        valid: false,
        error: "Complete la Contraseña del proveedor",
      });
    } else if (text.length < 7) {
      // console.log("Contraseña muy corta.");
      setClave({
        text: text,
        valid: false,
        error: "Ha ingresado una Contraseña muy corta",
      });
    } else {
      // console.log("Contraseña OK.");
      setClave({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const handleWhatsapp = (e) => {
    let text = e.target.value;

    if (text.length == 0) {
      // Whatsapp vacío.
      setWhatsapp({
        text: text,
        valid: false,
        error: "Complete el número de Whatsapp",
      });
    } else if (text.length < 3) {
      // console.log("Whatsapp muy corto.");
      setWhatsapp({
        text: text,
        valid: false,
        error: "Número de Whatsapp muy corto",
      });
    } else {
      // console.log("Whatsapp OK.");
      setWhatsapp({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const handleRubrosSeleccionados = (e) => {
    let text = e.target.value;

    if (text.length == 0) {
      // Proveedor vacío.
      setRubrosSeleccionados({
        text: text,
        valid: false,
        error: "Seleccione al menos un rubro",
      });
    } else if (text.length > 5) {
      // console.log("Proveedor muy corto.");
      setRubrosSeleccionados({
        text: text,
        valid: false,
        error: "Seleccione hasta un máximo de 5 rubros",
      });
    } else {
      // console.log("Proveedor OK.");
      setRubrosSeleccionados({
        text: text,
        valid: true,
        error: "",
      });
    }
  };

  const validarEmail = (event) => {
    // console.log(event.target.value);

    let text = event.target.value;
    // console.log(text);

    // VALIDACIÓN DE USUARIO
    // if (text.length > 2) {
    //   setEmail({ text: text, valid: true, error: "" });
    // } else {
    //   setEmail({
    //     text: text,
    //     valid: false,
    //     error: "Email o usuario incorrecto",
    //   });
    // }

    // VALIDACIÓN DE MAIL
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      if (text.length == 0) {
        // Email vacío
        setEmail({ text: text, valid: false, error: "" });
      } else {
        setEmail({ text: text, valid: false, error: "Email incorrecto" });
      }

      return false;
    } else {
      // console.log(text.length);

      // console.log("Email is Correct");
      setEmail({ text: text, valid: true, error: "" });
    }
  };

  const recuperar_clave = async () => {
    try {
      const API_ENDPOINT = servidor + `api_usuarios.php`;
      // var base64 = require("base-64");
      // let headers = new Headers();
      // // headers.append('Content-Type', 'text/json');
      // headers.append('Authorization', 'Basic ' + base64.encode(email.text + ":" + clave.text));
      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "RECUPERAR_CLAVE",
          email: email.text,
        }),
        // headers: headers,
        //credentials: 'user:passwd'
      });
      const usuarios = await datos.json();

      if (usuarios.result.respuesta === "USUARIO_OK") {
        setMensaje({
          text: "Te hemos enviado un E-mail para recuperar tu contraseña.",
          tipo: "success",
        });
        setMostrarMensaje(true);

        setTimeout(() => {
          props.setMostrar("FORMULARIO_LOGIN");
          navigate("/");
        }, 3000);
      } else {
        setMensaje({
          text: "El E-mail ingresado no corresponde a un usuario registrado.",
          tipo: "error",
        });
        setMostrarMensaje(true);
      }
      return usuarios.result;
    } catch (error) {
      console.error(error);
    }

    return "ERROR";
  };

  const handleBack = () => {
    props.setMostrar("FORMULARIO_LOGIN");
    navigate("/");
  };

  return (
    <div>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
          m: 1,
          mt: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
          width: { xs: 350, md: 400 },
          height: "auto", // Permite crecimiento automático
          marginX: "auto", // Centra horizontalmente
          // display: "flex",
          // // flexWrap: "wrap",
          // alignContent: "center",
          // justifyContent: "center",
          // p: 1,
          // m: 1,
          // mt: 2,
          // // border: 1,
          // bgcolor: "background.paper",
          // // maxWidth: 380,
          // // width: { xs: 300, md: 400 },
          // height: 700,
          // borderRadius: 1,
        }}
      >
        <Item sx={{ width: { xs: 350, md: 350 } }}>
          {/* <Grid container xs={12}>
            <Grid item xs={1}>
              <IconButton
                color="inherit"
                // onClick={handleAltaPedido}
                aria-label="volver"
                size="large"
              >
                <ArrowBackIcon
                  // fontSize="large"
                  fontSize="inherit"
                  // sx={{ color: "#FFFFFF" }}
                  onClick={() => handleBack()}
                />
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <Box
                component="img"
                sx={{
                  // border: "1px solid #000000",
                  height: 48,
                  width: 226,
                  maxHeight: { xs: 48, md: 72 },
                  maxWidth: { xs: 226, md: 339 },
                  mt: 1,
                }}
                alt="Bimtracker"
                src="bimtracker.png"
              />
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          <div style={{ fontWeight: "bold", fontSize: "18px" }}>
            RECUPERAR CONTRASEÑA
          </div> */}

          <EncabezadoItems
            setMostrar={props.setMostrar}
            titulo="QUIERO SER PROVEEDOR"
            pageBack="FORMULARIO_LOGIN"
          />
          <Box
            component="form"
            sx={{
              width: "100%",
              minHeight: 100, // Tamaño mínimo
              display: "flex",
              flexDirection: "column",
              // gap: 2, // Espaciado entre elementos
              // height: 100,
              // width: "100%",
              // // border: 1,
              // marginTop: 2,
              // justifyContent: "center",
              // alignItems: "center",
            }}
          >
            <TextField
              required
              id="txtProveedor"
              label="Nombre del proveedor"
              defaultValue=""
              variant="standard"
              style={{ width: "80%" }}
              onChange={handleProveedor}
              value={proveedor.text}
            />
            <div style={styles.error}>{proveedor.error}</div>
            <TextField
              required
              id="txtRazonSocial"
              label="Razón Social"
              defaultValue=""
              variant="standard"
              style={{ width: "80%" }}
              onChange={handleRazonSocial}
              value={razonSocial.text}
            />
            <div style={styles.error}>{razonSocial.error}</div>
            <TextField
              required
              id="txtCuit"
              label="CUIT"
              defaultValue=""
              variant="standard"
              style={{ width: "80%" }}
              onChange={handleCuit}
              value={cuit.text}
            />
            <div style={styles.error}>{cuit.error}</div>
            <TextField
              required
              id="txtEmail"
              label="Email"
              defaultValue=""
              variant="standard"
              style={{ width: "80%" }}
              onChange={handleEmail}
              value={email.text}
            />
            <div style={styles.error}>{email.error}</div>
            <TextField
              required
              id="standard-password-input"
              label="Contraseña"
              type="password"
              inputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
              variant="standard"
              style={{ width: "80%" }}
              onChange={handleClave}
              value={clave.text}
            />
            <div style={styles.error}>{clave.error}</div>
            <TextField
              required
              id="txtContacto"
              label="Persona de contacto"
              defaultValue=""
              variant="standard"
              style={{ width: "80%" }}
              onChange={handleContacto}
              value={contacto.text}
            />
            <div style={styles.error}>{contacto.error}</div>
            <TextField
              required
              id="txtWhatsapp"
              label="Whatsapp"
              defaultValue=""
              variant="standard"
              style={{ width: "80%" }}
              onChange={handleWhatsapp}
              value={whatsapp.text}
            />
            <div style={styles.error}>{whatsapp.error}</div>
            <br />

            {/* <div style={{ width: "100%", border: "1px solid #FF0000" }}> */}
            <SeleccionDeColor
              setColor={setProveedorColor}
              color={proveedorColor}
              setColorNumero={setProveedorColorNumero}
              colorNumero={proveedorColorNumero}
            />
            {/* </div> */}
            {/* <Rubros setRubros={setRubros} rubros={rubros} /> */}

            <br />
            <div
              style={{
                width: "100%",
              }}
            >
              <MultipleSelectChip
                setChips={setRubros}
                chips={rubros}
                setChipsSeleccionados={setChipsSeleccionados}
                chipsSeleccionados={chipsSeleccionados}
              />
            </div>
            <div style={styles.error}>{rubrosSeleccionados.error}</div>

            <br />
            <br />
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              onClick={() => validarFormulario()}
              // sx={{ "--IconButton-size": "96px" }}
            >
              Confirmar
            </Button>
          </Box>
        </Item>
      </Box>
    </div>
  );
};

export default PageQuieroSerProveedor;
