import * as React from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const TablaGeneral = ({ data, headerNames, columns }) => {
  // console.log("data", data);

  // Si no se especifican las columnas a mostrar, mostrar todas las columnas
  //   const headers = columns || Object.keys(data[0]);
  const headers = Object.keys(data[0]);

  return (
    // <div style={{ height: 400, width: "100%" }}>
    //   <TableContainer component={Paper}>
    //     <Table
    //       sx={{
    //         minWidth: 650,
    //       }}
    //       aria-label="simple table"
    //     >
    //       <TableHead>
    //         <TableRow>
    //           {/* {data &&
    //             data.length > 0 &&
    //             data.encabezados.map((encabezado) => (
    //               <TableCell align={encabezado.align}>
    //                 {encabezado.titulo}
    //               </TableCell>
    //             ))} */}

    //           {headers.map((header) => (
    //             <TableCell key={header}>{headerNames[header]}</TableCell>
    //           ))}
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {/* {data &&
    //           data.length > 0 &&
    //           data.filas.map((row) => (
    //             <TableRow
    //               key={row.id}
    //               sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    //             >

    //               {row.campo.map((camp) => {
    //                 <TableCell align={camp.align}>{camp.fecha}</TableCell>;
    //               })}
    //             </TableRow>
    //           ))} */}

    //         {data.map((row, index) => (
    //           <TableRow key={index}>
    //             {headers.map((header) => (
    //               <TableCell key={header}>{row[header]}</TableCell>
    //             ))}
    //           </TableRow>
    //         ))}
    //       </TableBody>
    //     </Table>
    //   </TableContainer>
    //   <br /> <br />
    // </div>

    <TableContainer component={Paper} sx={{ width: "100%", padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Lista de Registros
      </Typography>

      <Table
        sx={{
          minWidth: 650,
          "@media (max-width: 600px)": {
            minWidth: "100%",
          },
        }}
      >
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell
                key={header}
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "12px", sm: "14px", md: "16px" },
                  padding: { xs: "6px", sm: "8px", md: "12px" },
                }}
              >
                {headerNames[header]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              {headers.map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    fontSize: { xs: "12px", sm: "14px", md: "16px" },
                    padding: { xs: "6px", sm: "8px", md: "12px" },
                    wordWrap: "break-word",
                  }}
                >
                  {row[header]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TablaGeneral;
