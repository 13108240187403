import {
  Box,
  Button,
  FormControl,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ServidorContext from "../../contextos/ServidorContext";
import { useContext } from "react";
import ObraContext from "../../contextos/ObraContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalDesasignarTarea = ({
  openModalAsign,
  setOpenModalAsign,
  setEstado,
  idAsignacion,
}) => {
  const handleClose = () => {
    setOpenModalAsign(false);
  };

  const { servidor } = useContext(ServidorContext);
  const { obra } = useContext(ObraContext);

  const handleEliminar = async () => {
    const API_ENDPOINT = servidor + `api_presupuestos.php`;

    let formEliminar = "";
    if (obra.AppVersion === "1.0") {
      formEliminar = {
        operacion: "DESASIGNAR_TAREA_BTP",
        idAsignacion: idAsignacion,
      };
    } else {
      formEliminar = {
        operacion: "DESASIGNAR_TAREA_BTP_EN_V2",
        idAsignacionV2: idAsignacion,
      };
    }

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify(formEliminar),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);
    // alert(respuesta.result.guardado);
    if (respuesta.result.eliminado === "BAJA_OK") {
      // console.log(respuesta.result.eliminado);
      // setLista(respuesta.result.listaRecursos);

      setEstado("BAJA_OK");

      // handleClose();
      setOpenModalAsign(false);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN handleEliminar()");
      // setState([]);
    }
  };

  return (
    <Modal
      open={openModalAsign}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}

      <Box component="form" sx={style} noValidate autoComplete="off">
        {/* <TextField
            id="standard-basic"
            label="Standard"
            helperText="hola"
            variant="standard"
          /> */}

        {/* <FormControl sx={{ m: 1, minWidth: 80 }}> */}
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <Typography variant="h5" component="h5">
            ELIMINAR ASIGNACIÓN
          </Typography>

          {/* <InputLabel id="demo-simple-select-autowidth-label">
            Recurso
          </InputLabel> */}
          <div>&nbsp;</div>

          <Typography variant="h6" component="h6">
            Confirma que desea eliminar la asignación y su avance?
          </Typography>

          <div>&nbsp;</div>

          <Grid container spacing={2}>
            <Grid item xs={8}></Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                endIcon={<CheckOutlinedIcon />}
                onClick={handleEliminar}
              >
                Sí
              </Button>
              &nbsp;
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                endIcon={<ClearOutlinedIcon />}
                onClick={handleClose}
              >
                No
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
    </Modal>
  );
};

export default ModalDesasignarTarea;
