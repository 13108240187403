import {
  Autocomplete,
  Button,
  Checkbox,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SelectComboCategoria from "../PagePlano/SelectComboCategoria";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ControlDeAvanceContext from "../../../contextos/ControlDeAvanceContext";
import MensajesContext from "../../../contextos/MensajesContext";
import EstilosContext from "../../../contextos/EstilosContext";
import ServidorContext from "../../../contextos/ServidorContext";
import ObraContext from "../../../contextos/ObraContext";
import UsuarioContext from "../../../contextos/UsuarioContext";
import { chequearPermisos } from "../../../funciones/Funciones";
import PrivilegioContext from "../../../contextos/PrivilegiosContext";
import Titulo from "../../../components/Titulo";
import SelectComboGremios from "./SelectComboGremios";
import SelectComboTareas from "./SelectComboTareas";

const filter = createFilterOptions();

const PageListaTareas = () => {
  let navigate = useNavigate();

  const { estilos, screenWidth } = useContext(EstilosContext);
  const { servidor } = useContext(ServidorContext);
  const { obra } = useContext(ObraContext);
  const { usuario } = useContext(UsuarioContext);
  const { privilegios, setPrivilegios, mostrarAlert, setMostrarAlert } =
    useContext(PrivilegioContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  //   const {
  //     controlDeAvance,
  //     setControlDeAvance,
  //     tareasAsignadas,
  //     setTareasAsignadas,
  //     tareasFiltradas,
  //     setTareasFiltradas,
  //   } = useContext(ControlDeAvanceContext);

  const [valueCategoria, setValueCategoria] = useState(null);
  const [valueTarea, setValueTarea] = useState(null);
  const [valueGremios, setValueGremios] = useState(null);
  const [tarea, setTarea] = useState("");
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("");
  const [tareaSeleccionada, setTareaSeleccionada] = useState("");
  const [gremioSeleccionado, setGremioSeleccionado] = useState("");
  const [listaPorCategoria, setListaPorCategoria] = useState([]);
  const [listaTareas, setListaTareas] = useState([]);
  const [mostrarProgress, setMostrarProgress] = useState(false);

  const initCamposDisponibles = {
    tarea: false,
    unidad: false,
    gremio: false,
    capataz: false,
  };
  const [camposDisponibles, setCamposDisponibles] = useState(
    initCamposDisponibles
  );

  //   const [asignarTarea, setAsignarTarea] = useState(false);

  const initValid = {
    categoriaValid: false,
    tareaValid: false,
    unidadValid: false,
    gremioValid: false,
    capatazValid: false,
  };
  const [validForm, setValidForm] = useState(initValid);

  const initFormValues = {
    IDCategoria: "",
    CodCategoria: "",
    Categoria: "",
    IDTarea: "",
    CodTarea: "",
    Tarea: "",
    Unidad: "",
    IDGremio: "",
    Gremio: "",
    Capataz: "",
  };
  const [formValues, setFormValues] = useState(initFormValues);

  //   useEffect(() => {
  //     if (asignarTarea) {
  //       asignar_tarea();
  //       setAsignarTarea(false);
  //     }
  //   }, [tarea]);

  useEffect(() => {
    setValueCategoria({ IDTarea: "", Tarea: "", IDGremio: "", Gremio: "" }); // Esto debería resetear el valor del Autocompletado de tareas.
    // setFormValues({ ...formValues, Unidad: "", Cuantificacion: "" });

    // console.log("PASA POR 1");
    if (
      categoriaSeleccionada !== "" &&
      categoriaSeleccionada !== undefined &&
      categoriaSeleccionada !== null
    ) {
      //   console.log("PASA POR 2");

      validForm.categoriaValid = true;

      setFormValues({
        ...formValues,
        IDCategoria: categoriaSeleccionada.IDCategoria,
        CodCategoria: categoriaSeleccionada.CodCategoria,
        Categoria: categoriaSeleccionada.Categoria,
        //   IDTarea: "",
        //   CodTarea: "",
        //   Tarea: "",
        //   Unidad: "",
        //   IDGremio: "",
        //   Gremio: "",
        //   Capataz: "",
      });

      listar_tareas();
      setCamposDisponibles({ ...camposDisponibles, tarea: true });
    } else {
      //   console.log("PASA POR 3");

      validForm.categoriaValid = false;

      setCamposDisponibles({
        ...camposDisponibles,
        tarea: false,
        unidad: false,
        gremio: false,
        capataz: false,
      });
    }

    // console.log("CAT: ", categoriaSeleccionada);
  }, [categoriaSeleccionada]);

  useEffect(() => {
    if (categoriaSeleccionada) {
      let tasks = listaTareas;

      let tareasFiltradasPorCategoria = tasks.filter(
        (tar) => tar.CodCategoria === categoriaSeleccionada.CodCategoria
      );

      console.log("tareasFiltradasPorCategoria: ", tareasFiltradasPorCategoria);
      // console.log("PASA");
      setListaPorCategoria(tareasFiltradasPorCategoria);
    } else {
      setListaPorCategoria([]);
    }
  }, [listaTareas]);

  useEffect(() => {
    // setValueTarea({ IDTarea: "", Tarea: "" }); // Esto debería resetear el valor del Autocompletado de tareas.
    // // setFormValues({ ...formValues, Unidad: "", Cuantificacion: "" });

    console.log("tareaSeleccionada: ", tareaSeleccionada);

    // setFormValues({ ...formValues, IDGremio: "", Gremio: "", Capataz: "" });

    if (
      tareaSeleccionada !== "" &&
      tareaSeleccionada !== undefined &&
      tareaSeleccionada !== null
    ) {
      validForm.tareaValid = true;

      setCamposDisponibles({
        ...camposDisponibles,
        unidad: true,
        gremio: true,
        capataz: false,
      });
    } else {
      validForm.tareaValid = false;

      setCamposDisponibles({
        ...camposDisponibles,
        unidad: false,
        gremio: false,
        capataz: false,
      });
    }

    // console.log("CAT: ", categoriaSeleccionada);
  }, [tareaSeleccionada]);

  useEffect(() => {
    // setValueTarea({ IDTarea: "", Tarea: "" }); // Esto debería resetear el valor del Autocompletado de tareas.
    // // setFormValues({ ...formValues, Unidad: "", Cuantificacion: "" });

    console.log("gremioSeleccionado: ", gremioSeleccionado);

    // setFormValues({ ...formValues, IDGremio: gremioSeleccionado.IDGremio, Gremio: gremioSeleccionado.Gremio, Capataz: "" });

    if (
      gremioSeleccionado !== "" &&
      gremioSeleccionado !== undefined &&
      gremioSeleccionado !== null
    ) {
      validForm.gremioValid = true;

      setCamposDisponibles({
        ...camposDisponibles,
        capataz: true,
      });

      setFormValues({
        ...formValues,
        IDGremio: gremioSeleccionado.IDGremio,
        Gremio: gremioSeleccionado.inputValue, // inputValue tiene el nombre de gremio sin la palabra "Agregar... "
        Capataz: gremioSeleccionado.Capataz,
      });
    } else {
      validForm.gremioValid = false;

      setCamposDisponibles({
        ...camposDisponibles,
        capataz: false,
      });
    }

    // console.log("CAT: ", categoriaSeleccionada);
  }, [gremioSeleccionado]);

  useEffect(() => {
    console.log("formValues: ", formValues);
  }, [formValues]);

  //   useEffect(() => {
  //     // console.log("plano: ", plano);
  //     // console.log("controlDeAvance: ", controlDeAvance);

  //     setCategoriaSeleccionada("");

  //     setControlDeAvance({
  //       ...controlDeAvance,
  //       IDGrupo: grupo.IDGrupo,
  //     });

  //     // console.log("IDGRUPO: ", grupo.IDGrupo);
  //     listar_tareas();
  //     // console.log("tareasAsignadas: ", tareasAsignadas);
  //   }, []);

  //   useEffect(() => {
  //     if (tarea && tarea.Tarea !== "" && tarea.Tarea !== undefined) {
  //       const nuevasTareasAsignadas = [...tareasAsignadas];

  //       let gru = nuevasTareasAsignadas.filter(
  //         (group) => group.IDGrupo === grupo.IDGrupo
  //       );

  //       // console.log("Grupo: ", grupo);
  //       if (gru.length > 0) {
  //         // console.log("gru: ", gru[0]);
  //         // console.log("IDSector: ", controlDeAvance.IDSector);
  //         // Verificar si la tarea ya existe en la listaTareas
  //         const tareaExiste = gru[0].Tareas.some(
  //           (option) =>
  //             option.IDDepartamento === controlDeAvance.IDDepartamento &&
  //             option.IDSector === controlDeAvance.IDSector &&
  //             option.Tarea === formValues.Tarea
  //         );

  //         if (tareaExiste) {
  //           setMensaje({
  //             text: "La tarea ya está asignada en el sector " + grupo.Grupo + ".",
  //             tipo: "error",
  //           });
  //           setMostrarMensaje(true);

  //           // console.log("dialog: ", formValues);
  //           setValidForm({ tareaValid: false });
  //         }
  //       }
  //     }
  //   }, [tarea]);

  const listar_tareas = async () => {
    // console.log(listaTareas);

    console.log({
      operacion: "LISTAR_TAREAS",
      idObra: obra.IDObra,
    });

    setMostrarProgress(true);

    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_TAREAS",
        idObra: obra.IDObra,
        //   listaTareas: texto,
        // listaTareas:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.listaTareas) {
      //   console.log("listaTareas 2: ", respuesta.result.listaTareas);

      // console.log("PASA");
      setListaTareas(respuesta.result.listaTareas);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: listar_tareas()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log("objeto: ", formValues);

    if (
      categoriaSeleccionada.CodCategoria !== undefined &&
      categoriaSeleccionada !== ""
    ) {
      if (formValues.Tarea !== "" && formValues.Tarea !== undefined) {
        if (formValues.Unidad !== "" && formValues.Unidad !== undefined) {
          // Verificar si la tarea ya existe en la listaTareas
          const tareaExiste = listaTareas.some(
            (option) =>
              option.CodCategoria === categoriaSeleccionada.CodCategoria &&
              option.Tarea === formValues.Tarea
          );

          // console.log("listaTareas: ", listaTareas);
          // console.log("categoriaSeleccionada: ", categoriaSeleccionada);
          // console.log("Tarea: ", formValues.Tarea);
          // console.log("Inputvalue: ", tarea.inputValue);

          // setValueCategoria({
          //   IDTarea: parseInt(formValues.IDTarea, 10),
          //   CodCategoria: categoriaSeleccionada.CodCategoria,
          //   CodTarea: formValues.CodTarea,
          //   Tarea: formValues.Tarea,
          //   Unidad: formValues.Unidad,
          //   Cuantificacion: formValues.Cuantificacion,
          // });

          // console.log("tareaExiste: ", tareaExiste);

          if (!tareaExiste) {
            // console.log("AGREGA TAREA");

            agregar_tarea_a_db();

            setValueCategoria(null);
            setValueTarea(null);
            setValueGremios(null);
            setCamposDisponibles(initCamposDisponibles);
            setValidForm(initValid);
            setFormValues(initFormValues);

            // setValueCategoria({
            //   IDCategoria: "",
            //   Categoria: "",
            //   CodCategoria: "",
            //   IDTarea: "",
            //   Tarea: "",
            //   IDGremio: "",
            //   Gremio: "",
            // }); // Esto debería resetear el valor del Autocompletado de tareas.
            // setGremioSeleccionado({ IDGremio: "", Gremio: "", Capataz: "" });
          } else {
            // console.log("NO AGREGA TAREA");

            //   asignar_tarea();
            setMensaje({
              text:
                "La tarea ya existe para la categoría " +
                formValues.Categoria +
                ".",
              tipo: "error",
            });
            setMostrarMensaje(true);
          }

          handleClose();
        } else {
          setMensaje({
            text: "Ingrese una unidad.",
            tipo: "error",
          });
          setMostrarMensaje(true);
        }
      } else {
        setMensaje({
          text: "Ingrese una tarea.",
          tipo: "error",
        });
        setMostrarMensaje(true);
      }
    } else {
      setMensaje({
        text: "Seleccione la categoría.",
        tipo: "error",
      });
      setMostrarMensaje(true);
    }
  };

  const handleClose = () => {
    setFormValues({
      IDCategoria: "",
      CodCategoria: "",
      Categoria: "",
      IDTarea: "",
      CodTarea: "",
      Tarea: "",
      Unidad: "",
      IDGremio: "",
      Gremio: "",
      Capataz: "",
    });
    // toggleOpen(false);
  };

  //   const asignar_tarea = async () => {
  //     // const API_ENDPOINT = servidor + `api_controlDeAvance.php`;
  //     // console.log("obj: ", {
  //     //   operacion: "ASIGNAR_TAREA",
  //     //   idObra: obra.IDObra,
  //     //   idPlano: plano.IDPlano,
  //     //   idDepartamento: controlDeAvance.IDDepartamento,
  //     //   idSector: controlDeAvance.IDSector,
  //     //   idGrupo: grupo.IDGrupo,
  //     //   idTarea: tarea.IDTarea,
  //     //   idUsuario: usuario.IDUsuario,
  //     //   cuantificacion: formValues.Cuantificacion,
  //     //   asignacionMultiple: asignacionMultiple,
  //     // });
  //     // const datos = await fetch(API_ENDPOINT, {
  //     //   method: "POST",
  //     //   // headers: {
  //     //   //   Accept: "application/json",
  //     //   //   "Content-Type": "application/json",
  //     //   // },
  //     //   body: JSON.stringify({
  //     //     operacion: "ASIGNAR_TAREA",
  //     //     idObra: obra.IDObra,
  //     //     idPlano: plano.IDPlano,
  //     //     idDepartamento: controlDeAvance.IDDepartamento,
  //     //     idSector: controlDeAvance.IDSector,
  //     //     idGrupo: grupo.IDGrupo,
  //     //     idTarea: tarea.IDTarea,
  //     //     idUsuario: usuario.IDUsuario,
  //     //     cuantificacion: formValues.Cuantificacion,
  //     //     asignacionMultiple: asignacionMultiple,
  //     //   }),
  //     // });
  //     // const respuesta = await datos.json();
  //     // // console.log("respuesta: ", respuesta);
  //     // if (respuesta.result.respuesta) {
  //     //   // console.log(respuesta.result.proveedores);
  //     //   // setProveedores(respuesta.result.proveedores);
  //     //   // setProveedorElegido(respuesta.result.proveedores[0]);
  //     //   // console.log(respuesta.result.proveedores);
  //     //   // alert("Lista de precios actualizada.");
  //     //   // Creamos una copia del estado actual
  //     //   const nuevasTareasAsignadas = [...tareasAsignadas];
  //     //   // console.log("nuevasTareasAsignadas: ", nuevasTareasAsignadas);
  //     //   let tareaNueva = respuesta.result.tareaNueva;
  //     //   console.log("tareaNueva: ", tareaNueva);
  //     //   let grupoConocido = false;
  //     //   let i = 1;
  //     //   nuevasTareasAsignadas.forEach((gru) => {
  //     //     if (gru.IDGrupo === grupo.IDGrupo) {
  //     //       gru.Tareas.push(tareaNueva);
  //     //       grupoConocido = true;
  //     //       console.log("PUSH " + i + ": ", tareaNueva);
  //     //       i++;
  //     //     }
  //     //   });
  //     //   // let nuevasTareasFiltradas = [...tareasFiltradas];
  //     //   // nuevasTareasFiltradas.forEach((gru) => {
  //     //   //   if (gru.IDGrupo === grupo.IDGrupo) {
  //     //   //     gru.Tareas.push(tareaNueva);
  //     //   //   }
  //     //   // });
  //     //   // console.log("grupoConocido: ", grupoConocido);
  //     //   if (grupoConocido) {
  //     //     console.log("PASA POR GRUPO CONOCIDO");
  //     //     setTareasAsignadas(nuevasTareasAsignadas);
  //     //     // setTareasFiltradas(nuevasTareasFiltradas);
  //     //     // console.log("NUEVAS: ", nuevasTareasAsignadas);
  //     //   } else {
  //     //     console.log("PASA POR GRUPO DESCONOCIDO");
  //     //     setTareasAsignadas([
  //     //       ...tareasAsignadas,
  //     //       {
  //     //         IDGrupo: grupo.IDGrupo,
  //     //         Grupo: grupo.Grupo,
  //     //         IDObra: obra.IDObra,
  //     //         IDPlano: plano.IDPlano,
  //     //         Tareas: [tareaNueva],
  //     //       },
  //     //     ]);
  //     //     // console.log()
  //     //     // setTareasFiltradas([
  //     //     //   ...tareasFiltradas,
  //     //     //   {
  //     //     //     IDGrupo: grupo.IDGrupo,
  //     //     //     Grupo: grupo.Grupo,
  //     //     //     IDObra: obra.IDObra,
  //     //     //     Tareas: [tareaNueva],
  //     //     //   },
  //     //     // ]);
  //     //   }
  //     //   // console.log("tareasAsignadas NUEVO: ", tareasAsignadas);
  //     //   // console.log("tareasFiltradas NUEVO: ", tareasFiltradas);
  //     //   setMensaje({
  //     //     text: "La tarea ha sido asignada con éxito.",
  //     //     tipo: "success",
  //     //   });
  //     //   setMostrarMensaje(true);
  //     // } else {
  //     //   setMensaje({
  //     //     text: "Error al asignar la tarea.",
  //     //     tipo: "error",
  //     //   });
  //     //   setMostrarMensaje(true);
  //     //   console.log("NO TRAE NADA EN asignar_tarea()");
  //     //   // setState([]);
  //     // }
  //   };

  const agregar_tarea_a_db = async () => {
    const API_ENDPOINT = servidor + `api_tareas.php`;
    // console.log("agregar_tarea_a_db(): ", {
    //   operacion: "AGREGAR_TAREA",
    //   idObra: obra.IDObra,
    //   tarea: formValues.Tarea,
    //   unidad: formValues.Unidad,
    //   codCategoria: categoriaSeleccionada.CodCategoria,
    //   arrObras: obrasSeleccionadas,
    // });
    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "AGREGAR_TAREA",
        idObra: obra.IDObra,
        tarea: formValues.Tarea,
        unidad: formValues.Unidad,
        codCategoria: categoriaSeleccionada.CodCategoria,
        idGremio: formValues.IDGremio != undefined ? formValues.IDGremio : 0,
        gremio: formValues.Gremio,
        capataz: formValues.Capataz,
      }),
    });
    const respuesta = await datos.json();
    // console.log(respuesta);
    // console.log("idTarea: ", respuesta.result.idTarea);
    if (respuesta.result.idTarea) {
      // console.log(respuesta.result.proveedores);
      // setProveedores(respuesta.result.proveedores);
      // setProveedorElegido(respuesta.result.proveedores[0]);
      // console.log(respuesta.result.proveedores);
      // alert("Lista de precios actualizada.");
      setMensaje({
        text: "La tarea ha sido agregada con éxito.",
        tipo: "success",
      });
      setMostrarMensaje(true);

      //   let sCodTarea = respuesta.result.codTarea;
      //   let idGremio = respuesta.result.idGremio;
    } else {
      setMensaje({
        text: "Error al agregar la tarea.",
        tipo: "error",
      });
      setMostrarMensaje(true);
      console.log("NO TRAE NADA EN agregar_tarea_a_db()");
      // setState([]);
    }
  };

  //   console.log("screenWidth: ", screenWidth);

  return (
    <>
      <div>
        {/* {console.log("privilegios", privilegios)} */}
        {chequearPermisos(privilegios, "CONTROL_DE_AVANCE_ALTA_TAREAS") ===
        true ? (
          // <BoxContad
          //   imagen="planificacion.png"
          //   cantidad={0}
          //   titulo="Planificacion"
          //   link="planificacion"
          // />
          <>
            <Titulo
              texto="TAREAS"
              botones={{
                seccion: "",
                alta: false,
                baja: false,
                modif: false,
              }}
              estilos={estilos}
            />
            <Typography variant="h6" style={{ fontSize: "16px" }}>
              Agregue, edite o elimine tareas!
            </Typography>
            <br /> <br />
            {/* <TablaProject data={ejemplo} /> */}
          </>
        ) : (
          ""
        )}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectComboCategoria
            screen={{ xs: 12, sm: 12, md: 10, lg: 8, xl: 4 }}
            setCategoriaSeleccionada={setCategoriaSeleccionada}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} sx={{ width: "100%" }}>
            <SelectComboTareas
              screen={{ xs: 12, sm: 12, md: 10, lg: 8, xl: 4 }}
              setTareaSeleccionada={setTareaSeleccionada}
              listaPorCategoria={listaPorCategoria}
              setValueCategoria={setValueCategoria}
              valueCategoria={valueCategoria}
              //   setCamposDisponibles={setCamposDisponibles}
              camposDisponibles={camposDisponibles}
              setFormValues={setFormValues}
              formValues={formValues}
            />

            <Grid item xs={12} sm={12} md={10} lg={8} xl={4}>
              <TextField
                autoFocus
                margin="dense"
                id="unidad"
                disabled={!camposDisponibles.unidad}
                value={formValues.Unidad}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    Unidad: event.target.value,
                  });
                  // setTarea({
                  //   ...tarea,
                  //   Unidad: event.target.value,
                  // });
                  // setCamposDisponibles({
                  //   ...camposDisponibles,
                  //   gremio: true,
                  // });
                }}
                label="Unidad"
                type="text"
                variant="standard"
              />
            </Grid>
            <SelectComboGremios
              screen={{ xs: 12, sm: 12, md: 10, lg: 8, xl: 4 }}
              setGremioSeleccionado={setGremioSeleccionado}
              //   setCamposDisponibles={setCamposDisponibles}
              camposDisponibles={camposDisponibles}
              setValueGremios={setValueGremios}
              valueGremios={valueGremios}
            />
            <Grid item xs={12} sm={12} md={10} lg={8} xl={4}>
              <TextField
                autoFocus
                margin="dense"
                id="capataz"
                disabled={!camposDisponibles.capataz}
                value={formValues.Capataz}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    Capataz: event.target.value,
                  });

                  // setTarea({
                  //   ...tarea,
                  //   Capataz: event.target.value,
                  // });
                }}
                label="Capataz"
                type="text"
                variant="standard"
              />
            </Grid>
          </Stack>
        </Grid>
      </Grid>

      <br />
      <br />
      <br />
      <br />
      <Button onClick={handleClose}>Cancelar</Button>
      <Button onClick={handleSubmit}>Confirmar</Button>
    </>
  );
};

export default PageListaTareas;
