import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ObraContext from "../../../contextos/ObraContext";
import { useContext } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import EstilosContext from "../../../contextos/EstilosContext";
import ServidorContext from "../../../contextos/ServidorContext";

import { styled, lighten, darken } from "@mui/system";
import { desencriptar } from "../../../funciones/Funciones";
import SelectComboCategoria from "./SelectComboCategoria";
import AlertValidForms from "../../../components/AlertValidForms";
import MensajesContext from "../../../contextos/MensajesContext";

const filter = createFilterOptions();

const PopupAgregarTarea = () => {
  const { servidor } = useContext(ServidorContext);
  const { screenWidth } = useContext(EstilosContext);
  const { obra } = useContext(ObraContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [lista, setLista] = useState([]);

  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);

  const [dialogValue, setDialogValue] = React.useState({
    IDTarea: "",
    CodTarea: "",
    Tarea: "",
    Unidad: "",
    CodCategoria: "",
  });

  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("");

  const [tarea, setTarea] = useState("");

  const initValid = { tareaValid: false };
  const [validForm, setValidForm] = useState(initValid);

  const [listadoObras, setListadoObras] = useState([]);
  const [obrasSeleccionadas, setObrasSeleccionadas] = useState("");

  useEffect(() => {
    listar_tareas();
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const listar_tareas = async () => {
    // console.log(lista);

    setMostrarProgress(true);

    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_TAREAS",
        idObra: obra.IDObra,
        //   lista: texto,
        // lista:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.listaTareas) {
      // console.log("lista: ", respuesta.result.listaTareas);
      // console.log("PASA");
      setLista(respuesta.result.listaTareas);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: listar_tareas()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // if (categoriaSeleccionada.CodCategoria !== "")

    agregar_tarea_a_db();

    setValue({
      IDTarea: parseInt(dialogValue.IDTarea, 10),
      CodCategoria: categoriaSeleccionada.CodCategoria,
      CodTarea: dialogValue.CodTarea,
      Tarea: dialogValue.Tarea,
      Unidad: dialogValue.Unidad,
    });
    handleClose();
  };

  const handleClose = () => {
    setDialogValue({
      IDTarea: "",
      CodTarea: "",
      Tarea: "",
      Unidad: "",
      CodCategoria: "",
    });
    toggleOpen(false);
  };

  const agregar_tarea_a_db = async () => {
    const API_ENDPOINT = servidor + `api_tareas.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "AGREGAR_TAREA",
        idObra: obra.IDObra,
        tarea: dialogValue.Tarea,
        unidad: dialogValue.Unidad,
        codCategoria: categoriaSeleccionada.CodCategoria,
        arrObras: obrasSeleccionadas,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.idTarea) {
      // console.log(respuesta.result.proveedores);
      // setProveedores(respuesta.result.proveedores);
      // setProveedorElegido(respuesta.result.proveedores[0]);
      // console.log(respuesta.result.proveedores);
      // alert("Lista de precios actualizada.");

      setMensaje({
        text: "La tarea ha sido agregada con éxito.",
        tipo: "success",
      });
      setMostrarMensaje(true);

      let sCodTarea = respuesta.result.codTarea;

      // console.log("obj: ", {
      //   IDTarea: respuesta.result.idTarea,
      //   CodCategoria: categoriaSeleccionada.CodCategoria,
      //   CodTarea: sCodTarea,
      //   Tarea: dialogValue.Tarea,
      //   Unidad: dialogValue.Unidad,
      // });

      setTarea({
        IDTarea: respuesta.result.idTarea,
        CodCategoria: categoriaSeleccionada.CodCategoria,
        CodTarea: sCodTarea,
        Tarea: dialogValue.Tarea,
        Unidad: dialogValue.Unidad,
      });
      // props.setActualizarTarea(true);
    } else {
      setMensaje({
        text: "Error al agregar la tarea.",
        tipo: "error",
      });
      setMostrarMensaje(true);

      console.log("NO TRAE NADA EN agregar_tarea_a_db()");
      // setState([]);
    }
  };

  const options = lista.map((option) => {
    const categoria = option.Categoria.toUpperCase();
    return {
      categoria: /[0-9]/.test(categoria) ? "0-9" : categoria,
      ...option,
    };
  });

  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === "light"
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });

  return (
    <div>
      <AlertValidForms
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "left",
          border: "1px solid #FF0000",
        }}
      >
        <Grid item xs={9} sm={9} md={9} lg={10}>
          <Stack spacing={1} sx={{ width: "95%" }}>
            {/* <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                // console.log("newValue: ", newValue);
                if (typeof newValue === "string") {
                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    toggleOpen(true);
                    setDialogValue({
                      IDTarea: "",
                      Tarea: newValue,
                    });
                  });
                } else if (newValue && newValue.inputValue) {
                  toggleOpen(true);
                  setDialogValue({
                    IDTarea: "",
                    Tarea: newValue.inputValue,
                  });
                } else {
                  setValue(newValue);
                }

                setTarea(newValue);
                // alert(JSON.stringify(newValue));
                setValidForm({ ...validForm, tareaValid: true });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // console.log("options: ", options);
                // console.log("params: ", params);

                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    Tarea: `Agregar "${params.inputValue}"`,
                  });
                }
                // console.log("filtered: ", filtered);
                return filtered;
              }}
              id="free-solo-dialog-demo"
              options={lista}
              getOptionLabel={(option) => {
                // console.log("option: ", option);
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                if (option && option.inputValue) {
                  return option.inputValue;
                }
                if (option && option.Tarea) {
                  return option.Tarea;
                }
                return "";
                // if (typeof option === "string") {
                //   return option;
                // }
                // if (option.inputValue) {
                //   return option.inputValue;
                // }
                // console.log("option.inputValue: ", option.inputValue);
                // return option.Tarea;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderOption={(props, option) => (
                <li {...props} key={option.IDTarea}>
                  {option.Tarea}
                </li>
              )}
              // sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Tarea" variant="standard" />
              )}
            /> */}

            <Dialog open={open} onClose={handleClose}>
              <form>
                <DialogTitle>Agregar tarea</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Agregá una tarea a la lista!
                  </DialogContentText>
                  <br />
                  <SelectComboCategoria
                    screen={{ xs: 12, sm: 12, md: 10, lg: 8, xl: 4 }}
                    setCategoriaSeleccionada={setCategoriaSeleccionada}
                  />
                  <br />
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      // console.log("newValue: ", newValue);
                      if (typeof newValue === "string") {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                          toggleOpen(true);
                          setDialogValue({
                            IDTarea: "",
                            Tarea: newValue,
                          });
                        });
                      } else if (newValue && newValue.inputValue) {
                        toggleOpen(true);
                        setDialogValue({
                          IDTarea: "",
                          Tarea: newValue.inputValue,
                        });
                      } else {
                        setValue(newValue);
                      }

                      setTarea(newValue);
                      // alert(JSON.stringify(newValue));
                      setValidForm({ ...validForm, tareaValid: true });
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      // console.log("options: ", options);
                      // console.log("params: ", params);

                      if (params.inputValue !== "") {
                        filtered.push({
                          inputValue: params.inputValue,
                          Tarea: `Agregar "${params.inputValue}"`,
                        });
                      }
                      // console.log("filtered: ", filtered);
                      return filtered;
                    }}
                    id="free-solo-dialog-demo"
                    options={lista}
                    getOptionLabel={(option) => {
                      // console.log("option: ", option);
                      // e.g value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option.inputValue) {
                        return option.inputValue;
                      }
                      if (option && option.Tarea) {
                        return option.Tarea;
                      }
                      return "";
                      // if (typeof option === "string") {
                      //   return option;
                      // }
                      // if (option.inputValue) {
                      //   return option.inputValue;
                      // }
                      // console.log("option.inputValue: ", option.inputValue);
                      // return option.Tarea;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(props, option) => (
                      <li {...props} key={option.IDTarea}>
                        {option.Tarea}
                      </li>
                    )}
                    // sx={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} label="Tarea" variant="standard" />
                    )}
                  />
                  {/* <TextField
                    autoFocus
                    margin="dense"
                    id="tarea"
                    value={dialogValue.Tarea}
                    onChange={(event) => {
                      setDialogValue({
                        ...dialogValue,
                        Tarea: event.target.value,
                      });

                      setTarea({
                        ...tarea,
                        IDTarea: "",
                        Tarea: event.target.value,
                      });
                    }}
                    label="Tarea"
                    type="text"
                    variant="standard"
                  /> */}
                  &nbsp;
                  <TextField
                    autoFocus
                    margin="dense"
                    id="unit"
                    // value={dialogValue.Material}
                    onChange={(event) => {
                      setDialogValue({
                        ...dialogValue,
                        Unidad: event.target.value,
                      });

                      setTarea({
                        ...tarea,
                        Unidad: event.target.value,
                      });
                    }}
                    label="Unidad"
                    type="text"
                    variant="standard"
                  />
                  {/* <br />
                  <br />
                  <br />
                  {listadoObras.map((obra) => (
                    <div key={obra.IDObra}>
                      <Checkbox
                        id={obra.IDObra}
                        key={obra.IDObra}
                        icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                        checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                        // checked={seleccionados.some(
                        //   (item) => item.IDDocumentacion == docs.IDDocumentacion
                        // )}
                        onChange={(event) => handleChange(event, obra)}
                      />
                      {obra.Obra.toUpperCase()}
                    </div>
                  ))} */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancelar</Button>
                  <Button onClick={handleSubmit}>Agregar</Button>
                </DialogActions>
              </form>
            </Dialog>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default PopupAgregarTarea;
