import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import Select from "react-select";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Input, InputAdornment } from "@mui/material";
import { useContext } from "react";
import ObraContext from "../../../contextos/ObraContext";
import ServidorContext from "../../../contextos/ServidorContext";
import MensajesContext from "../../../contextos/MensajesContext";
import AlertValidForms from "../../../components/AlertValidForms";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalTareas = ({
  setOpenModal,
  openModal,
  idLocal,
  local,
  setEstado,
  setListaLocales,
  listaLocales,
}) => {
  const { servidor } = useContext(ServidorContext);
  const { obra } = useContext(ObraContext);
  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const defaultValueEnSelect = {
    label: "",
    value: "0",
    categoria: "",
    gremio: "",
    idTarea: "",
  };

  const [tarea, setTarea] = useState(defaultValueEnSelect);
  const [listaTareas, setListaTareas] = useState([]);

  const handleClose = () => {
    setTarea(defaultValueEnSelect);
    setOpenModal(false);
  };

  const handleChange = (event) => {
    // console.log("event: ", event);

    setTarea(event);
  };

  useEffect(() => {
    listar_tareas();
  }, []);

  const listar_tareas = async () => {
    // console.log(listaTareas);

    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_TAREAS_EN_MODAL",
        idObra: obra.IDObra,

        //   listaTareas: texto,
        // listaTareas:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.listaTareas) {
      //   console.log(respuesta.result.listaTareas);
      setListaTareas(respuesta.result.listaTareas);

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA.");
      // setState([]);
    }
  };

  const handleGuardar = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log({
    //   operacion: "ALTA_RELACION_TAREA_LOCAL",
    //   idObra: obra.IDObra,
    //   idLocal: idLocal,
    //   idTarea: tarea.idTarea,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ALTA_RELACION_TAREA_LOCAL",
        idObra: obra.IDObra,
        idLocal: idLocal,
        idTarea: tarea.idTarea,
      }),
    });
    const respuesta = await datos.json();

    // console.log("resultado: ", respuesta.result.resultado);
    // alert(respuesta.result.guardado);
    if (respuesta.result.resultado === "ALTA_OK") {
      // console.log(respuesta.result.guardado);
      // setListaTareas(respuesta.result.listaRecursos);
      let tareaNueva = respuesta.result.localNuevo[0];

      const localEncontrado = listaLocales.find(
        (local) => local.idLocal === idLocal
      );

      if (localEncontrado) {
        localEncontrado.detalle.push(tareaNueva);
      } else {
        console.log("No se encontró el local con el ID especificado.");
      }

      //   setListaLocales({
      //     ...listaLocales,
      //     detalle: [...listaLocales.detalle, localNuevo],
      //   });

      //   listaLocales.detalle.push(localNuevo);

      setMensaje({
        text: "Tarea asignada con éxito!.",
        tipo: "success",
      });
      setMostrarMensaje(true);

      setEstado("ALTA_OK");
      //   setTarea(defaultValueEnSelect);

      setTimeout(() => {
        handleClose();
      }, 3000);

      // alert("Lista de precios actualizada.");
    } else if (respuesta.result.resultado === "EXISTE_RELACION") {
      setMensaje({
        text: "La tarea ya está asignada a este local.",
        tipo: "error",
      });
      setMostrarMensaje(true);
    } else {
      console.log("ERROR AL GUARDAR.");
      // setState([]);
    }
  };

  return (
    <>
      <AlertValidForms
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}

        <Box
          component="form"
          sx={style}
          noValidate
          autoComplete="off"
          // style={{ border: "1px solid #FF0000", width: "50%" }}
        >
          {/* <TextField
            id="standard-basic"
            label="Standard"
            helperText="hola"
            variant="standard"
          /> */}

          {/* <FormControl sx={{ m: 1, minWidth: 80 }}> */}
          <FormControl variant="standard" sx={{ m: 1, width: "auto" }}>
            <Typography variant="h5" component="h5">
              ASIGNAR TAREA
            </Typography>

            {/* <InputLabel id="demo-simple-select-autowidth-label">
            Recurso
          </InputLabel> */}
            <div>&nbsp;</div>

            {/* <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // labelId="demo-simple-select-standard-label"
              // id="demo-simple-select-standard"
              value={codRecurso}
              label="Recurso"
              defaultValue="Recurso"
              onChange={handleChange}
            >
              {listaTareas.map((rowRecurso) => (
                <MenuItem
                  value={rowRecurso.CodRecurso}
                  key={rowRecurso.IDRecurso}
                >
                  {"(" + rowRecurso.CodRecurso + ") " + rowRecurso.Recurso}
                </MenuItem>
              ))}
            </Select> */}

            <Select
              // defaultValue={codTarea}
              onChange={handleChange}
              options={listaTareas}
              // value={defaultValueEnSelect}
              // value={listaTareas.filter((option) => option.label === "00-DEM-020")}
              //   value={listaTareas.filter(function (option) {
              //     return option.value === codLocal;
              //   })}
              value={listaTareas.label}
              // style={{ width: "60%" }}
            />

            <br />
            <div sx="size: 14px;">
              <strong>Categoría</strong>:&nbsp;
              {tarea.categoria}
            </div>

            <br />
            <div sx="size: 14px;">
              <strong>Gremio</strong>:&nbsp;
              {tarea.gremio}
            </div>
            <div>&nbsp;</div>

            {/* <TextField
            id="consumo"
            label="Consumo"
            variant="standard"
            onChange={validConsumo}
            value={consumoModif}

            // onChange={validConsumo}
          /> */}

            {/* {CATEGORIA} */}
            <div>&nbsp;</div>

            {/* <TextField
            id="costo"
            label="Costo"
            variant="standard"
            onChange={validCosto}
          />

          <div>&nbsp;</div> */}

            <Button
              variant="contained"
              endIcon={<CheckOutlinedIcon />}
              onClick={handleGuardar}
            >
              Asignar al local {local}
            </Button>
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};

export default ModalTareas;
