import React, { useContext, useEffect, useState } from "react";

import { Button, Grid, IconButton, Typography } from "@mui/material";

import { DragDropContext } from "react-beautiful-dnd";
import Boton from "../../components/Boton";
// import styled from "styled-components";

import Column from "./Column";

// import { OutTable, ExcelRenderer } from "react-excel-renderer";
import SendIcon from "@mui/icons-material/Send";
import Botonera from "../../components/Botonera";
import ObraContext from "../../contextos/ObraContext";
import { Navigate, useNavigate } from "react-router-dom";
import Encabezado from "./Encabezado";
import ServidorContext from "../../contextos/ServidorContext";

const PageImportarTareas = (props) => {
  const { servidor } = useContext(ServidorContext);
  const { obra, setObra } = useContext(ObraContext);

  let navigate = useNavigate();

  useEffect(() => {
    props.setTitulo("Importar tareas");
    props.setPagina("");

    if (obra.IDObra === undefined) {
      navigate("/presupuestosBTP");
    }
  }, [props.setTitulo]);

  const [texto, setTexto] = useState("");

  const handleChange = (event) => {
    let textoFormateado = event.target.value
      .replaceAll("\n", ";;\n")
      .replaceAll("	", "|*|")
      .replaceAll("$ ", "")
      // .replaceAll(/"/g, "''");
      // .replaceAll(/"/g, "&lsquo;");
      .replace(/"/g, "&quot;");

    setTexto(textoFormateado);
    // console.log(event.target.value);
  };

  const enviarTareas = async () => {
    // console.log(texto);

    const API_ENDPOINT = servidor + `api_presupuestos.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "IMPORTAR_TAREAS",
        idObra: obra.IDObra,
        lista: texto,
        // Archivo NotasClave.txt con formato: 00-DEM	DEMOLICIONES
        // 00-DEM-100	Demolicion general [Glogal]	00-DEM
        // 00-DEM-200	Demolicion general-Camargo [Glogal]	00-DEM
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.cantidadInsertada) {
      // console.log("cantidadInsertada: " + respuesta.result.cantidadInsertada);
      setTexto("");
      alert("Tareas actualizadas.");
    } else {
      console.log("NO TRAE NADA.");
      // setState([]);
    }
  };

  return (
    <div>
      <Encabezado />
      <br />
      <br />
      <Grid container columns={{ xs: 12 }} border={0}>
        <Grid item xs={10} border={0}>
          {/* <label htmlFor="contained-button-file">
          <input
          hidden
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            id="contained-button-file"
            multiple
            type="file"
            />
            <Button variant="contained" component="span">
            Upload
          </Button>
          </label>
        */}

          {/* <input
          type="file"
          onChange={fileHandler.bind(this)}
          style={{ padding: "10px" }}
        /> */}

          {/* <OutTable
          data={state.rows}
          columns={state.cols}
          tableClassName="ExcelTable2007"
          tableHeaderRowClass="heading"
        /> */}

          <textarea
            name="message"
            style={{ width: "100%" }}
            rows="20"
            cols="200"
            placeholder="Copie y pegue la lista de tareas del archivo NotasClave.txt"
            onChange={handleChange}
            value={texto}
            // defaultValue={texto}
            // disabled
          ></textarea>
        </Grid>

        <Grid item xs={12} border={0}></Grid>

        <Grid item xs={10} border={0}>
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={12}>
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() => enviarTareas()}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageImportarTareas;
