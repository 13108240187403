import {
  Box,
  Button,
  FormControl,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
// import ServidorContext from "../../contextos/ServidorContext";
// import { useContext } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalConfirmacion = (props) => {
  const handleClose = () => props.setOpenModalConfirm(false);

  //   const { servidor } = useContext(ServidorContext);

  return (
    <Modal
      open={props.openModalConfirm}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box> */}

      <Box component="form" sx={style} noValidate autoComplete="off">
        {/* <TextField
            id="standard-basic"
            label="Standard"
            helperText="hola"
            variant="standard"
          /> */}

        {/* <FormControl sx={{ m: 1, minWidth: 80 }}> */}
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <Typography variant="h5" component="h5">
            {props.titulo.toUpperCase()}
          </Typography>

          {/* <InputLabel id="demo-simple-select-autowidth-label">
            Recurso
          </InputLabel> */}
          <div>&nbsp;</div>

          <Typography variant="h6" component="h6">
            {props.pregunta}
          </Typography>

          <div>&nbsp;</div>

          <Grid container spacing={2}>
            <Grid item xs={8}></Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row", // Ajusta la dirección a columna
                justifyContent: "right",
              }}
            >
              <Button
                variant="contained"
                endIcon={<CheckOutlinedIcon />}
                onClick={props.handleEliminar}
                sx={{ margin: "5px" }}
              >
                Sí
              </Button>
              <Button
                variant="contained"
                endIcon={<ClearOutlinedIcon />}
                onClick={handleClose}
                sx={{ margin: "5px" }}
              >
                No
              </Button>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </FormControl>
      </Box>
    </Modal>
  );
};

export default ModalConfirmacion;
