import React, { useEffect, useState } from "react";
import { useContext } from "react";

import PrivilegioContext from "../../contextos/PrivilegiosContext";

import {
  chequearPermisos,
  enviarPosicionHaciaReactNative,
} from "../../funciones/Funciones";
import EstilosContext from "../../contextos/EstilosContext";
import UsuarioContext from "../../contextos/UsuarioContext";
import { Button, Typography } from "@mui/material";
import ListaTareasConCheck from "../planificacion/ListaTareasConCheck";
import ServidorContext from "../../contextos/ServidorContext";
import ObraContext from "../../contextos/ObraContext";
import MensajesContext from "../../contextos/MensajesContext";
import AlertValidConfig from "../../components/AlertValidConfig";
import { useNavigate } from "react-router-dom";

const PageDashboardPlanificacion = ({ setTitulo, setPagina }) => {
  let navigate = useNavigate();

  const { servidor } = useContext(ServidorContext);
  const { obra } = useContext(ObraContext);

  const { usuario } = useContext(UsuarioContext);
  const { screenWidth, isMobile } = useContext(EstilosContext);

  const { privilegios, setPrivilegios, mostrarAlert, setMostrarAlert } =
    useContext(PrivilegioContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const [mostrarProgress, setMostrarProgress] = useState(false);
  const [tareas, setTareas] = useState([]);
  const [tareasSeleccionadas, setTareasSeleccionadas] = useState([]);

  useEffect(() => {
    traer_tareas();
  }, []);

  useEffect(() => {
    setTitulo("Planificación de obra");
    setPagina("");

    // console.log("A");
  }, [setTitulo]);

  const traer_tareas = async () => {
    setMostrarProgress(true);

    const API_ENDPOINT = servidor + `api_tareas.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "LISTAR_TAREAS_CON_GREMIO",
        idObra: obra.IDObra,
        //   listaTareas: texto,
        // listaTareas:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.resultado === "OK") {
      // console.log("listaTareas: ", respuesta.result.listaTareas);

      // console.log("PASA");
      setTareas(respuesta.result.listaTareas);
      setTareasSeleccionadas(
        respuesta.result.listaTareas.filter(
          (tarea) => tarea.Seleccionada === "SI"
        )
      );

      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: traer_tareas()");
      // setState([]);
    }

    setMostrarProgress(false);
  };

  const actualizar_seleccion_tarea_en_gantt = async () => {
    const API_ENDPOINT = servidor + `api_tareas.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ACTUALIZAR_VISIBILIDAD_EN_GANTT",
        idObra: obra.IDObra,
        arrTareasSeleccionadas: tareasSeleccionadas,

        //   listaTareas: texto,
        // listaTareas:
        //   "GRUPO;MATERIAL;COSTO;UNIDAD;UNIDAD COMERCIAL;COSTO COMERCIAL;EQUIVALENCIA;TIPO DE RECURSO;;Trabajos Preliminares;Gastos varios CAMARGO;$ 0,00;Global;Global;$ 0,00;0;Material;;Trabajos Preliminares;Gastos varios AGUIRRE;$ 0,00;Global;Global;$ 0,00;0;Material;;",
      }),
    });
    const respuesta = await datos.json();

    // console.log(respuesta);

    if (respuesta.result.resultado === "OK") {
      // console.log("listaTareas: ", respuesta.result.listaTareas);

      setMensaje({
        text: "Tareas guardadas con éxito!",
        tipo: "success",
      });

      setMostrarMensaje(true);

      // console.log("PASA");
      // alert("Lista de precios actualizada.");
    } else {
      console.log("NO TRAE NADA EN: actualizar_seleccion_tarea_en_gantt()");
      // setState([]);
    }
  };

  const handleConfirmar = async () => {
    await actualizar_seleccion_tarea_en_gantt();
  };

  const handleOrdenarTareas = () => {
    navigate("/ordenarTareas");
  };

  return (
    <div>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />

      {/* {console.log("privilegios", privilegios)} */}
      {chequearPermisos(privilegios, "PLANIFICACION_SELECCIONAR_TAREAS") ===
      true ? (
        // <BoxContad
        //   imagen="planificacion.png"
        //   cantidad={0}
        //   titulo="Planificacion"
        //   link="planificacion"
        // />
        <>
          <Typography variant="h6" style={{ fontSize: "20px" }}>
            Seleccione las tareas que desea incluir en el diagrama de Gantt.
          </Typography>
          <Button variant="contained" onClick={() => handleOrdenarTareas()}>
            Ordenar tareas
          </Button>
          <br /> <br />
          {tareas ? (
            <ListaTareasConCheck
              tareas={tareas}
              setTareasSeleccionadas={setTareasSeleccionadas}
              tareasSeleccionadas={tareasSeleccionadas}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      <Button variant="contained" onClick={() => handleConfirmar()}>
        Confirmar
      </Button>
      <br />
      <br />
      <br />
    </div>
  );
};

export default PageDashboardPlanificacion;
