import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Input,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// import XLSX from "xlsx";
import * as XLSX from "xlsx";

import PrivilegioContext from "../../contextos/PrivilegiosContext";

import "../../estilos/table.css";
import {
  chequearPermisos,
  enviarPosicionHaciaReactNative,
} from "../../funciones/Funciones";
import EstilosContext from "../../contextos/EstilosContext";
import UsuarioContext from "../../contextos/UsuarioContext";

import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import DatePicker from "react-datepicker";
import { es } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css"; // Importa los estilos de react-datepicker
import GanttChart from "../planificacion/GanttChart";
import ExcelJS from "exceljs";

import { saveAs } from "file-saver";
import ServidorContext from "../../contextos/ServidorContext";
import ObraContext from "../../contextos/ObraContext";

const PageOrdenarTareas = () => {
  const { servidor } = useContext(ServidorContext);
  const { obra } = useContext(ObraContext);

  const [planificacion, setPlanificacion] = useState([]);
  const [mostrarProgress, setMostrarProgress] = useState(false);

  const [cantHorasLaborables, setCantHorasLaborables] = useState(8);

  // const [ejemplo, setEjemplo] = useState([
  //   {
  //     IDTarea: "101",
  //     Id: "1",
  //     Tarea: "Fecha de hormigón s/9°",
  //     FechaInicio: new Date("2023-06-23T09:00:00"),
  //     FechaFin: new Date("2023-06-30T17:00:00"),
  //     Predecesoras: "",
  //   },
  //   {
  //     IDTarea: "102",
  //     Id: "2",
  //     Tarea: "Mampostería piso 5",
  //     FechaInicio: new Date("2023-06-23T09:00:00"),
  //     FechaFin: new Date("2023-06-30T17:00:00"),
  //     Predecesoras: "1",
  //   },
  //   {
  //     IDTarea: "103",
  //     Id: "3",
  //     Tarea: "Replanteo s/10° (piso sala de máquinas)",
  //     FechaInicio: new Date("2023-06-23T09:00:00"),
  //     FechaFin: new Date("2023-06-30T17:00:00"),
  //     Predecesoras: "1, 2",
  //   },
  // ]);

  useEffect(() => {
    const traer_tareas_para_gantt = async () => {
      setMostrarProgress(true);

      //   console.log({
      //     operacion: "LISTAR_TAREAS_PARA_GANTT",
      //     idObra: obra.IDObra,
      //   });

      const API_ENDPOINT = servidor + `api_tareas.php`;

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "LISTAR_TAREAS_PARA_GANTT",
          idObra: obra.IDObra,
        }),
      });
      const respuesta = await datos.json();

      // console.log(respuesta);

      if (respuesta.result.resultado === "OK") {
        // console.log("listaTareas: ", respuesta.result.listaTareas);

        const tareasConId = respuesta.result.listaTareas
          .filter((seleccionadas) => seleccionadas.Seleccionada === "SI")
          .map((tarea, index) => ({
            ...tarea, // Copia todas las propiedades existentes
            Id: index + 1, // Agrega el nuevo Id consecutivo
            CantDias: 0,
          }));

        // console.log("PASA");
        setTasks(tareasConId);
        // setTasks(ejemplo);

        // alert("Lista de precios actualizada.");
      } else {
        console.log("NO TRAE NADA EN: traer_tareas_para_gantt()");
        // setState([]);
      }

      setMostrarProgress(false);
    };

    traer_tareas_para_gantt();

    //   const tar = [
    //     {
    //       IDTarea: 1,
    //       Tarea: "Canaleteado en pared",
    //       IDGremio: 2,
    //       Predecesoras: "",
    //       CantDias: 30,
    //       FechaInicio: "10-01-2025 08:00:00",
    //       FechaFin: "10-02-2025 17:00:00",
    //     },
    //     {
    //       IDTarea: 2,
    //       Tarea: "Armado de madera",
    //       IDGremio: 1,
    //       Predecesoras: "",
    //       CantDias: 15,
    //       FechaInicio: "14-01-2025 08:00:00",
    //       FechaFin: "14-02-2025 17:00:00",
    //     },
    //     {
    //       IDTarea: 3,
    //       Tarea: "Blanqueo de paredes",
    //       IDGremio: 1,
    //       Predecesoras: "1, 2",
    //       CantDias: 40,
    //       FechaInicio: "17-01-2025 08:00:00",
    //       FechaFin: "27-02-2025 17:00:00",
    //     },
    //     {
    //       IDTarea: 4,
    //       Tarea: "Blanqueo de cielorraso",
    //       IDGremio: 1,
    //       Predecesoras: "2, 3",
    //       CantDias: 20,
    //       FechaInicio: "20-01-2025 08:00:00",
    //       FechaFin: "10-03-2025 17:00:00",
    //     },
    //     {
    //       IDTarea: 5,
    //       Tarea: "Pintura latex en departamento",
    //       IDGremio: 3,
    //       Predecesoras: "3, 4",
    //       CantDias: 60,
    //       FechaInicio: "10-01-2025 08:00:00",
    //       FechaFin: "10-02-2025 17:00:00",
    //     },
    //   ];

    //   setTasks(tar);
    //   // console.log("tasks: ", tar);
  }, []);

  const [data, setData] = useState([]);

  const [verGantt, setVerGantt] = useState(false);
  const [tareas, setTareas] = useState([]);
  const [tasks, setTasks] = useState([]);

  const { privilegios, setPrivilegios, mostrarAlert, setMostrarAlert } =
    useContext(PrivilegioContext);

  const { usuario } = useContext(UsuarioContext);

  const { screenWidth, isMobile } = useContext(EstilosContext);

  async function sendPushNotification(expoPushToken) {
    // console.log("PASA POR 1");

    const message = {
      to: expoPushToken,
      sound: "default",
      title: "Upload",
      body: "Hizo click en upload",
      data: { data: "datos" },
    };

    // console.log("PASA POR 2");

    await fetch("https://exp.host/--/api/v2/push/send", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Accept-encoding": "gzip, deflate",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
      mode: "no-cors", // Agrega esta línea
    });

    // console.log("PASA POR 3");
  }

  // useEffect(() => {
  //   sendPushNotification("ExponentPushToken[8xAyjvPAcabecnWiv_zb8M]");
  // }, []);

  // useEffect(() => {
  //   console.log("usuario: ", usuario);
  // }, []);

  const handlePrueba1 = () => {
    enviarPosicionHaciaReactNative(isMobile, "playSound", {
      destino: "NOTIFICACION",
      volumen: 0.1,
    });

    // sendPushNotification("ExponentPushToken[8xAyjvPAcabecnWiv_zb8M]");
  };

  const handlePrueba2 = () => {
    enviarPosicionHaciaReactNative(isMobile, "playSound", {
      destino: "NOTIFICACION_2",
      volumen: 0.1,
    });

    // sendPushNotification("ExponentPushToken[8xAyjvPAcabecnWiv_zb8M]");
  };

  const handlePrueba3 = () => {
    enviarPosicionHaciaReactNative(isMobile, "playSound", {
      destino: "BITIPI",
      volumen: 0.2,
    });

    // sendPushNotification("ExponentPushToken[8xAyjvPAcabecnWiv_zb8M]");
  };

  const handlePrueba4 = () => {
    // enviarPosicionHaciaReactNative(isMobile, "playSound", {
    //   destino: "NOTIFICACION",
    //   volumen: 0.1,
    // });

    enviarPosicionHaciaReactNative(isMobile, "notification", {
      notificacion: {
        titulo: "Notificación de prueba",
        body: "Esto es una prueba del envío de notificaciones",
        destinatarios: "ExponentPushToken[yI67PUGsinJLLhwulTg5nX]", // ExponentPushToken[06y0hFB-MFFrTpOeZtjxoo]", // usuario.TokenParaNotificaciones, "ExponentPushToken[F6Sa0UEU3XCG3G8fjBJxE0]",
        link: "dashboard",
      },
    });

    // sendPushNotification("ExponentPushToken[8xAyjvPAcabecnWiv_zb8M]");
  };

  const handleVerGantt = () => {
    setVerGantt(!verGantt);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      // console.log("worksheet: ", worksheet);
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      setData(jsonData);

      // console.log("jsonData: ", jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  // **********************************************************************

  const convertirFecha = (fechaStr) => new Date(fechaStr);

  // const exportarExcel = () => {
  //   // Determinar el rango de fechas
  //   const fechas = tareas.reduce((acc, tarea) => {
  //     acc.push(new Date(tarea.FechaInicio));
  //     acc.push(new Date(tarea.FechaFin));
  //     return acc;
  //   }, []);

  //   const fechaMin = new Date(Math.min(...fechas));
  //   const fechaMax = new Date(Math.max(...fechas));

  //   const rangoFechas = [];
  //   let fechaActual = new Date(fechaMin);

  //   // Generar las fechas de las columnas
  //   while (fechaActual <= fechaMax) {
  //     rangoFechas.push(new Date(fechaActual));
  //     fechaActual.setDate(fechaActual.getDate() + 1);
  //   }

  //   // Crear las filas de Excel
  //   const data = [];

  //   // Crear la primera fila con las fechas
  //   const filaFechas = rangoFechas.map(
  //     (fecha) => `${fecha.getDate()}/${fecha.getMonth() + 1}`
  //   );
  //   data.push(["Tarea", ...filaFechas]);

  //   // Crear las filas de las tareas
  //   tareas.forEach((tarea) => {
  //     const fila = [tarea.Tarea];

  //     // Verificar qué celdas pintar según las fechas de cada tarea
  //     rangoFechas.forEach((fecha) => {
  //       const fechaInicio = convertirFecha(tarea.FechaInicio);
  //       const fechaFin = convertirFecha(tarea.FechaFin);

  //       // Verificar si la fecha está dentro del rango de la tarea
  //       if (fecha >= fechaInicio && fecha <= fechaFin) {
  //         fila.push({ v: "", s: { fill: { fgColor: { rgb: "FFFF00" } } } }); // Celda amarilla
  //       } else {
  //         fila.push(""); // Celda vacía
  //       }
  //     });

  //     data.push(fila);
  //   });

  //   // Crear el archivo Excel
  //   const ws = XLSX.utils.aoa_to_sheet(data);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Tareas");

  //   // Descargar el archivo
  //   XLSX.writeFile(wb, "Diagrama_Gantt.xlsx");
  // };

  // const exportarExcel = () => {
  // const headerColumns = ["ID", "Tarea", "Duración", "Predecesoras"];
  // const days = ["L", "M", "M", "J", "V", "S", "D"];
  // const dayColumns = Array(5).fill(days).flat();
  // const header = [...headerColumns, ...dayColumns];

  // const data = [];
  // const taskPositions = {};

  // tasks.forEach((task) => {
  //   const row = new Array(header.length).fill("");
  //   row[0] = task.IDTarea;
  //   row[1] = task.Tarea;
  //   row[2] = task.CantDias;
  //   row[3] = task.Predecesoras;

  //   let startDay = 0;

  //   if (task.Predecesoras) {
  //     const predecessors = task.Predecesoras.split(", ").map(Number);
  //     startDay = Math.max(
  //       ...predecessors.map((id) => taskPositions[id]?.endDay || 0)
  //     );
  //   }

  //   let paintedDays = 0;
  //   for (let i = 4; i < header.length && paintedDays < task.CantDias; i++) {
  //     if (header[i] !== "S" && header[i] !== "D" && startDay <= 0) {
  //       row[i] = "celeste";
  //       paintedDays++;
  //     } else {
  //       startDay--;
  //     }
  //   }

  //   taskPositions[task.IDTarea] = {
  //     startDay: paintedDays,
  //     endDay: paintedDays + task.CantDias,
  //   };

  //   data.push(row);
  // });

  // const ws = XLSX.utils.aoa_to_sheet([header, ...data]);

  // for (let r = 1; r <= data.length; r++) {
  //   for (let c = 4; c < header.length; c++) {
  //     const cell = ws[XLSX.utils.encode_cell({ r, c })];
  //     if (!cell) continue;
  //     if (cell.v === "celeste") {
  //       cell.s = { fill: { fgColor: { rgb: "00B0F0" } } };
  //     } else if (header[c] === "S" || header[c] === "D") {
  //       cell.s = { fill: { fgColor: { rgb: "CCCCCC" } } };
  //     }
  //   }
  // }

  // const wb = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(wb, ws, "Tareas");
  // XLSX.writeFile(wb, "Tareas_Con_Gantt6.xlsx");
  // };

  // const calculateStartDay = (task, taskMap) => {
  //   if (!task.Predecesoras) return 0; // Sin predecesoras, comienza en el día 0

  //   const predecessors = task.Predecesoras.split(",").map((id) =>
  //     taskMap.get(parseInt(id.trim()))
  //   );

  //   // Determinar el día final máximo de las predecesoras
  //   const maxPredecessorEnd = Math.max(
  //     ...predecessors.map((pre) => calculateEndDay(pre, taskMap))
  //   );

  //   // Ajustar al primer día laborable después del día final máximo
  //   let columnIndex = 0;
  //   let workDaysCounted = 0;

  //   // Avanzar por las columnas hasta alcanzar el día máximo final de las predecesoras
  //   while (workDaysCounted < maxPredecessorEnd) {
  //     const dayOfWeek = ["L", "M", "M", "J", "V", "S", "D"][columnIndex % 7];
  //     if (dayOfWeek !== "S" && dayOfWeek !== "D") {
  //       workDaysCounted++;
  //     }
  //     columnIndex++;
  //   }

  //   // Saltar fines de semana para garantizar que el inicio esté en un día laborable
  //   while (
  //     ["S", "D"].includes(["L", "M", "M", "J", "V", "S", "D"][columnIndex % 7])
  //   ) {
  //     columnIndex++;
  //   }

  //   return columnIndex; // Devuelve el índice de columna para el inicio
  // };

  // const calculateEndDay = (task, taskMap) => {
  //   const startDay = calculateStartDay(task, taskMap);

  //   // Calcular el fin considerando únicamente días laborables
  //   let columnIndex = startDay;
  //   let workDaysCounted = 0;
  //   while (workDaysCounted < task.CantDias) {
  //     const dayOfWeek = ["L", "M", "M", "J", "V", "S", "D"][columnIndex % 7];
  //     if (dayOfWeek !== "S" && dayOfWeek !== "D") {
  //       workDaysCounted++;
  //     }
  //     columnIndex++;
  //   }

  //   return columnIndex; // Devuelve el índice de columna para el fin
  // };

  // -----------------------------------------------

  // // Función para calcular el siguiente día hábil a partir de una fecha y duración
  // function calculateStartDayIgnoringWeekends(startDay, duration) {
  //   let currentDay = startDay;
  //   let daysAdded = 0;

  //   while (daysAdded < duration) {
  //     currentDay++;
  //     const dayOfWeek = (currentDay - 1) % 7; // Calcula el día de la semana (0=L, 6=D)
  //     if (dayOfWeek !== 5 && dayOfWeek !== 6) {
  //       // Ignorar sábados (5) y domingos (6)
  //       daysAdded++;
  //     }
  //   }

  //   return currentDay;
  // }

  // // Cálculo de las fechas de inicio para las tareas
  // const taskStartDays = {}; // Objeto para almacenar los días de inicio de cada tarea
  // tasks.forEach((task) => {
  //   if (!task.Predecesoras) {
  //     // Si no tiene predecesoras, comienza desde el primer día laborable
  //     taskStartDays[task.IDTarea] = calculateStartDayIgnoringWeekends(1, 0);
  //   } else {
  //     // Procesar tareas con predecesoras
  //     const predecessors = task.Predecesoras.split(",").map(Number);

  //     // Obtener el fin de todas las predecesoras
  //     const endDays = predecessors.map((predecessorID) => {
  //       const predecessorTask = tasks.find((t) => t.IDTarea === predecessorID);
  //       const startDay = taskStartDays[predecessorTask.IDTarea];
  //       return calculateStartDayIgnoringWeekends(
  //         startDay,
  //         predecessorTask.CantDias
  //       );
  //     });

  //     // La tarea comienza después de que termine la predecesora de mayor duración
  //     const maxEndDay = Math.max(...endDays);
  //     taskStartDays[task.IDTarea] = calculateStartDayIgnoringWeekends(
  //       maxEndDay,
  //       1
  //     );
  //   }
  // });

  // // Generar el Excel
  // function exportarExcel() {
  //   const workbook = new ExcelJS.Workbook();
  //   const sheet = workbook.addWorksheet("Diagrama de Gantt");

  //   // Crear las columnas principales
  //   const header = ["ID", "Tarea", "Duración", "Predecesoras"];
  //   const daysHeader = [];

  //   // Calcular cuántas columnas de días necesitamos
  //   const totalDays = Math.max(
  //     ...tasks.map((task) => taskStartDays[task.IDTarea] + task.CantDias)
  //   );

  //   for (let i = 1; i <= totalDays; i++) {
  //     const dayOfWeek = (i - 1) % 7;
  //     if (dayOfWeek === 5) daysHeader.push("S"); // Sábados
  //     else if (dayOfWeek === 6) daysHeader.push("D"); // Domingos
  //     else daysHeader.push(`Día ${i}`);
  //   }

  //   const sheetData = [header.concat(daysHeader)];

  //   // Rellenar las filas con las tareas
  //   tasks.forEach((task) => {
  //     const row = [task.IDTarea, task.Tarea, task.CantDias, task.Predecesoras];
  //     const startDay = taskStartDays[task.IDTarea];
  //     const taskRow = Array(totalDays).fill("");

  //     // Pintar las celdas de los días correspondientes
  //     let daysAdded = 0;
  //     for (let i = startDay; daysAdded < task.CantDias && i <= totalDays; i++) {
  //       const dayOfWeek = (i - 1) % 7;
  //       if (dayOfWeek !== 5 && dayOfWeek !== 6) {
  //         taskRow[i - 1] = "Celeste"; // Marcamos la celda (solo texto para identificar)
  //         daysAdded++;
  //       }
  //     }

  //     sheetData.push(row.concat(taskRow));
  //   });

  //   // Añadir datos al Excel
  //   sheet.addRows(sheetData);

  //   // Estilo: Pintar las celdas según el contenido
  //   sheetData.forEach((row, rowIndex) => {
  //     if (rowIndex === 0) return; // Saltar el encabezado
  //     row.forEach((cell, columnIndex) => {
  //       const currentCell = sheet.getRow(rowIndex + 1).getCell(columnIndex + 1);
  //       if (cell === "Celeste") {
  //         currentCell.fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: { argb: "87CEEB" }, // Celeste
  //         };

  //         currentCell.value = ""; // Dejar la celda vacía, eliminando "Celeste"
  //       }
  //       if (
  //         daysHeader[columnIndex - 4] === "S" ||
  //         daysHeader[columnIndex - 4] === "D"
  //       ) {
  //         currentCell.fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: { argb: "CCCCCC" }, // Gris
  //         };
  //       }
  //     });
  //   });

  //   // Guardar el archivo
  //   workbook.xlsx.writeBuffer().then((buffer) => {
  //     const blob = new Blob([buffer], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     saveAs(blob, "Diagrama_de_Gantt10.xlsx");
  //   });
  // }

  // Función para calcular el próximo día hábil ignorando sábados y domingos
  function calculateNextWorkingDay(startDay, duration) {
    let currentDay = startDay - 1;
    let daysAdded = 0;

    while (daysAdded < duration) {
      currentDay++;
      const dayOfWeek = (currentDay - 1) % 7; // 0 = lunes, ..., 6 = domingo
      if (dayOfWeek !== 5 && dayOfWeek !== 6) {
        daysAdded++;
      }
    }

    // Avanzar al siguiente día hábil
    do {
      currentDay++;
    } while ((currentDay - 1) % 7 === 5 || (currentDay - 1) % 7 === 6);

    return currentDay;
  }

  // Función para calcular el día de inicio considerando predecesoras
  function calculateTaskStartDay(task, taskStartDays) {
    if (!task.Predecesoras) {
      return 1; // Si no tiene predecesoras, comienza en el día 1
    }

    const predecessors = task.Predecesoras.split(",").map(Number);
    const endDays = predecessors.map((predecessorID) => {
      const predecessorTask = tasks.find((t) => t.IDTarea === predecessorID);
      const startDay = taskStartDays[predecessorTask.IDTarea];
      return calculateNextWorkingDay(startDay, predecessorTask.CantDias);
    });

    const maxEndDay = Math.max(...endDays);
    return calculateNextWorkingDay(maxEndDay, 0); // Comienza el siguiente día hábil
  }

  // Exportar Excel
  function exportarExcel() {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Diagrama de Gantt");

    // Crear las columnas principales
    const header = ["ID", "Tarea", "Duración", "Predecesoras"];
    const daysHeader = [];

    // Calcular los días necesarios
    const taskStartDays = {};
    // tasks.forEach((task) => console.log("task: ", task));
    tasks.forEach((task) => {
      taskStartDays[task.IDTarea] = calculateTaskStartDay(task, taskStartDays);
    });

    const totalDays = Math.max(
      ...tasks.map((task) => taskStartDays[task.IDTarea] + task.CantDias)
    );

    for (let i = 1; i <= totalDays; i++) {
      const dayOfWeek = (i - 1) % 7;
      if (dayOfWeek === 5) daysHeader.push("S"); // Sábados
      else if (dayOfWeek === 6) daysHeader.push("D"); // Domingos
      else daysHeader.push(`Día ${i}`);
    }

    const sheetData = [header.concat(daysHeader)];

    // Rellenar las filas con las tareas
    tasks.forEach((task) => {
      console.log("task: ", task);

      //   Math.ceil(
      //     parseFloat(calculos[row.IDTarea].cantidadDeDias).toFixed(0) /
      //       cantHorasLaborables
      //   );

      const row = [task.Id, task.Tarea, task.CantDias, task.Predecesoras];
      const startDay = taskStartDays[task.IDTarea];
      const taskRow = Array(totalDays).fill("");

      let daysAdded = 0;
      for (let i = startDay; daysAdded < task.CantDias && i <= totalDays; i++) {
        const dayOfWeek = (i - 1) % 7;
        if (dayOfWeek !== 5 && dayOfWeek !== 6) {
          taskRow[i - 1] = ""; // Dejar la celda vacía para aplicar color
          daysAdded++;
        }
      }

      sheetData.push(row.concat(taskRow));
    });

    // Añadir datos al Excel
    sheet.addRows(sheetData);

    // Estilo: Pintar las celdas según el contenido
    sheetData.forEach((row, rowIndex) => {
      if (rowIndex === 0) return; // Saltar el encabezado
      const task = tasks[rowIndex - 1]; // Obtener la tarea actual
      const startDay = taskStartDays[task.IDTarea]; // Día de inicio de la tarea

      let daysAdded = 0;
      for (
        let columnIndex = startDay + 3; // Ajustar índice para columnas de días
        daysAdded < task.CantDias && columnIndex <= totalDays + 3;
        columnIndex++
      ) {
        const dayOfWeek = (columnIndex - 4) % 7; // Ajustar índice a días de la semana
        if (dayOfWeek !== 5 && dayOfWeek !== 6) {
          const cell = sheet.getRow(rowIndex + 1).getCell(columnIndex + 1);
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "87CEEB" }, // Celeste
          };
          daysAdded++;
        }
      }
    });

    // Pintar sábados y domingos en gris
    for (let columnIndex = 4; columnIndex < totalDays + 4; columnIndex++) {
      const dayLabel = daysHeader[columnIndex - 4];
      if (["S", "D"].includes(dayLabel)) {
        sheet.getColumn(columnIndex + 1).eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "CCCCCC" }, // Gris
          };
        });
      }
    }

    // Guardar el archivo
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const nombreArchivo = `gantt_${getFormattedDate()}.xlsx`;

      saveAs(blob, nombreArchivo);
    });
  }

  const getFormattedDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Meses van de 0 a 11
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  };

  //   function exportarProject(listaTareas) {
  //     console.log("tareas: ", listaTareas);

  //     // Ordenar las tareas por "Orden"
  //     const tareasOrdenadas = listaTareas.sort(
  //       (a, b) => parseInt(a.Orden) - parseInt(b.Orden)
  //     );

  //     // Crear el encabezado del archivo XML
  //     let projectXML = `<?xml version="1.0" encoding="UTF-8"?>
  // <Project xmlns="http://schemas.microsoft.com/project">
  //   <Name>Exportación de Tareas</Name>
  //   <Tasks>`;

  //     // Agregar las tareas al XML
  //     tareasOrdenadas.forEach((tarea) => {
  //       projectXML += `
  //     <Task>
  //       <UID>${tarea.Orden}</UID>
  //       <ID>${tarea.Orden}</ID>
  //       <Name>${tarea.Tarea}</Name>
  //       <Start>${new Date(tarea.FechaInicio).toISOString()}</Start>
  //       <Finish>${new Date(tarea.FechaFin).toISOString()}</Finish>
  //       <Duration>${tarea.CantDias}d</Duration>
  //       <Notes>${tarea.Gremio}</Notes>
  //       <Active>${tarea.Publicar === "1" ? "true" : "false"}</Active>
  //     </Task>`;
  //     });

  //     // Cerrar las etiquetas XML
  //     projectXML += `
  //   </Tasks>
  // </Project>`;

  //     // Crear un blob para descargar el archivo
  //     const blob = new Blob([projectXML], { type: "application/xml" });
  //     const url = URL.createObjectURL(blob);

  //     // Crear un enlace para descargar
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = "tareas.xml";
  //     link.click();

  //     // Liberar memoria del blob
  //     URL.revokeObjectURL(url);
  //   }

  const exportarProject = (listaTareas) => {
    console.log("lista: ", listaTareas);
    const projectTemplate = `<?xml version="1.0" encoding="UTF-8"?>
<Project xmlns="http://schemas.microsoft.com/project">
  <Name>Exportación de Tareas</Name>
  <Tasks>
${listaTareas
  .sort((a, b) => a.Orden - b.Orden)
  .map((tarea, index) => {
    console.log("tarea " + index + ": ", tarea);
    // const duration = `${tarea.CantDias || 0}d`;
    const start = tarea.FechaInicio
      ? `<Start>${new Date(tarea.FechaInicio)
          .toISOString()
          .replace(".000Z", "")}</Start>`
      : "";

    console.log("start: ", start);
    const duration = `${tarea.CantDias || 0}`;

    const durationInMs = (tarea.CantDias || 0) * 24 * 60 * 60 * 1000; // Convertir días a milisegundos
    const finishDate = tarea.FechaInicio
      ? new Date(new Date(tarea.FechaInicio).getTime() + durationInMs)
          .toISOString()
          .replace(".000Z", "")
      : "";

    const finish = finishDate ? `<Finish>${finishDate}</Finish>` : "";

    const manualStart = `<ManualStart>${new Date(tarea.FechaInicio)
      .toISOString()
      .replace(".000Z", "")}</ManualStart>`;
    const manualFinish = `<ManualFinish>${finishDate}</ManualFinish>`;

    // const fechaCompleta = tarea.FechaInicio;
    // console.log("fechaCompleta: ", fechaCompleta);
    // const start = fechaCompleta
    //   ? `<Start>${fechaCompleta.split(" ")[0]}</Start>`
    //   : "";
    // console.log("fechaInicio: ", start);
    // const fechaInicio = fechaCompleta.split(" ")[0];
    return `
    <Task>
      <UID>${index + 1}</UID>
      <ID>${index + 1}</ID>
      <Name>${tarea.Tarea}</Name>
      ${start}
      ${finish}
      ${manualStart}
      ${manualFinish}
      <Duration>PT${Math.round(duration * 8)}H0M0S</Duration>      
      <ManualDuration>PT${Math.round(duration * 8)}H0M0S</ManualDuration>
      <RemainingDuration>PT${Math.round(duration * 8)}H0M0S</RemainingDuration>
      <RemainingWork>PT${Math.round(duration * 8)}H0M0S</RemainingWork>
      <Work>PT${Math.round(duration * 8)}H0M0S</Work>
      <Notes>${tarea.Gremio || ""}</Notes>
      <Manual>true</Manual>
      <Active>true</Active>
    </Task>`;
  })
  .join("")}
  </Tasks>
</Project>`;

    const nombreArchivo = `gantt_${getFormattedDate()}.xml`;

    const blob = new Blob([projectTemplate], { type: "text/xml" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = nombreArchivo;
    a.click();
  };

  // -----------------------------------------------

  // // Función para calcular el día de inicio considerando predecesoras
  // function calculateTaskStartDay(task, taskStartDays) {
  //   if (!task.Predecesoras) {
  //     return 1; // Si no tiene predecesoras, comienza en el día 1
  //   }

  //   const predecessors = task.Predecesoras.split(",").map(Number);
  //   const endDays = predecessors.map((predecessorID) => {
  //     const predecessorTask = tasks.find((t) => t.IDTarea === predecessorID);
  //     const startDay = taskStartDays[predecessorTask.IDTarea];
  //     return calculateNextWorkingDay(startDay, predecessorTask.CantDias);
  //   });

  //   const maxEndDay = Math.max(...endDays);
  //   return calculateNextWorkingDay(maxEndDay, 0); // Comienza el día siguiente
  // }

  // // Función para calcular el próximo día hábil ignorando sábados y domingos
  // function calculateNextWorkingDay(startDay, duration) {
  //   let currentDay = startDay - 1;
  //   let daysAdded = 0;

  //   while (daysAdded < duration) {
  //     currentDay++;
  //     const dayOfWeek = (currentDay - 1) % 7; // 0 = lunes, ..., 6 = domingo
  //     if (dayOfWeek !== 5 && dayOfWeek !== 6) {
  //       daysAdded++;
  //     }
  //   }

  //   return currentDay;
  // }

  // // Exportar Excel
  // function exportarExcel() {
  //   const workbook = new ExcelJS.Workbook();
  //   const sheet = workbook.addWorksheet("Diagrama de Gantt");

  //   // Crear las columnas principales
  //   const header = ["ID", "Tarea", "Duración", "Predecesoras"];
  //   const daysHeader = [];

  //   // Calcular los días necesarios
  //   const taskStartDays = {};
  //   tasks.forEach((task) => {
  //     taskStartDays[task.IDTarea] = calculateTaskStartDay(task, taskStartDays);
  //   });

  //   const totalDays = Math.max(
  //     ...tasks.map((task) => taskStartDays[task.IDTarea] + task.CantDias)
  //   );

  //   for (let i = 1; i <= totalDays; i++) {
  //     const dayOfWeek = (i - 1) % 7;
  //     if (dayOfWeek === 5) daysHeader.push("S"); // Sábados
  //     else if (dayOfWeek === 6) daysHeader.push("D"); // Domingos
  //     else daysHeader.push(`Día ${i}`);
  //   }

  //   const sheetData = [header.concat(daysHeader)];

  //   // Rellenar las filas con las tareas
  //   tasks.forEach((task) => {
  //     const row = [task.IDTarea, task.Tarea, task.CantDias, task.Predecesoras];
  //     const startDay = taskStartDays[task.IDTarea];
  //     const taskRow = Array(totalDays).fill("");

  //     let daysAdded = 0;
  //     for (let i = startDay; daysAdded < task.CantDias && i <= totalDays; i++) {
  //       const dayOfWeek = (i - 1) % 7;
  //       if (dayOfWeek !== 5 && dayOfWeek !== 6) {
  //         taskRow[i - 1] = ""; // Dejar la celda vacía para aplicar color
  //         daysAdded++;
  //       }
  //     }

  //     sheetData.push(row.concat(taskRow));
  //   });

  //   // Añadir datos al Excel
  //   sheet.addRows(sheetData);

  //   // Estilo: Pintar las celdas según el contenido
  //   sheetData.forEach((row, rowIndex) => {
  //     if (rowIndex === 0) return; // Saltar el encabezado
  //     const task = tasks[rowIndex - 1]; // Obtener la tarea actual
  //     const startDay = taskStartDays[task.IDTarea]; // Día de inicio de la tarea

  //     let daysAdded = 0;
  //     for (
  //       let columnIndex = startDay + 3; // Ajustar índice para columnas de días
  //       daysAdded < task.CantDias && columnIndex <= totalDays + 3;
  //       columnIndex++
  //     ) {
  //       const dayOfWeek = (columnIndex - 4) % 7; // Ajustar índice a días de la semana
  //       if (dayOfWeek !== 5 && dayOfWeek !== 6) {
  //         const cell = sheet.getRow(rowIndex + 1).getCell(columnIndex + 1);
  //         cell.fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: { argb: "87CEEB" }, // Celeste
  //         };
  //         daysAdded++;
  //       }
  //     }
  //   });

  //   // Pintar sábados y domingos en gris
  //   for (let columnIndex = 4; columnIndex < totalDays + 4; columnIndex++) {
  //     const dayLabel = daysHeader[columnIndex - 4];
  //     if (["S", "D"].includes(dayLabel)) {
  //       sheet.getColumn(columnIndex + 1).eachCell((cell) => {
  //         cell.fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: { argb: "CCCCCC" }, // Gris
  //         };
  //       });
  //     }
  //   }

  //   // Guardar el archivo
  //   workbook.xlsx.writeBuffer().then((buffer) => {
  //     const blob = new Blob([buffer], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     saveAs(blob, "Diagrama_de_Gantt8.xlsx");
  //   });
  // }

  // **********************************************************************

  function TablaProject({ data, setData }) {
    const inputRefs = useRef({});
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [newStartDate, setNewStartDate] = useState(null);
    const [newEndDate, setNewEndDate] = useState(null);
    const [selectedPredecessors, setSelectedPredecessors] = useState([]);

    // console.log("data: ", data);

    const [calculos, setCalculos] = useState(
      // Inicializar el estado `calculos` con valores predeterminados
      data.reduce((acc, row) => {
        acc[row.IDTarea] = {
          cantidadDeDias: row.CantDias || 0,
          cantidadOficiales: 0,
          cantidadAyudantes: 0,
        };
        return acc;
      }, {})
    );

    // Función para actualizar dinámicamente las propiedades de `calculos`
    const updateCalculos = (idTarea, field, value) => {
      setCalculos((prevCalculos) => ({
        ...prevCalculos,
        [idTarea]: {
          ...prevCalculos[idTarea],
          [field]: value,
        },
      }));
    };

    useEffect(() => {
      const actualizar_calculos = () => {
        console.log("data: ", data);
        data.map(async (row, index) => {
          //   await traer_planificacion_de_tarea(row.CodTarea);

          let cantDias = 0;
          console.log("row.Planificacion: ", row.Planificacion);

          (row.Planificacion || []).map((recurso) => {
            if (parseInt(recurso.CantDias) > parseInt(cantDias)) {
              cantDias = recurso.CantDias;
            }
          });

          //   console.log("cantDias mayor: ", cantDias);

          await updateCalculos(row.IDTarea, "cantidadDeDias", cantDias);
        });
      };

      actualizar_calculos();
    }, []);

    // useEffect(() => {
    //   console.log("PASA POR 1");
    // }, [data]);

    const traer_planificacion_de_tarea = async (codTarea) => {
      const API_ENDPOINT = servidor + `api_planificaciones.php`;

      //   console.log({
      //     operacion: "TRAER_PLANIFICACION_DE_TAREA",
      //     idObra: obra.IDObra,
      //     codTarea: codTarea,
      //   });

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "TRAER_PLANIFICACION_DE_TAREA",
          idObra: obra.IDObra,
          codTarea: codTarea,
        }),
      });
      const respuesta = await datos.json();

      //   console.log("planificacion: ", respuesta.result.planificacion);

      if (respuesta.result.resultado === "OK") {
        // console.log("PASAAA");
        setPlanificacion([
          ...planificacion,
          { IDTarea: respuesta.result.planificacion },
        ]);
      } else {
        console.log("NO TRAE NADA EN traer_planificacion_de_tarea()");
        setPlanificacion([]);
        // setGruposNoAsignados(grupos);
      }
    };

    const onDragEnd = (result) => {
      const { source, destination } = result;

      if (!destination || source.index === destination.index) return;

      const newData = Array.from(data);
      const [movedItem] = newData.splice(source.index, 1);
      newData.splice(destination.index, 0, movedItem);

      const idMap = {};
      newData.forEach((item, index) => {
        idMap[item.Id] = (index + 1).toString();
      });

      const updatedData = newData.map((item) => ({
        ...item,
        Id: idMap[item.Id],
        Predecesoras: item.Predecesoras
          ? item.Predecesoras.split(",")
              .map((pred) => idMap[pred.trim()] || pred.trim())
              .join(", ")
          : "",
      }));

      setData(updatedData);

      console.log("updatedData 1: ", updatedData);

      actualizar_lista(updatedData);
    };

    const actualizar_lista = async (updatedData) => {
      const API_ENDPOINT = servidor + `api_planificaciones.php`;

      console.log({
        operacion: "ACTUALIZAR_TAREAS_DE_GANTT",
        idObra: obra.IDObra,
        listaTareas: updatedData,
      });

      const datos = await fetch(API_ENDPOINT, {
        method: "POST",
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
        body: JSON.stringify({
          operacion: "ACTUALIZAR_TAREAS_DE_GANTT",
          idObra: obra.IDObra,
          listaTareas: updatedData,
        }),
      });
      const respuesta = await datos.json();

      //   console.log("planificacion: ", respuesta.result.planificacion);

      if (respuesta.result.resultado === "OK") {
        console.log("Cambios en GANTT realizados!");
        // console.log("PASAAA");
        //   setPlanificacion([
        //     ...planificacion,
        //     { IDTarea: respuesta.result.planificacion },
        //   ]);
      } else {
        console.log("NO TRAE NADA EN actualizar_lista()");
        //   setPlanificacion([]);
      }
    };

    // Función para abrir el Dialog y configurar la tarea seleccionada
    const handleEditClick = (task) => {
      setSelectedTask(task);

      // Convertir Predecesoras a un arreglo limpio (eliminar espacios y elementos vacíos)
      setSelectedPredecessors(
        task.Predecesoras
          ? task.Predecesoras.split(",")
              .map((pred) => pred.trim())
              .filter(Boolean)
          : []
      );

      setNewStartDate(
        task.FechaInicio !== "0000-00-00 00:00:00" && task.FechaInicio
          ? new Date(task.FechaInicio)
          : null
      );
      setNewEndDate(
        task.FechaFin !== "0000-00-00 00:00:00" && task.FechaFin
          ? new Date(task.FechaFin)
          : null
      );
      setOpenDialog(true);
    };

    // Función para confirmar los cambios en el Dialog
    const handleConfirm = () => {
      const updatedData = data.map((task) => {
        if (task.Id === selectedTask.Id) {
          return {
            ...task,
            FechaInicio: newStartDate,
            FechaFin: newEndDate,
            Predecesoras: selectedPredecessors.join(", "), // Convertir a cadena consistente
          };
        }
        return task;
      });

      setData(updatedData);

      console.log("updatedData 2: ", updatedData);

      actualizar_lista(updatedData);

      setOpenDialog(false);
    };

    const handleExportClick = () => {
      // Actualizamos `tasks` con los valores calculados
      const updatedTasks = tasks.map((task) => {
        const cantDiasCalculado = Math.ceil(
          parseFloat(calculos[task.IDTarea]?.cantidadDeDias || 0).toFixed(0) /
            cantHorasLaborables
        );
        return {
          ...task,
          CantDias: cantDiasCalculado, // Actualizamos el valor de cantDias
        };
      });

      setTasks(updatedTasks); // Actualizamos el estado de tasks

      // Una vez que el estado está actualizado, llamamos a la función exportarProject
      exportarProject(updatedTasks);
    };

    // // Función para abrir el Dialog y configurar la tarea seleccionada
    // const handleEditClick = (task) => {
    //   setSelectedTask(task);
    //   setNewStartDate(task.FechaInicio);
    //   setNewEndDate(task.FechaFin);
    //   setSelectedPredecessors(
    //     task.Predecesoras ? task.Predecesoras.split(",") : []
    //   );
    //   setOpenDialog(true);
    // };

    // Función para manejar el cambio de predecesores seleccionados
    const handlePredecessorChange = (event, taskId) => {
      if (event.target.checked) {
        setSelectedPredecessors([...selectedPredecessors, taskId]);
      } else {
        setSelectedPredecessors(
          selectedPredecessors.filter((id) => id !== taskId)
        );
      }
    };

    // // Función para confirmar los cambios en el Dialog
    // const handleConfirm = () => {
    //   const updatedData = data.map((task) => {
    //     if (task.Id === selectedTask.Id) {
    //       return {
    //         ...task,
    //         FechaInicio: newStartDate,
    //         FechaFin: newEndDate,
    //         Predecesoras: selectedPredecessors.join(", "),
    //       };
    //     }
    //     return task;
    //   });

    //   setData(updatedData);
    //   setOpenDialog(false);
    // };

    // Función para cancelar y cerrar el Dialog
    const handleCancel = () => {
      setOpenDialog(false);
    };

    // const handleCantDias = (event, id) => {
    //   const { value } = event.target;

    //   setData((prevData) =>
    //     prevData.map((row) =>
    //       row.Id === id ? { ...row, CantDias: value } : row
    //     )
    //   );

    //   // Mantener foco después de actualizar
    //   inputRefs.current[id]?.focus();
    // };

    return (
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="table">
            {(provided) => (
              <TableContainer
                component={Paper}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell align="left">Tarea</TableCell>
                      <TableCell align="left">Duración</TableCell>
                      <TableCell align="center">Inicio</TableCell>
                      {/* <TableCell align="center">Fin</TableCell> */}
                      <TableCell align="center">Predecesoras</TableCell>
                      <TableCell align="center">Modificar</TableCell>{" "}
                      {/* Columna Modificar */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, index) => (
                      <Draggable
                        key={row.Id}
                        draggableId={String(row.Id)}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              backgroundColor: snapshot.isDragging
                                ? "#CCCCCC"
                                : "inherit",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.Id}
                            </TableCell>
                            <TableCell align="left">{row.Tarea}</TableCell>
                            <TableCell align="left">
                              {Math.ceil(
                                parseFloat(
                                  calculos[row.IDTarea].cantidadDeDias
                                ).toFixed(0) / cantHorasLaborables
                              )}{" "}
                              días
                              {/* <Input
                                id={`cant-dias-${row.Id}`}
                                endAdornment={
                                  <InputAdornment position="end">
                                    días
                                  </InputAdornment>
                                }
                                aria-describedby={`cant-dias-helper-${row.Id}`}
                                inputProps={{
                                  "aria-label": "CantDias",
                                }}
                                value={row.CantDias}
                                onChange={(event) =>
                                  handleCantDias(event, row.Id)
                                }
                                sx={{ width: "80px" }}
                                inputRef={(el) =>
                                  (inputRefs.current[row.Id] = el)
                                } // Referencia al input
                              /> */}
                            </TableCell>
                            <TableCell align="center">
                              {row.FechaInicio !== "0000-00-00 00:00:00" &&
                              row.FechaInicio
                                ? new Date(row.FechaInicio).toLocaleString()
                                : ""}
                            </TableCell>
                            {/* <TableCell align="center">
                              {row.FechaFin !== "0000-00-00 00:00:00" &&
                              row.FechaFin
                                ? new Date(row.FechaFin).toLocaleString()
                                : ""}
                            </TableCell> */}
                            <TableCell align="center">
                              {row.Predecesoras}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                onClick={() => handleEditClick(row)} // Abrir el dialog para editar
                              >
                                <EditIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Droppable>
        </DragDropContext>
        <div>
          {/* Dialog para modificar tarea */}
          <Dialog open={openDialog} onClose={handleCancel}>
            <DialogTitle>Modificar tarea: {selectedTask?.Tarea}</DialogTitle>

            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "right",
                      minWidth: "80px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <strong>Inicio:</strong>
                  </Typography>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={newStartDate}
                    onChange={(date) => setNewStartDate(date)}
                    locale={es} // Configuración para español
                    // showTimeSelect
                    inline
                  />
                  {/* <DatePicker
                    selected={newStartDate}
                    onChange={(date) => setNewStartDate(date)}
                    locale={es} // Configuración para español
                    showTimeSelect
                    timeFormat="HH:mm" // Formato de 24 horas
                    timeIntervals={15} // Intervalos de 15 minutos
                    dateFormat="dd/MM/yyyy HH:mm" // Formato de fecha y hora
                    placeholderText="Selecciona fecha y hora"
                    customInput={
                      <TextField fullWidth size="small" variant="outlined" />
                    }
                  /> */}
                </Box>
                {/* <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "right",
                      minWidth: "80px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <strong>Fin:</strong>
                  </Typography>

                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={newEndDate}
                    onChange={(date) => setNewEndDate(date)}
                    locale={es} // Configuración para español
                    // showTimeSelect
                    inline
                  />
                 
                </Box> */}
              </Box>

              {/* <div>
                <h3>Seleccionar Predecesoras</h3>
                <List>
                  {data
                    .filter((task) => task.Id !== selectedTask?.Id) // Filtrar la tarea seleccionada
                    .map((task) => (
                      <ListItem
                        key={task.Id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={selectedPredecessors.includes(task.Id)}
                          onChange={(event) =>
                            handlePredecessorChange(event, task.Id)
                          }
                          icon={
                            <CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />
                          }
                          checkedIcon={
                            <CheckCircleIcon sx={{ fontSize: 40 }} />
                          }
                        />
                        <ListItemText
                          primary={task.Tarea}
                          sx={{ fontSize: "20px" }}
                        />
                      </ListItem>
                    ))}
                </List>
              </div> */}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel}>Cancelar</Button>
              <Button onClick={handleConfirm}>Confirmar</Button>
            </DialogActions>
          </Dialog>
          <br />
          {tasks.length > 0 ? (
            <Button variant="contained" onClick={handleExportClick}>
              Gantt a Project
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* {console.log("privilegios", privilegios)} */}
      {chequearPermisos(privilegios, "PLANIFICACION_IMPORTAR_XLSX") === true ? (
        // <BoxContad
        //   imagen="planificacion.png"
        //   cantidad={0}
        //   titulo="Planificacion"
        //   link="planificacion"
        // />
        <>
          <Typography variant="h6" style={{ fontSize: "16px" }}>
            Bimtracker Plus te permite importar la planificación de tu obra
            desde un archivo Microsoft Excel.
          </Typography>
          <Typography variant="h6" style={{ fontSize: "16px" }}>
            Para eso, es importante que el Excel a importar tenga el siguiente
            formato:
          </Typography>
          <br /> <br />
          <TablaProject data={tasks} setData={setTasks} />
        </>
      ) : (
        ""
      )}
      {/* <Button variant="contained" component="label">
        Subir archivo
        <input type="file" hidden onChange={handleFileUpload} />
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => handlePrueba1()}>
        Sonido Notificación
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => handlePrueba2()}>
        Sonido Notificación2
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => handlePrueba3()}>
        Sonido BITIPI
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={() => handlePrueba4()}>
        Notificación
      </Button>
      <br /> */}
      <br />
      <br />
      {data.length > 0 ? <TablaProject data={data} /> : ""}

      {tasks.length > 0 ? (
        <Button variant="contained" onClick={exportarExcel}>
          Gantt a Excel
        </Button>
      ) : (
        ""
      )}

      {/* {verGantt ? <GanttChart tareas={tareas} /> : ""} */}

      {/* {console.log("data: ", data)} */}
    </div>
  );
};

export default PageOrdenarTareas;
