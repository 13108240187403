import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";

import {
  chequearPermisos,
  desencriptar,
  encriptar,
} from "../../../funciones/Funciones";
import ServidorContext from "../../../contextos/ServidorContext";
import EstilosContext from "../../../contextos/EstilosContext";
import ObraContext from "../../../contextos/ObraContext";
import MensajesContext from "../../../contextos/MensajesContext";
import AlertValidConfig from "../../../components/AlertValidConfig";
import ControlDeAvanceContext from "../../../contextos/ControlDeAvanceContext";

import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { traer_color } from "../../../colores/Colores";

const filter = createFilterOptions();

const BoxLocales = ({ setLocalSelected, localSelected, ufSelected }) => {
  const { servidor } = useContext(ServidorContext);
  const { isMobile, screenWidth } = useContext(EstilosContext);
  const { obra, setObra } = useContext(ObraContext);

  const { mostrarMensaje, setMostrarMensaje, mensaje, setMensaje } =
    useContext(MensajesContext);

  const { tareasAsignadas, setTareasAsignadas, planosDeObra, setPlanosDeObra } =
    useContext(ControlDeAvanceContext);

  const colorTitulo = traer_color("blue", 600);
  const [bgColorTitulo, setBgColorTitulo] = useState(colorTitulo);

  const [locales, setLocales] = useState({
    IDLocal: "",
    Local: "",
  });

  const [comboLocales, setComboLocales] = useState([]);
  //   const [localSelected, setLocalSelected] = useState("");
  //   const [paso1, setPaso1] = useState("");

  const [value, setValue] = useState(null);
  const [open, toggleOpen] = useState(false);

  const [dialogValue, setDialogValue] = useState({
    IDLocal: "",
    Local: "",
  });

  const initValid = { localSelectedValid: false };
  const [validForm, setValidForm] = useState(initValid);

  const [chipData, setChipData] = useState([]);
  const [chipSelected, setChipSelected] = useState([]);
  const [tareasAsignadasNuevas, setTareasAsignadasNuevas] = useState([]);
  const [tareasSeleccionadas, setTareasSeleccionadas] = useState([]);
  const [planosFiltrados, setPlanosFiltrados] = useState([]);

  const plano = desencriptar(localStorage.getItem("plano"));

  //   useEffect(() => {
  //     console.log("tareasAsignadas desde CONTEXT: ", tareasAsignadas);
  //   }, []);

  useEffect(() => {
    // console.log("obra: ", obra);

    const refreshTareas = async () => {
      //   if (obra.AppVersion === "1.0") {
      await traer_tareas_asignadas_a_local();
      //   } else {
      //     await traer_tareas_asignadas_a_local_en_v2();
      //   }
    };

    refreshTareas();
  }, [locales]);

  //   useEffect(() => {
  //     console.log("tareasSeleccionadas: ", tareasSeleccionadas);
  //     console.log("COUNT: ", tareasSeleccionadas.length);
  //   }, [tareasSeleccionadas]);

  useEffect(() => {
    traer_locales();
  }, [ufSelected.IDDepartamento]);

  useEffect(() => {
    traer_locales();
    setChipSelected(localSelected);
  }, []);

  useEffect(() => {
    // console.log("chipData: ", chipData);
  }, [chipData]);

  //   useEffect(() => {
  //     // return () => {
  //     // let paso1 = desencriptar(localStorage.getItem("objordserv"));
  //     // let paso2 = { ...paso1, localSelected: chipSelected };

  //     // localStorage.setItem("objordserv", encriptar(paso2));

  //     setLocalSelected(chipSelected);

  //     console.log("chipSelected: ", chipSelected);

  //     // console.log("paso2: ", paso2);
  //     // };
  //   }, [chipSelected]);

  const asignar_local_a_unidad_funcional = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log("idPlano: ", idPlano);
    // console.log("dialogValue.Local: ", dialogValue.Local);

    // console.log({
    //   operacion: "ASIGNAR_LOCAL_A_UF",
    //   idObra: obra.IDObra,
    //   idPlano: plano.IDPlano,
    //   idDepartamento: ufSelected.IDDepartamento,
    //   idLocal: dialogValue.IDLocal,
    //   arrtareasSeleccionadas: tareasSeleccionadas,
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "ASIGNAR_LOCAL_A_UF",
        idObra: obra.IDObra,
        idPlano: plano.IDPlano,
        idDepartamento: ufSelected.IDDepartamento,
        idLocal: dialogValue.IDLocal,
        local: dialogValue.Local,
        arrTareasSeleccionadas: tareasSeleccionadas,

        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.resultado === "OK") {
      // setChipData([...chipData, respuesta.result.locales[0]]);

      // console.log("sect: ", respuesta.result.locales);

      //   console.log(
      //     "respuesta.result.yaExistia: ",
      //     respuesta.result.yaExistiaLocal
      //   );

      if (!respuesta.result.yaExistiaLocal) {
        setChipData([...chipData, respuesta.result.asignaciones[0]]);
      }

      setDialogValue({
        IDLocal: respuesta.result.asignaciones[0]["IDLocal"],
        Local: locales.Local,
      });

      setLocalSelected("");

      if (respuesta.result.yaExistiaLocal) {
        setMensaje({ text: "Tareas asignadas con éxito!", tipo: "success" });
      } else {
        setMensaje({ text: "Local agregado con éxito!", tipo: "success" });
      }

      setMostrarMensaje(true);
    } else {
      console.log("NO TRAE NADA EN asignar_local_a_unidad_funcional()");
      // setState([]);
    }
  };

  const traer_locales = async () => {
    const API_ENDPOINT = servidor + `api_locales.php`;

    // console.log("IDObra: ", obra.IDObra);

    // console.log({
    //   operacion: "TRAER_SECTORES",
    //   idObra: obra.IDObra,
    //   idDepartamento: ufSelected.IDDepartamento,
    //   idPlano: ufSelected.IDPlano,
    //   // productos: "VOLQUETES",
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_LOCALES",
        idObra: obra.IDObra,
        idDepartamento: ufSelected.IDDepartamento,
        idPlano: ufSelected.IDPlano,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("listaLocales: ", respuesta.result.listaLocales);

    if (respuesta.result.resultado === "OK") {
      setComboLocales(respuesta.result.listaLocalesEnCombo);
      setChipData(respuesta.result.listaLocales);
    } else {
      setComboLocales([]);
      setChipData([]);
      console.log("NO TRAE NADA EN traer_locales()");
      // setState([]);
    }
  };

  const traer_tareas_asignadas_a_local = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_TAREAS_ASIGNADAS_A_LOCAL",
        idObra: obra.IDObra,
        idLocal: locales.IDLocal,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("locales: ", respuesta.result.listaSectores);

    if (respuesta.result.resultado === "OK") {
      console.log("LTA: ", respuesta.result.listaTareasAsignadas);
      //   setTareasAsignadas(respuesta.result.listaTareasAsignadas);
      setTareasAsignadasNuevas(respuesta.result.listaTareasAsignadas);
      setTareasSeleccionadas(respuesta.result.listaTareasAsignadas);

      //   console.log("tar: ", respuesta.result.listaTareasAsignadas);
    } else {
      //   setTareasAsignadas([]);
      setTareasAsignadasNuevas([]);
      setTareasSeleccionadas([]);

      console.log("NO TRAE NADA EN traer_tareas_asignadas_a_local()");
      // setState([]);
    }
  };

  const traer_tareas_asignadas_a_local_en_v2 = async () => {
    const API_ENDPOINT = servidor + `api_controlDeAvance.php`;

    // console.log({
    //   operacion: "TRAER_TAREAS_ASIGNADAS_A_LOCAL_EN_DEPTO",
    //   idObra: obra.IDObra,
    //   idLocal: locales.IDLocal,
    //   idPlano: plano.IDPlano,
    //   // productos: "VOLQUETES",
    // });

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "TRAER_TAREAS_ASIGNADAS_A_LOCAL",
        idObra: obra.IDObra,
        idLocal: locales.IDLocal,
        idPlano: plano.IDPlano,
        // productos: "VOLQUETES",
      }),
    });
    const respuesta = await datos.json();

    // console.log("locales: ", respuesta.result.listaSectores);

    if (respuesta.result.resultado === "OK") {
      setTareasAsignadas(respuesta.result.listaTareasAsignadas);
      setTareasSeleccionadas(respuesta.result.listaTareasAsignadas);

      //   console.log("tar: ", respuesta.result.listaTareasAsignadas);
    } else {
      setTareasAsignadas([]);
      setTareasSeleccionadas([]);

      console.log("NO TRAE NADA EN traer_tareas_asignadas_a_local_en_v2()");
      // setState([]);
    }
  };

  //   const traer_planos_filtrados = async () => {
  //     const API_ENDPOINT = servidor + `api_departamentos.php`;

  //     // console.log("IDObra: ", obra.IDObra);

  //     // console.log({
  //     //   operacion: "TRAER_PLANOS_FILTRADOS",
  //     //   idObra: obra.IDObra,
  //     //   idDepartamento: ufSelected.IDDepartamento,
  //     //   // productos: "VOLQUETES",
  //     // });

  //     const datos = await fetch(API_ENDPOINT, {
  //       method: "POST",
  //       // headers: {
  //       //   Accept: "application/json",
  //       //   "Content-Type": "application/json",
  //       // },
  //       body: JSON.stringify({
  //         operacion: "TRAER_PLANOS_FILTRADOS",
  //         idObra: obra.IDObra,
  //         idDepartamento: ufSelected.IDDepartamento,
  //       }),
  //     });
  //     const respuesta = await datos.json();

  //     if (respuesta.result.resultado === "OK") {
  //       let filtro = planosDeObra.filter((item) =>
  //         respuesta.result.planosFiltrados.some(
  //           (item2) => item2.IDPlano === item.IDPlano
  //         )
  //       );

  //       setPlanosFiltrados(filtro);
  //     } else {
  //       setPlanosFiltrados([]);
  //       console.log("NO TRAE NADA EN traer_planos_filtrados()");
  //       // setState([]);
  //     }
  //   };

  const handleClick = (chip) => {
    // let found = chipSelected.filter(
    //   (chip) => chip.IDPalabraClave === idPalabraClave
    // );
    // console.log("found: ", found);

    // let newChip = "";
    // if (found.length > 0) {
    //   newChip = chipSelected.filter(
    //     (chip) => chip.IDPalabraClave !== idPalabraClave
    //   );
    //   setChipSelected(newChip);
    // } else {
    //   newChip = { IDPalabraClave: idPalabraClave };
    //   setChipSelected([...chipSelected, newChip]);
    // }

    // console.log("chip: ", chip);
    setLocalSelected(chip);
    setChipSelected([chip]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (dialogValue.Local !== "") {
      //   if (
      //     chipData.filter((chip) => chip.Local === dialogValue.Local).length > 0
      //   ) {
      //     setMensaje({ text: "El local ya existe.", tipo: "error" });

      //     setMostrarMensaje(true);
      //   } else {
      asignar_local_a_unidad_funcional();

      setLocalSelected(value);

      setValue({
        IDLocal: parseInt(dialogValue.IDLocal, 10),
        Local: dialogValue.Local,
      });

      handleClose();
      //   }
    } else {
      setMensaje({ text: "El local no puede ser vacío.", tipo: "error" });

      setMostrarMensaje(true);
    }
  };

  const handleClose = () => {
    setDialogValue({
      IDLocal: "",
      Local: "",
    });
    toggleOpen(false);
  };

  const handleAddLocal = () => {
    // console.log("idDepto: ", ufSelected.IDDepartamento);

    if (
      chequearPermisos(
        obra.Privilegios,
        "CONTROL_DE_AVANCE_ROL_ADMINISTRADOR"
      ) === true
    ) {
      if (
        ufSelected.IDDepartamento !== "" &&
        ufSelected.IDDepartamento !== 0 &&
        ufSelected.IDDepartamento !== undefined
      ) {
        toggleOpen(true);
      } else {
        setMensaje({
          text: "Seleccione el espacio donde agregar el local!",
          tipo: "error",
        });

        setMostrarMensaje(true);
      }
    } else {
      setMensaje({ text: "No tiene permisos!", tipo: "error" });
      setMostrarMensaje(true);
    }
  };

  const handleTareas = (event, tarea) => {
    // console.log("tarea: ", event.target.checked + " | " + tarea.IDTarea);

    if (event.target.checked) {
      // Agregar la tarea seleccionada al estado
      setTareasSeleccionadas((prev) => [...prev, tarea]);
    } else {
      // Remover la tarea deseleccionada del estado
      setTareasSeleccionadas((prev) =>
        prev.filter((item) => item.IDTarea !== tarea.IDTarea)
      );
    }

    // if (event.target.checked) {
    //   setTareasSeleccionadas([...tareasSeleccionadas, tarea]);
    // } else {
    //   let filtro = tareasSeleccionadas.filter(
    //     (item) => item.IDTarea !== tarea.IDTarea
    //   );
    //   // console.log("filtro: ", filtro);

    //   setTareasSeleccionadas(filtro);
    // }
  };

  const tareasPorGremio = tareasAsignadasNuevas.reduce((grupos, tarea) => {
    const { Gremio } = tarea;
    if (!grupos[Gremio]) {
      grupos[Gremio] = [];
    }
    grupos[Gremio].push(tarea);

    // console.log("AA: ", grupos);
    return grupos;
  }, {});

  //   console.log("tareasAsignadas: ", tareasAsignadas);
  //   console.log("listaTareasSeleccionadas: ", tareasSeleccionadas);
  //   console.log("tareasPorGremio: ", tareasPorGremio);

  return (
    <>
      <AlertValidConfig
        mostrarMensaje={mostrarMensaje}
        mensaje={mensaje.text}
        tipo={mensaje.tipo}
      />
      {/* <br /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {chipData.map((data, index) => (
          // <div
          //   style={
          //     {
          //       /*padding: "20px"*/
          //     }
          //   }
          // >
          <Chip
            key={data.IDLocal}
            label={data.Local.toUpperCase()}
            color="primary"
            variant={
              chipSelected.length > 0 &&
              chipSelected.filter((chip) => chip.IDLocal === data.IDLocal)
                .length > 0
                ? ""
                : "outlined"
            }
            onClick={() => handleClick(data)}
            style={{ fontSize: "25px", padding: "0px" }} // Ajusta estos valores a tu gusto
          />
          // </div>
        ))}

        <Chip
          key="agregar"
          label="+"
          color="primary"
          // variant={
          //   chipSelected.length > 0 &&
          //   chipSelected.filter(
          //     (chip) => chip.IDLocal === data.IDLocal
          //   ).length > 0
          //     ? ""
          //     : "outlined"
          // }
          variant="outlined"
          onClick={() => handleAddLocal()}
          style={{ fontSize: "25px", padding: "10px" }} // Ajusta estos valores a tu gusto
        />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>Asignar Local</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ejemplos: "Living", "Cocina", etc.
            </DialogContentText>

            <br />
            <Autocomplete
              id="free-solo-dialog-demo"
              // options={chipData}
              options={comboLocales}
              value={value}
              // inputValue={value === null ? "" : value.Tarea}
              clearOnBlur={false}
              selectOnFocus
              handleHomeEndKeys
              freeSolo
              // disabled={!camposDisponibles.tarea}
              onChange={(event, newValue) => {
                // console.log("newValue: ", newValue);

                if (typeof newValue === "string") {
                  // console.log("PASA POR 1");

                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    toggleOpen(true);

                    setDialogValue({
                      IDLocal: "",
                      Local: newValue,
                    });

                    setLocales({
                      ...locales,
                      IDLocal: "",
                      Local: newValue,
                    });
                  });
                } else if (newValue && newValue.inputValue) {
                  // console.log("PASA POR 2: Cuando agrega un local.");

                  toggleOpen(true);
                  setDialogValue({
                    IDLocal: "",
                    Local: newValue.inputValue,
                  });

                  setLocales({
                    ...locales,
                    IDLocal: "",
                    Local: newValue.inputValue,
                  });
                } else {
                  // console.log(
                  //   "PASA POR 3: Cuando selecciona un local existente."
                  // );

                  //   setValue(newValue);

                  if (newValue !== null) {
                    setDialogValue({
                      IDLocal: newValue.IDLocal,
                      Local: newValue.Local,
                    });

                    setLocales({
                      ...locales,
                      IDLocal: newValue.IDLocal,
                      Local: newValue.Local,
                    });
                  } else {
                    setDialogValue({
                      IDLocal: "",
                      Local: "",
                    });

                    setLocales({
                      ...locales,
                      IDLocal: "",
                      Local: "",
                    });
                  }
                }

                // setLista([...lista, newValue]);
                console.log("NEW VALUE: ", newValue);

                if (newValue !== null) {
                  setValue(newValue);
                  //   setLocalSelected(newValue);
                } else {
                  setValue("");
                  //   setLocalSelected("");
                }

                // alert(JSON.stringify(newValue));

                setValidForm({ ...validForm, localValid: true });
                // setCamposDisponibles({ ...camposDisponibles, unidad: true });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // console.log("options: ", options);
                // console.log("params: ", params);

                if (
                  params.inputValue !== "" &&
                  !options.some(
                    (item) =>
                      item.Local.toLowerCase() ===
                      params.inputValue.toLowerCase()
                  )
                ) {
                  filtered.push({
                    inputValue: params.inputValue,
                    Local: `Agregar "${params.inputValue}"`,
                  });
                }
                // console.log("filtered: ", filtered);
                return filtered;
              }}
              getOptionLabel={(option) => {
                // console.log("option: ", option);
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  // console.log("PASA POR A");
                  return option;
                }
                if (option && option.inputValue) {
                  // console.log("PASA POR B");
                  return option.inputValue;
                }
                if (option && option.Local) {
                  // console.log("PASA POR C");

                  return option.Local;
                }
                return "";
                // if (typeof option === "string") {
                //   return option;
                // }
                // if (option.inputValue) {
                //   return option.inputValue;
                // }
                // console.log("option.inputValue: ", option.inputValue);
                // return option.Tarea;
              }}
              // clearOnBlur

              renderOption={(props, option) => (
                <li {...props} key={option.IDLocal}>
                  {option.Local}
                </li>
              )}
              // sx={{ width: 300 }}

              renderInput={(params) => (
                <TextField {...params} label="Local" variant="standard" />
              )}
            />

            {/* <TextField
              autoFocus
              margin="dense"
              id="local"
              value={dialogValue.Local}
              onChange={(event) => {
                setDialogValue({
                  ...dialogValue,
                  Local: event.target.value,
                });

                setLocales({
                  ...locales,
                  IDLocal: "",
                  Local: event.target.value,
                });
              }}
              label="Local"
              type="text"
              variant="standard"
            /> */}
          </DialogContent>
          <DialogTitle>
            Tareas asignadas a "{ufSelected.Departamento}":
          </DialogTitle>
          <DialogContent>
            {/* {console.log("porGremio: ", tareasPorGremio)}
            {Object.entries(tareasPorGremio).map(([gremio, tareas]) =>
              //   console.log("GREMIO: ", gremio)

              console.log("TAREAS: ", tareas)
            )} */}
            {/* {console.log("tareasPorGremio: ", tareasPorGremio)} */}
            {Object.entries(tareasPorGremio || {}).map(([gremio, tareas]) => (
              <div key={gremio} style={{ marginBottom: "20px" }}>
                {/* Título del gremio */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                    backgroundColor: bgColorTitulo,
                    fontSize:
                      screenWidth === "xs" || screenWidth === "sm"
                        ? "26px"
                        : "60px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "25px",
                      color: "#FFFFFF",
                      paddingLeft: "10px",
                    }}
                  >
                    {gremio.toUpperCase()}
                  </div>
                </div>

                {/* {console.log("TAREASSS: ", tareas)} */}
                {/* Validación adicional */}
                {(tareas || []).map((tarea) => (
                  <div key={tarea.IDLocalTarea} style={{ padding: "10px" }}>
                    <Checkbox
                      id={tarea.IDLocalTarea}
                      icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                      checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                      checked={tareasSeleccionadas.some(
                        (seleccionada) => seleccionada.IDTarea === tarea.IDTarea
                      )}
                      onChange={(event) => handleTareas(event, tarea)}
                    />
                    {`${tarea.Tarea} (${tarea.Unidad})`}
                  </div>
                ))}
              </div>
            ))}

            {/* {tareasAsignadas
              // .filter((item) => item.IDPlano !== idPlano)
              .map((tarea) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column", // Ajusta la dirección a columna
                      justifyContent: "center",
                      width: "100%",
                      backgroundColor: bgColorTitulo,
                      fontSize:
                        screenWidth === "xs" || screenWidth === "sm"
                          ? "26px"
                          : "60px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "25px",
                        color: "#FFFFFF",
                        paddingLeft: "10px",
                        // padding: "15px",
                        // alignItems: "center",
                        // border: "1px solid #FF0000",
                      }}
                    >
                      
                      {tarea.Gremio.toUpperCase()}
                    </div>
                  </div>
                  
                  <div key={tarea.IDLocalTarea}>
                    <Checkbox
                      id={plano.IDLocalTarea}
                      key={plano.IDLocalTarea}
                      icon={<CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />}
                      checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                      // checked={seleccionados.some(
                      //   (item) => item.IDDocumentacion == docs.IDDocumentacion
                      // )}
                      onChange={(event) => handleTareas(event, tarea)}
                    />
                    {tarea.Tarea + " (" + tarea.Unidad + ") "}
                  </div>
                </>
              ))} */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleSubmit}>Agregar</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default BoxLocales;
