import {
  Autocomplete,
  createFilterOptions,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";

const filter = createFilterOptions();

const SelectComboTareas = ({
  screen,
  setTareaSeleccionada,
  listaPorCategoria,
  setValueCategoria,
  valueCategoria,
  //   setCamposDisponibles,
  camposDisponibles,
  setFormValues,
  formValues,
}) => {
  return (
    <div Style="width: 100%;">
      <Grid container sx={{ display: "flex", justifyContent: "left" }}>
        <Grid
          item
          xs={screen.xs}
          sm={screen.sm}
          md={screen.md}
          lg={screen.lg}
          xl={screen.xl}
          sx={{ width: "100%" }}
        >
          <Stack
            spacing={1}

            // xs={9}
            // sm={9}
            // md={9}
            // lg={5}
          >
            <Autocomplete
              id="free-solo-dialog-demo"
              options={listaPorCategoria}
              value={valueCategoria}
              // inputValue={valueCategoria === null ? "" : valueCategoria.Tarea}
              clearOnBlur={false}
              selectOnFocus
              handleHomeEndKeys
              freeSolo
              disabled={!camposDisponibles.tarea}
              onChange={(event, newValue) => {
                console.log("newValue: ", newValue);

                if (typeof newValue === "string") {
                  // console.log("PASA POR 1");

                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    //   toggleOpen(true);

                    setFormValues({
                      ...formValues,
                      IDTarea: "",
                      Tarea: newValue,
                    });
                  });
                } else if (newValue && newValue.inputValue) {
                  // console.log("PASA POR 2: Cuando agrega una tarea.");

                  // toggleOpen(true);
                  setFormValues({
                    ...formValues,
                    IDTarea: "",
                    Tarea: newValue.inputValue,
                    Unidad: "",
                    IDGremio: "",
                    Gremio: "",
                    Capataz: "",
                  });

                  //   setCamposDisponibles({
                  //     ...camposDisponibles,
                  //     unidad: true,
                  //     gremio: false,
                  //   });
                } else {
                  // console.log(
                  //   "PASA POR 3: Cuando selecciona una tarea existente."
                  // );
                  setValueCategoria(newValue);

                  if (newValue !== null) {
                    setFormValues({
                      ...formValues,
                      IDTarea: newValue.IDTarea,
                      Tarea: newValue.Tarea,
                      Unidad: newValue.Unidad,
                      IDGremio: newValue.IDGremio,
                      Gremio: newValue.Gremio,
                      Capataz: newValue.Capataz,
                    });
                    // setCamposDisponibles({
                    //   ...camposDisponibles,
                    //   gremio: true,
                    // });
                  } else {
                    setFormValues({
                      ...formValues,
                      IDTarea: "",
                      Tarea: "",
                      Unidad: "",
                      IDGremio: "",
                      Gremio: "",
                      Capataz: "",
                    });
                    // setCamposDisponibles({
                    //   ...camposDisponibles,
                    //   unidad: false,
                    //   gremio: false,
                    // });
                  }
                }

                setTareaSeleccionada(newValue);

                // setLista([...lista, newValue]);

                // setTarea(newValue);
                // // alert(JSON.stringify(newValue));

                // setValidForm({ ...validForm, tareaValid: true });
                // // setCamposDisponibles({ ...camposDisponibles, unidad: true });
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // console.log("options: ", options);
                // console.log("params: ", params);

                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    Tarea: `Agregar "${params.inputValue}"`,
                  });
                }
                // console.log("filtered: ", filtered);
                return filtered;
              }}
              getOptionLabel={(option) => {
                // console.log("option: ", option);
                // e.g value selected with enter, right from the input
                if (typeof option === "string") {
                  // console.log("PASA POR A");
                  return option;
                }
                if (option && option.inputValue) {
                  // console.log("PASA POR B");
                  return option.inputValue;
                }
                if (option && option.Tarea) {
                  // console.log("PASA POR C");

                  return option.Tarea;
                }
                return "";
                // if (typeof option === "string") {
                //   return option;
                // }
                // if (option.inputValue) {
                //   return option.inputValue;
                // }
                // console.log("option.inputValue: ", option.inputValue);
                // return option.Tarea;
              }}
              // clearOnBlur

              renderOption={(props, option) => (
                <li {...props} key={option.IDTarea}>
                  {option.Tarea}
                </li>
              )}
              // sx={{ width: 300 }}

              renderInput={(params) => (
                <TextField {...params} label="Tarea" variant="standard" />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectComboTareas;
